(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ShareChargesDialogController', ShareChargesDialogController);

    ShareChargesDialogController.$inject = ['$rootScope', '$uibModalInstance', '$scope', '$translate', '$timeout', '$uibModal',
    'entity', 'ExpressParcel', 'Guide', 'DataUtils', 'uiGridConstants', 'GridState'];

    function ShareChargesDialogController ($rootScope, $uibModalInstance, $scope, $translate, $timeout, $uibModal,
        entity, ExpressParcel, Guide, DataUtils,uiGridConstants, GridState) {

        var vm = this;
        var isPay = entity.isPay;
        vm.shareMCostDTO = entity;
        vm.clear = clear;
        vm.delHGuide = delHGuide;
        vm.delRate = delRate;
        vm.submitShare = submitShare;
        vm.delAllChecked = delAllChecked;
        vm.delAllUnChecked = delAllUnChecked;
        vm.isSaving = false;
        vm.exSharedRates = true;
        vm.exSharedRatesChange = exSharedRatesChange;
        vm.cargoType = "";
        vm.cargoTypeChange = cargoTypeChange;
        vm.filterChange = filterChange;
        vm.updateVWtotal = updateVWtotal;
        //改成grid后 已经不用了的 暂时保留
        var consolShipmentsCopy = [];
        vm.filterStr = "";

        var gridStateObj_2 = {};
        var gridStateCopy_2 = null;


        $timeout(function (){
            vm.shareMCostDTO.consolShipmentDTOs = [];
            vm.shareMCostDTO.shareRates = [];
            vm.shareMCostDTO.type = "vol";
            angular.forEach(vm.shareMCostDTO.masterGuide.guideRates, function(data){
                if (!data.isHidden
                    && data.payAmount
                    && data.item
                    && (!data.dnNum || data.dnNum.indexOf("CS") == -1)){
                    if(isPay == data.isPay){
                        vm.shareMCostDTO.shareRates.push(data);
                    }
                }
            })
            if(vm.shareMCostDTO.shareRates.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                clear();
                return;
            }

            gridStateCopy_2 = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 174
            }, function(result){
                gridStateObj_2 = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if(!gridStateObj_2 || !gridStateObj_2.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy_2);
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj_2.gridState));
            })

            ExpressParcel.getSubShipments({
                companyId: $rootScope.account.companyId,
                isFms: vm.shareMCostDTO.isFms,
                masterId: vm.shareMCostDTO.masterId,
                forShare: true,
            }, function(result){
                vm.shareMCostDTO.consolShipmentDTOs = result;
                angular.forEach(vm.shareMCostDTO.consolShipmentDTOs, function(data) {
                    DataUtils.fillFdLabel(data);
                })
//                consolShipmentsCopy = angular.copy(vm.shareMCostDTO.consolShipmentDTOs);
                $scope.gridOptions.data = angular.copy(vm.shareMCostDTO.consolShipmentDTOs);
                // updateVWtotal();
            })
            filterByExSharedRates();
        })


        //改成grid后 已经不用了的 暂时保留
        function cargoTypeChange () {
            $scope.gridOptions.data = [];
            angular.forEach(consolShipmentsCopy, function(data){
                if (!vm.cargoType || data.cargoType == vm.cargoType){
                    $scope.gridOptions.data.push(data);
                }
            })
            updateVWtotal();
        }

        //改成grid后 已经不用了的 暂时保留
        function filterChange () {
            if (!vm.filterStr){
                vm.shareMCostDTO.consolShipmentDTOs = consolShipmentsCopy;
                return;
            }
            vm.shareMCostDTO.consolShipmentDTOs = [];
            angular.forEach(consolShipmentsCopy, function(data){
                if (data.mblNum && data.mblNum.indexOf(vm.filterStr) !== -1){
                    vm.shareMCostDTO.consolShipmentDTOs.push(data);
                }
                else if (data.soNum && data.soNum.indexOf(vm.filterStr) !== -1){
                    vm.shareMCostDTO.consolShipmentDTOs.push(data);
                }
                else if (data.jobNum && data.jobNum.indexOf(vm.filterStr) !== -1){
                    vm.shareMCostDTO.consolShipmentDTOs.push(data);
                }
            })
            updateVWtotal();
        }


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function delHGuide (guide) {
            var index = $scope.gridOptions.data.indexOf(guide);
            if (index > -1) {
                $scope.gridOptions.data.splice(index, 1);
            };
            // updateVWtotal();
        }
        function delRate (rate) {
            var index = vm.shareMCostDTO.shareRates.indexOf(rate);
            if (index > -1) {
                vm.shareMCostDTO.shareRates.splice(index, 1);
            };
            updateTotal();
        }
        function exSharedRatesChange () {
            vm.exSharedRates = !vm.exSharedRates;
            filterByExSharedRates();
        }
        function filterByExSharedRates () {
            vm.shareMCostDTO.shareRates = [];
            angular.forEach(vm.shareMCostDTO.masterGuide.guideRates, function(data){
                if (!data.isHidden
                    && data.item
                    && data.isPay == isPay
                    && data.payAmount && (!data.dnNum || data.dnNum.indexOf("CS") == -1)){
                    if (vm.exSharedRates){
                        if (!data.sharedOn){
                            vm.shareMCostDTO.shareRates.push(data);
                        }
                    }
                    else {
                        vm.shareMCostDTO.shareRates.push(data);
                    }
                }
            })
            updateTotal();
        }
        vm.costTotalList = [];
        //第一部分表格的合计
        function updateTotal () {
            vm.costTotalList = [];
            var currencys = [];
            angular.forEach(vm.shareMCostDTO.shareRates, function(data){
                if (data.payCurrency && currencys.indexOf(data.payCurrency) === -1){
                    currencys.push(data.payCurrency);
                }
            })

            var cost = 0;
            angular.forEach(currencys, function(currency){
                cost = 0;
                angular.forEach(vm.shareMCostDTO.shareRates, function(rate){
                    if (rate.payCurrency == currency && rate.payAmount){
                        if (rate.isPay == isPay) {
                            cost = cost + rate.payAmount;
                        }
                    }
                })
                vm.costTotalList.push({currency: currency, total: cost.toFixed(2) -0});
            })
        }
        vm.vwTtl = {};

        //改成grid后 已经不用了的 暂时保留
        function updateVWtotal () {
            var gw=0,vol=0,cw=0,pallets=0,teus=0,pkgNum=0;
            angular.forEach($scope.gridOptions.data, function(data){
                if(data.gw){
                    gw = gw + data.gw;
                }
                if(data.vol){
                    vol = vol + data.vol;
                }
                if(data.cw){
                    cw = cw + data.cw;
                }
                if(data.pallets){
                    pallets = pallets + data.pallets;
                }
                if(data.teus){
                    teus = teus + data.teus;
                }
                if(data.pkgNum){
                    pkgNum = pkgNum + data.pkgNum;
                }
            })
            vm.vwTtl.gw = gw.toFixed(2) -0;
            vm.vwTtl.vol = vol.toFixed(3) -0;
            vm.vwTtl.cw = cw.toFixed(2) -0;
            vm.vwTtl.pallets = pallets.toFixed(2) -0;
            vm.vwTtl.teus = teus.toFixed(2) -0;
            vm.vwTtl.pkgNum = pkgNum.toFixed(2) -0;
        }

        var onShareSuccess = function(result) {
            layer.closeAll();
            vm.isSaving = false;
            $uibModalInstance.close(result);
        };

        function submitShare() {
            if (!vm.shareMCostDTO.type){
                layer.msg($translate.instant('global.invalidRequest'));
                return;
            }
            if ($scope.gridOptions.data.length == 0){
                layer.msg($translate.instant('autopsApp.guide.noShareShipments'));
                return;
            }
            if (vm.shareMCostDTO.shareRates.length == 0){
                layer.msg($translate.instant('autopsApp.guide.noShareCharges'));
                return;
            }
            if (vm.shareMCostDTO.type == "teu"){
                var isAllFcl = true;
                angular.forEach(vm.shareMCostDTO.consolShipmentDTOs, function(data){
                    if (data.shipmentType != 'FCL'){
                        isAllFcl = false;
                    }
                })
                if (!isAllFcl){
                    layer.msg($translate.instant('autopsApp.guide.byTeuAlert'));
                    return;
                }
            }
            vm.shareMCostDTO.consolShipmentDTOs = $scope.gridOptions.data;
            var entity = angular.copy(vm.shareMCostDTO);
            layer.load(1, { shade: 0.3 });
            ExpressParcel.shareCharges(entity, onShareSuccess, $rootScope.ALERT_ERROR);
        }

        $scope.searchStr = "";
        $scope.virtualWms = false;
        var loadFmsBiz = vm.shareMCostDTO.isFms;
        $scope.searchType = 'Job#';

        $scope.setSearchType = function(type) {
            $scope.searchType = type;
        }

        $scope.searchGuide = function(value) {
            if (!$scope.searchStr){
                return;
            }
            loadFmsBiz = value;
            if(loadFmsBiz){
                searchGuides();
            }
            else {
                searchParcels();
            }
        };

        function searchGuides() {
            Guide.searchGuide({
                isFms: loadFmsBiz,
                searchType: $scope.searchType,
                jobType: "shareCharges",
                virtualWms: $scope.virtualWms,
                companyId: $rootScope.account.companyId,
                inputStr: $scope.searchStr
            }, function(result) {
                addAllToGuides(result);
                $scope.searchStr = null;
            }, function(response) {
            });
        }

        function searchParcels() {
            ExpressParcel.searchByNum({
                isFms: loadFmsBiz,
                type: "shareCharges",
                searchType: $scope.searchType,
                companyId: $rootScope.account.companyId,
                inputStr: $scope.searchStr,
                isTms: $scope.isTms
            }, function(result) {
                addAllToGuides(result);
                $scope.searchStr = null;
            }, function(response) {
            });
        }

        function searchParcelsPop(type) {
            var searchParams = {
                isShareCharges: true,
                submited: true,
                shipmentType: type,
                companyId: $rootScope.account.companyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/express-parcel/express-parcel-detail.html',
                controller: 'ExpressParcelDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(result) {
                addAllToGuides(result);
            }, function() {
            });
        }

        function searchGuidesPop() {
            var searchParams = {
                isExport: vm.shareMCostDTO.masterGuide.isExport,
                isShareCharges: true,
                searchType: "all",
                companyId: $rootScope.account.companyId
            };
            if($scope.virtualWms){
                searchParams.companyId = $scope.choosedWarehouse.companyId;
            }

            $uibModal.open({
                templateUrl: 'app/entities/guide/guide-search-dialog.html',
                controller: 'GuideSearchDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(result) {
                addAllToGuides(result);
            }, function() {
            });
        }


        $scope.searchGuidePop = function(loadFms, parcelType) {
            if($scope.virtualWms && (!$scope.choosedWarehouse || !$scope.choosedWarehouse.companyId)){
                layer.msg($translate.instant('autopsApp.guide.warehouseNullAlert'));
                return;
            }
            if(loadFms){
                searchGuidesPop();
            }
            else {
                searchParcelsPop(parcelType);
            }
        }
        function addAllToGuides(results){
            if (!results || results.length == 0){
                layer.msg($translate.instant('global.messages.resultNull'));
            }
            else {
                angular.forEach(results, function(data){
                    if(data.otherTimeTwo){
                        layer.msg($translate.instant('autopsApp.guide.sts.wmsOut'));
                    }
                    else if(data.isClosed){
                        layer.msg($translate.instant('autopsApp.guide.sts.isClosed'));
                    }
                    else if ($scope.virtualWms && data.medCompanyId != $rootScope.account.companyId){

                    }
                    else {
                        addToList(data);
                    }
                })
                $translate.refresh();
                $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.DATA );
            }
        }


        function addToList (guide) {
            var isRepeated = false;
            for(var j = $scope.gridOptions.data.length -1; j >=0; j--){
                if (guide.parcelId && guide.parcelId == $scope.gridOptions.data[j].parcelId){
                    isRepeated = true;
                    break;
                }
                if (guide.guideId && guide.guideId == $scope.gridOptions.data[j].guideId){
                    isRepeated = true;
                    break;
                }
            }
            if (isRepeated){
                layer.msg($translate.instant('global.messages.repeated'));
            }
            else {
                $scope.gridOptions.data.push(guide);
            }
        }


        function delAllChecked() {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            for(var i = $scope.gridOptions.data.length -1; i>= 0; i--){
                var isSelected = false;
                angular.forEach(selectedRows, function(data){
                    if(angular.equals(data, $scope.gridOptions.data[i])){
                        isSelected = true;
                    }
                })
                if(isSelected){
                    $scope.gridOptions.data.splice(i, 1);
                }
            }
            $timeout(function (){
                $scope.gridApi.selection.clearSelectedRows();
            });
        }

        function delAllUnChecked() {
            $scope.gridOptions.data = $scope.gridApi.selection.getSelectedRows();
            $scope.gridApi.selection.clearSelectedRows();
        }



        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: false,

            enableGridMenu : true,//表格 菜单
            showGridFooter: true,//表格的footer
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤

            enableHorizontalScrollbar : 1,//表格的水平滚动条
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv : false,
            exporterMenuPdf : false,
            exporterMenuLabel : "Export",
            exporterExcelFilename: 'share-charges.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],
            minimumColumnSize: 80,
            gridFooterTemplate:'<div class="ml-5 mt-5"><strong><span translate="global.ttlTitle" class="text-muted mr-5"></span><span class="label label-primary mr-8 f-13">{{grid.appScope.gridOptions.data.length}} shipments</span></strong><span ng-if="grid.appScope.gridApi.selection.getSelectedCount()"><button type="button" class="btn btn-default btn-xs ml-10 mr-5" ng-click="grid.appScope.vm.delAllChecked()"><span class="glyphicon glyphicon-remove mr-3"></span><span translate="entity.action.delAllChecked">delAllChecked</span></button><button type="button" class="btn btn-default btn-xs" ng-click="grid.appScope.vm.delAllUnChecked()"><span class="glyphicon glyphicon-remove mr-3"></span><span translate="entity.action.delAllUnChecked">delAllUnChecked</span></button></span></div>',
            columnDefs: [
                {
                    field: 'id',
                    displayName: '',
                    width: 200,
                    headerCellTemplate: '<div class="ui-grid-cell-contents text-primary f-17"  style="text-align: left;" translate="autopsApp.guide.hguideToShare">hguideToShare</div>',
                    enableSorting: false,
                    enableColumnMenu: false,
                    cellTemplate: '<div class="ui-grid-cell-contents"><span class="glyphicon glyphicon-remove-circle text-danger mr-10"  ng-click="grid.appScope.vm.delHGuide(row.entity)"></span><span class="label label-success mr-5" ng-if="row.entity.isMaster">M</span><span class="label label-info mr-5" ng-if="row.entity.isSub">H</span><a href="" ng-click="grid.appScope.BIZPOP(row.entity.guideId)" ng-if="row.entity.guideId">{{row.entity.jobNum}}</a><a href="" ng-click="grid.appScope.VIEWEXPRESS(row.entity.parcelId)" ng-if="row.entity.parcelId">{{row.entity.jobNum}}</a></div>'
                },
                { field: 'soNum',
                    displayName: $translate.instant('autopsApp.guide.soNum'),
                    width: 120},
                { field: 'mblNum',
                    displayName: $translate.instant('autopsApp.guide.mblNum'),
                    width: 120},
                { field: 'vol',
                    displayName: $translate.instant('autopsApp.guide.vol'),
                    width: 100,
                    type: 'number',
                    enableCellEdit: true,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'gw',
                    width: 100,
                    type: 'number',
                    enableCellEdit: true,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.guide.gw'),
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'cw',
                    width: 100,
                    type: 'number',
                    enableCellEdit: true,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.booking.chargeWeight'),
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'pallets',
                    width: 100,
                    type: 'number',
                    enableCellEdit: true,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.booking.palletNum'),
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'pkgNum',
                    width: 100,
                    type: 'number',
                    enableCellEdit: true,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    displayName:  $translate.instant('autopsApp.guide.pkgNum'),
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'teus',
                    displayName: 'TEU',
                    width: 100,
                    type: 'number',
                    enableCellEdit: true,
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'cargoType',
                    width: 100,
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.CARGOTYPES
                    },
                    displayName: $translate.instant('autopsApp.guide.cargoType'),
                    cellTemplate: '<span class="label label-info ml-5" ng-show="row.entity.cargoTypeLabel">{{row.entity.cargoTypeLabel}}</span>'},
                { field: 'shipmentType',
                    width: 100,
                    displayName: $translate.instant('autopsApp.guide.shipmentType'),
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.FMS_TYPES
                    },
                    cellTemplate: '<span ng-if="row.entity.shipmentType" class="label label-info ml-5" translate="global.{{row.entity.shipmentType}}"></span>'},
                { field: 'customRequired',
                    width: 100,
                    displayName: $translate.instant('autopsApp.booking.customRequired'),
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.customRequired"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.customRequired"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},

                { field: 'poNum',
                    width: 120,
                    displayName: $translate.instant('autopsApp.booking.poNum')},
                { field: 'etd',
                    width: 120,
                    displayName: $translate.instant('autopsApp.guide.etd')},
                { field: 'customerName',
                    width: 100,
                    displayName: $translate.instant('autopsApp.guide.customerName')},
                { field: 'shipperName',
                    width: 100,
                    displayName: $translate.instant('autopsApp.guide.home.shipper')},
                { field: 'porName',
                    width: 100,
                    displayName: $translate.instant('autopsApp.guide.porName')},
                { field: 'destName',
                    width: 100,
                    displayName: $translate.instant('autopsApp.guide.destName')},
                { field: 'deliveryTo',
                    width: 100,
                    displayName: $translate.instant('autopsApp.guide.deliveryTo')},

                { field: 'salesName',
                    width: 100,
                    displayName: $translate.instant('autopsApp.guide.salesName')},
                { field: 'opName',
                    width: 100,
                    displayName: $translate.instant('autopsApp.guide.opName')},
                { field: 'docName',
                    width: 100,
                    displayName: $translate.instant('autopsApp.guide.docName')}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj_2.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj_2.userId = $rootScope.account.id;
                    gridStateObj_2.gridId = 174;
                    GridState.update(gridStateObj_2, function(result){
                        gridStateObj_2 = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj_2.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj_2.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy_2);
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
            }
        }


    }
})();
