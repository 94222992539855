(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('opsVender-all', {
                parent: 'entity',
                url: '/settlement-object/all',
                data: {
                    authorities: ['VISIT_STMT_OBJ'],
                    pageTitle: 'global.menu.entities.opsVender'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/opsVender/opsVenders.html',
                        controller: 'OpsVenderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsClient');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('customNode');
                        return $translate.refresh();
                    }]
                }
            })
            .state('opsVender-client', {
                parent: 'entity',
                url: '/clients',
                data: {
                    authorities: ['FUN_CLIENT'],
                    pageTitle: 'autopsApp.opsClient.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/opsVender/opsVenders.html',
                        controller: 'OpsVenderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsClient');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('customNode');
                        return $translate.refresh();
                    }]
                }
            })
            .state('opsVender-bookingAgent', {
                parent: 'entity',
                url: '/bookingAgents',
                data: {
                    authorities: ['FUN_COLOADER'],
                    pageTitle: 'autopsApp.opsClient.home.bookingAgent'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/opsVender/opsVenders.html',
                        controller: 'OpsVenderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsClient');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('customNode');
                        return $translate.refresh();
                    }]
                }
            })
            .state('opsVender-overseasAgent', {
                parent: 'entity',
                url: '/overseasAgents',
                data: {
                    authorities: ['FUN_AGENT'],
                    pageTitle: 'autopsApp.opsClient.home.overseasAgent'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/opsVender/opsVenders.html',
                        controller: 'OpsVenderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsClient');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('customNode');
                        return $translate.refresh();
                    }]
                }
            })
            .state('opsVender-vender', {
                parent: 'entity',
                url: '/venders',
                data: {
                    authorities: ['FUN_VENDER'],
                    pageTitle: 'global.menu.entities.opsVender'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/opsVender/opsVenders.html',
                        controller: 'OpsVenderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsClient');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('customNode');
                        return $translate.refresh();
                    }]
                }
            })
            .state('opsVender.delete', {
                parent: 'opsVender',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/opsVender/opsVender-delete-dialog.html',
                        controller: 'OpsVenderDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['OpsVender', function(OpsVender) {
                                return OpsVender.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('opsVender', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }
})();
