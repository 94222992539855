(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountBindDialogController', AccountBindDialogController);

    AccountBindDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$uibModalInstance', '$translate', '$uibModal', '$location', '$stateParams',
    'entity', 'Dictionary', 'AccountBind', 'AddressBook', 'WmsWarehouse', 'ApiErp', 'DataUtils'];

    function AccountBindDialogController ($timeout, $scope, $rootScope, $uibModalInstance, $translate, $uibModal, $location, $stateParams,
        entity, Dictionary, AccountBind, AddressBook, WmsWarehouse, ApiErp, DataUtils) {
        var vm = this;

        vm.accountBind = entity;
        vm.clear = clear;
        vm.save = save;
        vm.clientSelected = clientSelected;
        vm.loadAddressBooks = loadAddressBooks;
        vm.onAddressSelected = onAddressSelected;
        vm.getWarehouses = getWarehouses;
        vm.warehouseSelected = warehouseSelected;
        vm.enableEdi = enableEdi;
        vm.wmsMaping = wmsMaping;
        vm.marketplaces = Dictionary.marketplaces;
        vm.typeChange = typeChange;
        vm.getStorageCosts = getStorageCosts;
        vm.generateKey = generateKey;
        vm.generateToken = generateToken;
        vm.showtxt = {};
        vm.GoEosPlatformOptions = [
            { label: 'Amazon', value: 'amazon' },
            { label: 'Walmart', value: 'walmart' },
            { label: 'eBay', value: 'ebay' },
            { label: 'Wayfair', value: 'wayfair' },
            { label: 'Home Depot', value: 'homedepot' },
            { label: 'Overstock', value: 'overstock' },
            { label: 'Lowes', value: 'lowes' },
            { label: 'Other', value: 'other' }
        ];
        vm.EbaoPayType = [
            { label: '预存款支付', value: '1' },
            { label: '月结支付', value: '2' },
            { label: '代收代付', value: '3' }];
        $timeout(function (){
            var absUrl = $location.absUrl();
            if(absUrl.indexOf("spapi_oauth_code") !== -1){   
                layer.load(1, {shade: 0.3});
                var stateStr = $location.search().state;
                var idx = stateStr.indexOf("_");
                stateStr = stateStr.substring(idx + 1);
                AccountBind.get({id : stateStr/82678665}, function(result){
                    vm.accountBind = result;
                    vm.accountBind.appKey = $location.search().selling_partner_id;
                    vm.accountBind.appToken = $location.search().spapi_oauth_code;
                    vm.accountBind.appPassword = $location.search().mws_auth_token;
                    var accountBind = angular.copy(vm.accountBind);
                    if(accountBind.autoChannelJson){
                        accountBind.autoChannelJson = angular.toJson(accountBind.autoChannelJson);
                    }
                    AccountBind.getAmazonRefreshToken(vm.accountBind, function(result){
                        vm.accountBind.appToken = result.appToken;
                        vm.accountBind.thirdPartCodeFd = null;
                        layer.closeAll();
                        save();
                    }, function(error){
                        layer.closeAll();
                        if(error && error.data && error.data.description){
                            $rootScope.OPS_ALERT(error.data.description);
                        }
                        else {
                            layer.msg($translate.instant('global.messages.submittedfailed'));
                        }
                    })
                });
                return;
            }
            if(absUrl.indexOf("mb-wms-auth") !== -1){   
                var stateStr = $location.search().data;
                var respObj = angular.fromJson(stateStr);
                $rootScope.OPS_ALERT("请复制授权码： " + respObj.accessToken);
                clear();
                return;
            }
            if(absUrl.indexOf("TikTokShopAuth") !== -1){   
                layer.load(1, {shade: 0.3});
                var stateStr = $location.search().state;
                var idx = stateStr.lastIndexOf("_");
                stateStr = stateStr.substring(idx + 1);
                AccountBind.get({id : stateStr/82678666}, function(result){
                    vm.accountBind = result;
                    vm.accountBind.appToken = $location.search().code;
                    var accountBind = angular.copy(vm.accountBind);
                    if(accountBind.autoChannelJson){
                        accountBind.autoChannelJson = angular.toJson(accountBind.autoChannelJson);
                    }
                    AccountBind.getTikTokToken(vm.accountBind, function(result){
                        layer.closeAll();
                        vm.accountBind = result;
                        layer.msg("授权成功");
                    }, function(error){
                        layer.closeAll();
                        if(error && error.data && error.data.description){
                            $rootScope.OPS_ALERT(error.data.description);
                        }
                        else {
                            layer.msg($translate.instant('global.messages.submittedfailed'));
                        }
                    })
                });
                return;
            }
            if (absUrl.indexOf("temucw-auth") !== -1) {                
                var redirectLink = $stateParams.redirectLink;
                layer.load(1, { shade: 0.3 });
                if(redirectLink){                    
                    var idx = redirectLink.indexOf("ticket=");
                    if(idx){
                        var ticket = redirectLink.substring(idx + 7);
                        console.log("ticket = " + ticket);
                        if(ticket){
                            AccountBind.tokenInfoPush({
                                platform: "TEMUCW", 
                                ticket: ticket
                            }, function(){
                                window.open(redirectLink, "_self");
                            }, function(error){
                                $rootScope.ALERT_ERROR(error);
                                $uibModalInstance.dismiss('cancel');
                            });                                
                        }
                    }
                }
                return;
            }
            angular.element('.form-group:eq(1)>input').focus();
            if(vm.accountBind && vm.accountBind.autoChannelJson){
                vm.accountBind.autoChannelJson = angular.fromJson(vm.accountBind.autoChannelJson, true);
            }
            typeChange();
        });
        function typeChange () {
            vm.severUrlPh = "";
            if(vm.accountBind.type == "ecang" || vm.accountBind.type == "westernpost" || vm.accountBind.type == "ecangFBA"){
                vm.severUrlPh = "http://***.com/default/svc/web-service";
            }
            else if(vm.accountBind.type == "MfyCang"){
                vm.severUrlPh = "http://***.com:8203";
            }
            else if(vm.accountBind.type == "anmei"){
                vm.severUrlPh = "https://api.anmeigroup.com";
            }     
            else if(vm.accountBind.type == "aidelivery"){
                vm.severUrlPh = "http://{域名}/WebService/PublicService.asmx?wsdl";
            }        
            else{
                vm.severUrlPh = "https://***.com";
            }

            // 平台的提示，提示哪些平台需要维护什么信息或注意事项
            vm.guideTip = "";
            if(vm.accountBind.type == "MfyCang") {
                vm.guideTip = "推送时需要维护“计费渠道(推单)”，映射此平台的“instructionsType”值(发货包裹类型)，未匹配或未映射默认值为OWN_PAKAGE";
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            var accountBind = angular.copy(vm.accountBind);
            if(accountBind.autoChannelJson){
                accountBind.autoChannelJson = angular.toJson(accountBind.autoChannelJson);
            }
            if (accountBind.id !== null) {
                AccountBind.update(accountBind, onSaveSuccess, onSaveError);
            } else {
                AccountBind.save(accountBind, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('autopsApp:accountBindUpdate', result);
            vm.accountBind = result;
            if(vm.accountBind && vm.accountBind.autoChannelJson){
                vm.accountBind.autoChannelJson = angular.fromJson(vm.accountBind.autoChannelJson, true);
            }
            vm.isSaving = false;
            layer.msg($translate.instant('global.save_success'));
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function clientSelected (client, fd) {
            vm.accountBind[fd] = client.id;
        }

        vm.addressBooks = [];
        function loadAddressBooks () {
            if (vm.addressBooks.length == 0 && !vm.isSaving) {
                vm.isSaving = true;
                AddressBook.getAll(function(result) {
                    vm.addressBooks = result;
                    vm.isSaving = false;
                });
            };
        };

        function onAddressSelected (data, fd) {
            vm.accountBind[fd] = data.id;
        }

        vm.wmswarehouses = [];
        function getWarehouses () {
            if(vm.wmswarehouses.length > 0){
                return;
            }
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: true,
                    includeGroup: false
                }, function (data) {
                vm.wmswarehouses = data;
            });
        }

        function warehouseSelected (data) {
            vm.accountBind.warehouseId = data.id;
            if(data.isVirtual){
                vm.accountBind.companyId = data.medCompanyId;
            }
            else {
                vm.accountBind.companyId = data.companyId;
            }            
        }

        function enableEdi () {
            if(!vm.accountBind.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            $scope.ediChannels = [];
            $scope.ediWarehouses = [];
            layer.msg("Service availability testing...", {
              shade: 0.3,
              time: 200000
            });
            var accountBind = angular.copy(vm.accountBind);
            if(accountBind.autoChannelJson){
                accountBind.autoChannelJson = angular.toJson(accountBind.autoChannelJson);
            }
            if(accountBind.type == 'chukou1' && !accountBind.appPassword){
                layer.prompt({
                  formType: 0,
                  value: "",
                  title: "Input Code",
                  area: ['230px', '160px']
                }, function(value, index, elem){
                    AccountBind.getChukou1Token({
                        code: value
                    }, accountBind, function(result){
                        layer.closeAll();
                        vm.accountBind = result;
                        if(vm.accountBind && vm.accountBind.autoChannelJson){
                            vm.accountBind.autoChannelJson = angular.fromJson(vm.accountBind.autoChannelJson, true);
                        }
                        if(vm.accountBind.enabled){
                            layer.msg($translate.instant('global.valid_success'));
                        }
                        else {
                            layer.msg($translate.instant('global.invalid_success'));
                        }    
                    }, function(result){
                        layer.closeAll();
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.accountBind.enableFailed'));                 
                    })  
                    layer.close(index);
                });
                return;
            }

            AccountBind.enableEdi(accountBind, function (result) {
                layer.closeAll();
                vm.accountBind = result;
                if(vm.accountBind && vm.accountBind.autoChannelJson){
                    vm.accountBind.autoChannelJson = angular.fromJson(vm.accountBind.autoChannelJson, true);
                }
                if(vm.accountBind.enabled){
                    layer.msg($translate.instant('global.valid_success'));
                }
                else {
                    layer.msg($translate.instant('global.invalid_success'));
                }                
            }, function(error){
                layer.closeAll();
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                }   
                else {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.accountBind.enableFailed'));
                }
            });
        }

        function wmsMaping () {
            if(!vm.accountBind.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/wms-map/wms-map-dialog.html',
                controller: 'WmsMapDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsMap');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return angular.copy(vm.accountBind);
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }
        
        $scope.bindedAccounts = [];
        $scope.getAllAccounts = function() {
            if($rootScope.account.companyId && $rootScope.HAVENOAUTH('AU_LABEL_ACCOUNT')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_LABEL_ACCOUNT');
                return;            
            }
            if ($scope.bindedAccounts.length == 0 && !$scope.isSaving) {
                $scope.isSaving = true;
                AddressBook.getAllAccounts({includeChargeChannel: true}, function(result) {
                    $scope.bindedAccounts = result;
                    $scope.isSaving = false;
                });
            };
        };

        $scope.autoChannelEnable = function() {
            if(!vm.accountBind.enabled){
                layer.msg($translate.instant("autopsApp.accountBind.enableEdiAlert"));
                return;
            }
            vm.accountBind.autoChannel = !vm.accountBind.autoChannel;
            if(!vm.accountBind.autoChannel){
                vm.accountBind.autoChannelJson = [];
            }            
        }


        $scope.shipServiceSelected = function(item) {
            if(!vm.accountBind.autoChannelJson){
                vm.accountBind.autoChannelJson = [];
            }
            var isNew = true;
            angular.forEach(vm.accountBind.autoChannelJson, function(data){
                if(data.id){
                    if(item.id == data.id){
                        isNew = false;
                    }                    
                }
                else if (data.code == item.code){
                    isNew = false;
                }
            })
            if(isNew){
                var service = {
                    "id": item.id,
                    "code": item.code
                }
                vm.accountBind.autoChannelJson.push(service);
            }            
            vm.accountBind.channelCode="";
        }

        $scope.delService = function(index) {
            vm.accountBind.autoChannelJson.splice(index, 1);
        };

        $scope.goAmazon = function() {
            if(!vm.accountBind.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            var amazonUrl = "https://sellercentral-europe.amazon.com";
            if(vm.accountBind.payeeCode == "US" || vm.accountBind.payeeCode == "CA" || vm.accountBind.payeeCode == "MX" || vm.accountBind.payeeCode == "BR"){
                amazonUrl = "https://sellercentral.amazon.com";
            }
            else if (vm.accountBind.payeeCode == "SG" || vm.accountBind.payeeCode == "AU" || vm.accountBind.payeeCode == "JP" || vm.accountBind.payeeCode == "CN"){
                amazonUrl = "https://sellercentral-japan.amazon.com";
            }
            amazonUrl += "/apps/authorize/consent?application_id=amzn1.sp.solution.ed9c671c-70ae-42b0-9008-e9e89c360f9c&state=";
            // amazonUrl += "/apps/authorize/consent?application_id=amzn1.sellerapps.app.18cf8d02-dfb5-446b-8121-b48f1d7667c8&state=";
            var absUrl = $location.absUrl();
            amazonUrl += $location.protocol() + "-" + $location.host() + "_" + (vm.accountBind.id * 82678665);
            window.open(amazonUrl);
            $rootScope.OPS_ALERT($translate.instant("autopsApp.accountBind.loginAuthAlert"), {
                icon: 0, 
                yes:function(){
                    $uibModalInstance.close();
                    layer.closeAll();
                }
            });            
        };

        $scope.amazonMarketplaceChange = function() {
            vm.accountBind.appKey = null;
            vm.accountBind.appToken = null;
            vm.accountBind.appPassword = null;
        }

        $scope.ediWarehouses = [];
        $scope.getEdiWarehouses = function() {
            if(!vm.accountBind.id || !vm.accountBind.enabled || $scope.ediWarehouses.length > 0){
                return;
            }
            AccountBind.getEdiWarehouses(vm.accountBind, function(result){
                $scope.ediWarehouses = result;
            }, $rootScope.ALERT_ERROR);
        }

        $scope.ediChannels = [];
        $scope.getEdiChannels = function() {
            if(!vm.accountBind.id || !vm.accountBind.enabled || $scope.ediChannels.length > 0){
                return;
            }
            AccountBind.getEdiChannels(vm.accountBind, function(result){
                $scope.ediChannels = result;
            }, $rootScope.ALERT_ERROR);
        }

        $scope.goMbWms = function() {
            if(!vm.accountBind.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            var redirectUrl = $location.host();
            if(redirectUrl.indexOf("127.0.0.1") !== -1){
                 redirectUrl += ":8080";
            }
            // redirectUrl += "/#/mb-wms-auth";
            AccountBind.genAuthUrl({
                redirectUrl: redirectUrl
            }, vm.accountBind, function(result){
                if(result.value){
                    window.open(result.value);
                }
            }, $rootScope.ALERT_ERROR);            
            $rootScope.OPS_ALERT($translate.instant("autopsApp.accountBind.loginAuthAlert"), {
                icon: 0, yes:function(){
                    layer.closeAll();
                }
            });            
        };
        $scope.isEnableEdi = function() {
            return vm.accountBind.type == 'AmazonSftp' || vm.accountBind.type == 'wayfair' || vm.accountBind.type == 'wayfairSftp' 
            || vm.accountBind.type == 'chukou1' || vm.accountBind.type == 'ecang' || vm.accountBind.type == 'westernpost' 
            || vm.accountBind.type == 'anmei' || vm.accountBind.type == 'logiwa' || vm.accountBind.type == 'biaoju' 
            || vm.accountBind.type == 'pedder' || vm.accountBind.type == 'RXO' || vm.accountBind.type == 'AmazonTrack' 
            || vm.accountBind.type == 'TEMUCW';
        }
        $scope.goTikTokShop = function() {
            if(!vm.accountBind.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if (!vm.accountBind.thirdPartCode) {
                layer.msg("'APP & Service ID' Can not empty!");
                return;
            }
            var amazonUrl = "https://services.us.tiktokshop.com/open/authorize?service_id=" + vm.accountBind.thirdPartCode +"&state=";
            amazonUrl += $location.protocol() + "-" + $location.host() + "_" + "TikTokShopAuth" + "_" + (vm.accountBind.id * 82678666);
            window.open(amazonUrl);
            $rootScope.OPS_ALERT($translate.instant("autopsApp.accountBind.loginAuthAlert"), {
                icon: 0, 
                yes:function(){
                    $uibModalInstance.close();
                    layer.closeAll();
                }
            });            
        };

        function getStorageCosts () {
            ApiErp.getStorageCosts(vm.accountBind, function (result) {
                // body...
            })
        }
        function generateKey() {
            if(vm.accountBind.appKey && vm.accountBind.id){
                $rootScope.OPS_ALERT("取消/更新授权会导致合作仓的订单无法下发、已发货订单无法更新平台订单状态、以及订单无法取消成功等一系列严重后果!");
                return;
            }
            vm.accountBind.appKey = DataUtils.randomWord(false, 32, 32);
        }
        function generateToken() {
            if(vm.accountBind.appToken && vm.accountBind.id){
                $rootScope.OPS_ALERT("取消/更新授权会导致合作仓的订单无法下发、已发货订单无法更新平台订单状态、以及订单无法取消成功等一系列严重后果!");
                return;
            }
            vm.accountBind.appToken = DataUtils.randomWord(false, 64, 64);
        }

        

    }
})();
