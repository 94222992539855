(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WorkTableHomeController', WorkTableHomeController);

    WorkTableHomeController.$inject = ['$rootScope', '$scope', '$interval', '$state', '$translate', '$http', '$uibModal',
    'WinAES', 'DataUtils', 'AlertService', 'pagingParams', 'paginationConstants', 'Principal', 'Guide', 'OpsCompany', 'GuideTemplate', 'GuideSearch','ParseLinks','Booking'];

    function WorkTableHomeController($rootScope, $scope, $interval, $state, $translate, $http, $uibModal,
        WinAES, DataUtils, AlertService, pagingParams, paginationConstants, Principal, Guide, OpsCompany, GuideTemplate, GuideSearch, ParseLinks, Booking) {

        $scope.getWorkload = function  (){
            if($scope.isSaving || !$rootScope.account.companyId){
                return;
            }
            Guide.getWorkload(function(result){
                $rootScope.WORKLOAD = result;
                layer.msg($translate.instant('autopsApp.guide.workloadUpdated'));
                $translate.refresh();
            });
        }

        if(!$rootScope.WORKLOAD.ttlAmt){
            $scope.getWorkload();
        }

        // $interval($scope.getWorkload, 180000);
        $scope.roleSet = function(role) {
            if($rootScope.choosedWork != role){
                $scope.guides = [];
            }
            $rootScope.choosedWork = role;
            // $scope.getWorkload();
            setTimeout(function() {frzTable("divTable", "showTable", "footPager");}, 100);
        }

        $scope.filterSet = function(status) {
            $rootScope.filterValue = status;
            vm.isAdvanceSearch = true;
            vm.searchQuery = null;
            pagingParams.search = null;
            vm.clear();
        }

        $scope.guides = [];
        var vm = this;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.isAdvanceSearch = false;

        loadAll();
        function loadAll () {
            if(!$rootScope.choosedWork || !$rootScope.filterValue){
                return;
            }
            if (pagingParams.search) {
                GuideSearch.tableQuery({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    role: $rootScope.choosedWork,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Guide.getAllTableGuides({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    role: $rootScope.choosedWork,
                    type: $rootScope.filterValue,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                $scope.guides = data;
                angular.forEach($scope.guides, function(data){
                    data.encodeId = WinAES.encode(data.id.toString());
                    DataUtils.fillFdLabel(data);
                })
                vm.page = pagingParams.page;
                vm.isAdvanceSearch = false;
                setTimeout(function() {frzTable("divTable", "showTable", "footPager");}, 100);
            }
            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = true;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            if($rootScope.choosedWork == "TABLE_BOOKING"){
                if ($rootScope.filterValue == "notBooked"){
                    vm.predicate = 'bookingTime';
                }
                else{
                    vm.predicate = 'soReleaseTime';
                }
            }
            else if ($rootScope.choosedWork == "TABLE_TRUCK"){
                if ($rootScope.filterValue == "notTruck"){
                    vm.predicate = 'truckArrangeTime';
                }
                else{
                    vm.predicate = 'ctnsLoadedTime';
                }
            }
            else if ($rootScope.choosedWork == "TABLE_CUSTOM"){
                if ($rootScope.filterValue == "notCustom"){
                    vm.predicate = 'customArrangeTime';
                }
                else{
                    vm.predicate = 'customReleaseTime';
                }
            }
            // vm.predicate = 'bookingTime';
            vm.reverse = false;
            vm.currentSearch = null;
            if (vm.isAdvanceSearch){
                vm.itemsPerPage = paginationConstants.itemsPerPage;
                vm.isAdvanceSearch = false;
                loadAll();
            }
            else {
                vm.transition();
            }
            $scope.teusTotal = 0;
        }

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        }


        $scope.inputedValue = "";
        var inputPopIndex = 0;
        var guideCopy;
        $scope.upateSoNum = function(guide) {
            var title = $translate.instant('autopsApp.guide.table.upateSoNum');
            $scope.inputedValue = guide.soNum;
            guideCopy = angular.copy(guide);
            inputPopIndex = layer.open({
                type: 1,
                area: ['380px', '170px'],
                title: title,
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#inputSoNumPop")
            });
            document.getElementById("inputAmount_field").focus();
        }

        var onSaveSoSuccess = function(result) {
            angular.forEach($scope.guides, function(data){
                if(data.id == result.id){
                    data.soNum = result.soNum;
                    data.soReleaseTime = result.soReleaseTime;
                }
            })
            $scope.isSaving = false;
            layer.msg($translate.instant('global.save_success'));
        };
        var onSaveSoError = function(result) {
            $scope.isSaving = false;
            if (result.status === 409) {
                layer.msg($translate.instant('autopsApp.guide.home.soNumRepeated'));
            } else {
                layer.msg($translate.instant('global.save_fail'));
            }
        };
        $scope.saveSoNum = function() {
            layer.close(inputPopIndex);
            $scope.isSaving = true;
            Guide.upateSoNum({
                soNum: $scope.inputedValue,
                companyId: guideCopy.companyId,
                guideId: guideCopy.id
            }, onSaveSoSuccess, onSaveSoError);
        }


        $scope.guideDialog = function(guide, tab) {
            if(!guide.isExport){
                $state.go('import.detail',{guideId: WinAES.encode(guide.id.toString())});
                return;
            }
            $rootScope.GUIDEACTIVETAB = tab;
            $rootScope.id = "view"
            var htmlPath = 'app/entities/guide/sea-dialog.html';
            var controllerName = 'SeaDialogController';
            if(guide.shipmentType == "AIR"){
                var htmlPath = 'app/entities/guide/air-dialog.html';
                var controllerName = 'AirDialogController';
            }
            else if (guide.shipmentType == "EXPRESS" || guide.shipmentType == "FBAAIR" || guide.shipmentType == "FBASEA"
                || guide.shipmentType == "WMS" || guide.shipmentType == "LOCAL" || guide.shipmentType == "LOCALKG"){
                var htmlPath = $rootScope.FBA_HTML_PATH();
                var controllerName = 'FbaDialogController';
            }
            $uibModal.open({
                templateUrl: htmlPath,
                controller: controllerName,
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return Guide.get({id : guide.id}).$promise;
                    }]
                }
            }).result.then(function(result) {
                angular.forEach($scope.guides, function(data){
                    if(data.id == result.id){
                        data.hblConfirmedTime = result.hblConfirmedTime;
                        data.mblConfirmedTime = result.mblConfirmedTime;
                        data.customArrangeTime = result.customArrangeTime;
                        data.customReleaseTime = result.customReleaseTime;
                        data.truckArrangeTime = result.truckArrangeTime;
                        data.bookingTime = result.bookingTime;
                        data.dnSentTime = result.dnSentTime;
                        data.hblReleasedTime = result.hblReleasedTime;
                        data.saSentTime = result.saSentTime;
                        data.localTimeTwo = result.localTimeTwo;
                        data.ctnsLoadedTime = result.ctnsLoadedTime;
                        data.localTimeOne = result.localTimeOne;
                        data.lastModifiedTime = result.lastModifiedTime;
                        data.soReleaseTime = result.soReleaseTime;
                        guide.status = result.status;
                        guide.mblOnly = result.mblOnly;
                        guide.isVoid = result.isVoid;
                        guide.checkStatus = result.checkStatus;
                        guide.shipmentType = result.shipmentType;
                        guide.soNum = result.soNum;
                        guide.shipperName = result.shipperName;
                        guide.polName = result.polName;
                        guide.podName = result.podName;
                        guide.destName = result.destName;
                        guide.etd = result.etd;
                        guide.eta = result.eta;
                        guide.volumes = result.volumes;
                        guide.carrier = result.carrier;
                        guide.mblNum = result.mblNum;
                        guide.vsl = result.vsl;
                        guide.voy = result.voy;
                        guide.cls = result.cls;
                        guide.bizType = result.bizType;
                        guide.salesName = result.salesName;
                        guide.opName = result.opName;
                        guide.docName = result.docName;
                        guide.deliveryName = result.deliveryName;
                        guide.bookingToName = result.bookingToName;
                        guide.createdTime = result.createdTime;
                        guide.hblNum = result.hblNum;
                        guide.poNum = result.poNum;
                        guide.jobNum = result.jobNum;
                        guide.serviceTerm = result.serviceTerm;
                    }
                })
            }, function(result) {

            });
        }


        var memoCopy;
        $scope.copyMemo = function(guide, memo) {
            memoCopy = angular.copy(guide[memo]);
        }
        $scope.saveMemo = function(guide, memo) {
            if(memoCopy != guide[memo]){
                Guide.upateMemo({
                    memoString: guide[memo],
                    type: $rootScope.choosedWork,
                    companyId: guide.companyId,
                    guideId: guide.id
                }, onSaveSoSuccess, onSaveSoError);
            }
        }
        $scope.getDepWorkload = function() {
            if ($rootScope.HAVENOAUTH('ROLE_DEPARTMENT')){
                layer.msg($translate.instant('global.forbiddenTeam'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/work-table/work-table-list.html',
                controller: 'WorkTableListController',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return Guide.getDepWorkload().$promise;
                    }]
                }
            }).result.then(function(result) {
            }, function(result) {
            });
        }

        $scope.checkPop = function(guide) {
            var guideCheckStatus = guide.checkStatus;
            var guideCheckReamrks = guide.checkRemarks;
            $uibModal.open({
                templateUrl: 'app/entities/guideRates/guideRates-dialog.html',
                controller: 'GuideRatesDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return guide;
                    }]
                }
            }).result.then(function(result) {
            }, function(result) {
                guide.checkStatus = guideCheckStatus;
                guide.checkRemarks = guideCheckReamrks;
            });
        };

        $scope.setAllCleared = function() {
            if(!$scope.guides || $scope.guides.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            layer.confirm($translate.instant('autopsApp.guide.table.setAllClearedAlert'), {
                title: $translate.instant('global.alert_title'),
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                $scope.isSaving = true;
                Guide.setAllCleared({role: $rootScope.choosedWork}, $scope.guides, function(){
                    $scope.isSaving = false;
                    loadAll();
                    $scope.getWorkload();
                    layer.msg($translate.instant('global.save_success'));
                }, function(){
                    $scope.isSaving = false;
                    layer.msg($translate.instant('global.save_fail'));
                });
                layer.close(index);
            }, function() {});
        }

        $scope.setCheckedId = function(id) {
            $scope.checkedId = id;
        }
        var isOp = function() {
            var authorities = ["ROLE_BOSS", "ROLE_DOCUMENT", "ROLE_OPERATOR"];
            if (!$rootScope.account || angular.equals($rootScope.account, {})){
                return false;
            }
            for (var i = 0; i < authorities.length; i++) {
                if ($rootScope.account.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }
            return false;
        };

        $scope.jhfAuthPushUsers = ["zouxy", "huawujm1", "hualvmt", "huayuyd", "huayewf", "pony"];
        $scope.jhfAuthPushChange = function(){
            OpsCompany.jhfAuthPushChange({
                companyId: $rootScope.account.companyId
            },function(result){
                var idx = $rootScope.account.authorities.indexOf('JHF_AUTOPUSH');
                if(idx > -1){                    
                    $rootScope.account.authorities.splice(idx, 1);
                }
                else {
                    $rootScope.account.authorities.push('JHF_AUTOPUSH');
                }
                layer.msg($translate.instant('global.save_success'));
            }, $rootScope.ALERT_ERROR)
        }

    }

})();
