(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WoHeadDialogController', WoHeadDialogController);

    WoHeadDialogController.$inject = ['$timeout', '$rootScope', '$scope', '$stateParams', '$uibModalInstance', '$http', '$translate', 
        'entity', 'WoHead', 'OpsCompany'];

    function WoHeadDialogController ($timeout, $rootScope, $scope, $stateParams, $uibModalInstance, $http, $translate, 
        entity, WoHead, OpsCompany) {
        var vm = this;

        vm.woHead = entity;
        vm.clear = clear;
        vm.save = save;
        vm.saveAlert = saveAlert;
        vm.woBody = {};

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
            if(!vm.woBody.files){
                vm.woBody.files = [];
            }
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            var content = "工单主题: \n" + vm.woHead.woTitle + "\n\n";
            if(vm.woHead.type == 'bug'){
                if(vm.woBody.wechatNum){
                    content += "联系微信: " + vm.woBody.wechatNum + "\n";
                }
                if(vm.woBody.wechatNum){
                    content += "联系QQ: " + vm.woBody.qqNum + "\n";
                }
                if(vm.woBody.telNum){
                    content += "联系电话: " + vm.woBody.telNum + "\n";
                }
                if(vm.woBody.content){
                    content += "问题描述: \n" + vm.woBody.content;
                }
            }
            else {
                if(vm.woBody.bizCase){
                    content += "业务应用场景: \n" + vm.woBody.bizCase + "\n\n";
                }
                if(vm.woBody.content){
                    content += "功能详细描述: \n" + vm.woBody.content;
                }
            }
            vm.woHead.woBodys[0].content = content;
            vm.woHead.woBodys[0].userName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            vm.woHead.woBodys[0].time = new Date();
            vm.woHead.woBodys[0].cusReply = false;

            if (vm.woHead.id !== null) {
                WoHead.update(vm.woHead, onSaveSuccess, onSaveError);
            } else {
                WoHead.save(vm.woHead, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.woHead = result;
            vm.woBody.id = result.woBodys[0].id;
            $scope.$emit('autopsApp:woHeadUpdate', result);
            // $uibModalInstance.close(result);
            layer.msg($translate.instant('global.messages.submitted'));
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function saveAlert () {
            layer.msg($translate.instant('global.saveAlert'));
        };

        document.addEventListener('paste', function (event) {
            if(document.hidden){
                return;
            }
            if(!vm.woBody.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            // console.log(event);
            if (event.clipboardData || event.originalEvent) {
                var clipboardData = (event.clipboardData || event.originalEvent.clipboardData);
                if(clipboardData.items && clipboardData.items.length == 1){
                    var  items = clipboardData.items,
                        len = items.length,
                        blob = null;
                    for (var i = 0; i < len; i++) {
                        // console.log(items[i]);
                        if (items[i].type.indexOf("image") !== -1) {
                            //getAsFile() 此方法只是living standard firefox ie11 并不支持
                            blob = items[i].getAsFile();
                            $rootScope.UPLOADFILE(blob, '/woBody/' + vm.woBody.id + '/',
                                vm.woBody.files, vm.woHead.companyId);
                        }
                    }
                }
            }
        })

        $scope.groupCompanys = [];
        $scope.loadCompanys = function() {
            if ($scope.groupCompanys && $scope.groupCompanys.length > 0) {
                return;
            }
            OpsCompany.getBindCompanys(function(result) {
                $scope.groupCompanys = result;
            })
        };

    }
})();
