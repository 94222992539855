(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WarehousingDataDialogController', WarehousingDataDialogController);

    WarehousingDataDialogController.$inject = ['$scope', '$rootScope', '$timeout', '$http', '$uibModal', '$translate', '$uibModalInstance',
        'entity', 'ManageEnterprise', 'WmsIn', 'DataUtils', 'OpsVender', 'WmsWarehouse', 'DateUtils', 'uiGridConstants',
        'Guide', 'AddressBook', 'ExpressParcel', 'WarehousingData', 'OpsFiles', 'OpsCost', 'uiGridGroupingConstants', 'GridState'
    ];

    function WarehousingDataDialogController($scope, $rootScope, $timeout, $http, $uibModal, $translate, $uibModalInstance,
        entity, ManageEnterprise, WmsIn, DataUtils, OpsVender, WmsWarehouse, DateUtils, uiGridConstants,
        Guide, AddressBook, ExpressParcel, WarehousingData, OpsFiles, OpsCost, uiGridGroupingConstants, GridState) {
        var vm = this;
        vm.bizType = entity.type;
        vm.addCargo = addCargo;
        vm.delCargo = delCargo;
        vm.submitIn = submitIn;
        vm.updateTtl = updateTtl;
        vm.updateVol = updateVol;
        vm.focusCargo = focusCargo;
        vm.getWarehouses = getWarehouses;
        vm.warehouseSelected = warehouseSelected;
        vm._onLocationSelected = _onLocationSelected;
        vm.countrySelected = countrySelected;
        vm.getLocations = getLocations;
        vm.getBarcode = getBarcode;
        vm.getShipment = getShipment;
        vm.clear = clear;
        vm.clearGuide = clearGuide;
        vm.deleteByGuideId = deleteByGuideId;
        vm.autoRemoteCheck = autoRemoteCheck;
        vm.websockSubscribe = websockSubscribe;
        vm.delSelected = delSelected;
        vm.batchAmend = batchAmend;
        vm.batchAmendChange = batchAmendChange;
        vm.setAutoPrint = setAutoPrint;

        vm.locations = [];
        $scope.guide = {};
        vm.thisCargo = {};
        vm.isSaving = false;

        function setAutoPrint() {
            vm.autoPrint = !vm.autoPrint;
            vm.continuousPrinting = vm.autoPrint;
            vm.printExpressLabel = vm.autoPrint;
        }

        var initGuidePl = function() {
            if (!$scope.guide.warehousingDatas || $scope.guide.warehousingDatas.length == 0) {
                $scope.guide.warehousingDatas = [];
                vm.thisCargo = angular.copy(newCargo);
                // vm.thisCargo.pkgNum = $scope.guide.booking.pkgNum;
                // vm.thisCargo.gwTtl = $scope.guide.booking.gw;
                // vm.thisCargo.volTtl = $scope.guide.booking.vol;
                // if(vm.thisCargo.pkgNum){
                //     if(vm.thisCargo.gwTtl){
                //         vm.thisCargo.gw = DataUtils.round(vm.thisCargo.gwTtl/vm.thisCargo.pkgNum, 2);
                //     }
                //     if(vm.thisCargo.volTtl){
                //         vm.thisCargo.vol = DataUtils.round(vm.thisCargo.volTtl/vm.thisCargo.pkgNum, 4);
                //     }
                // }
                // if(vm.locations && vm.locations.length > 0){
                //     _onLocationSelected (vm.locations[0], vm.thisCargo);
                // }
                $scope.guide.warehousingDatas.push(vm.thisCargo);
            }
            angular.forEach($scope.guide.warehousingDatas, function(data){
                updateVwCw(data);
            })
            if(vm.locations && vm.locations.length > 0){
                autoFillLocation();
            }

            if(!$scope.guide.cargoType){
                $scope.guide.cargoType = "generalCargo";
            }
            if(!$scope.guide.destCountryCode){
                $scope.guide.destCountryCode = "US";
                $scope.guide.destCountryName = "美国";
            }
            else{
                $scope.guide.destCountryName = $rootScope.getCountryName($scope.guide.destCountryCode);
            }
            if(!$scope.guide.shipmentType){
                $scope.guide.shipmentType = vm.bizType == "WMS"?"WMS":"FBASEA";
            }
            vm.pkgsTtl = $scope.guide.booking.pkgNum;
            getWarehouses();
            getDeliveryChannel();
            $scope.gridOptions.data = $scope.guide.warehousingDatas;
            angular.forEach($scope.gridOptions.columnDefs, function(col) {
                if(col.field == "vw"){
                    col.displayName = $translate.instant('autopsApp.wmsIn.vw') + "(" + $scope.guide.booking.divVolBy + ")";
                }
                if(col.field == "cw"){
                    col.displayName = $translate.instant('autopsApp.wmsIn.cw') + "(" + ($scope.guide.chargeByCbm?"CBM":"KG") + ")";
                }
            })
            $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);
        };

        var deliveryChannel = null;
        function getDeliveryChannel() {
            if(!$scope.guide.deliveryChannel){
                deliveryChannel = null;
                return;
            }
            if(deliveryChannel && deliveryChannel.channel == $scope.guide.deliveryChannel){
                return;
            }
            Guide.getDeliveryChannelByGuide({}, $scope.guide, function(result) {
                deliveryChannel = result;
                angular.forEach($scope.guide.warehousingDatas, function(data){
                    updateVwCw(data);
                })
            })
        }


        function clearGuide() {
            if($scope.guide.id){
                $scope.guide = {};
            }
        }

        function getShipment() {
            if (!$scope.guide.jobNum) {
                return;
            }
            vm.isSaving = true;
            Guide.getFmsRapidInGuide({
                jobNum: $scope.guide.jobNum
            }, function(result) {
                $scope.guide = result;
                initGuidePl();
                vm.isSaving = false;
                if(vm.autoPrint){
                    autoPrintLabel();
                }
                layer.msg($translate.instant('entity.action.loadSuccess'));
            }, function(result){
                vm.isSaving = false;
                if(result.status == 406){
                    layer.msg("It's FCL shipment!");
                }
                else if(result.status == 403){
                    layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                }
                else if(result.status == 404){
                    layer.msg($translate.instant('global.noRecord'));
                }
                else {
                    layer.msg($translate.instant('global.loadFailed'));
                }
            })
        }

        vm.equipmentConnected = false;
        function websockSubscribe (userId) {
            if(!$rootScope.account.companyId || $rootScope.HAVENOAUTH('ROLE_WAREHOUSE')){
                $rootScope.FORBIDDEN_BY_ALERT('ROLE_WAREHOUSE');
                return;
            }
            if($rootScope.HAVENOAUTH('GOODSCAN')){
                layer.msg("No binded equipment..")
                return;
            }
            WarehousingData.websockSubscribe({
                userId: userId,
                companyId: $rootScope.account.companyId
            }, function (result) {
                if(result && result.key){
                    $rootScope.OPS_ALERT($translate.instant('global.messages.linkEquipmentAlert', {param: result.value + "(" + result.key + ")"}));
                }
                else if(userId){
                    layer.load(1, { shade: 0.3 });
                }
                else {
                    $rootScope.OPS_ALERT($translate.instant('global.messages.equipmentDisConnected'))
                }
            })
        }


        function loadGuide (guideId) {
            if(!guideId){return;}
            var loadIndex = layer.load(1, {shade: 0.3});
            Guide.getFmsRapidInGuide({
                id: guideId
            }, function(result) {
                layer.close(loadIndex);
                $scope.guide = result;
                initGuidePl();
            }, function(error){
                layer.close(loadIndex);
                if(error.status == 403){
                    layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                }
                else if(error.status == 404){
                    layer.msg($translate.instant('global.noRecord'));
                }
                else{
                    layer.msg($translate.instant("global.loadFailed"));
                }
                clear();
            });
        }

        function clear () {
            $uibModalInstance.close();
        }

        function getBarcode (obj, fd) {
            if(obj.locked){
                return;
            }
            getLocations();
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-dialog.html',
                controller: 'WmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md'
            }).result.then(function(code) {
                if (fd == "code"){
                    angular.forEach(vm.locations, function(data){
                        if(data.code == code){
                            _onLocationSelected(data, obj);
                        }
                    });
                }
                else {
                    obj[fd] = code;
                }
                if(fd =="jobNum"){
                    getShipment();
                }
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        function _onLocationSelected (item, cargo) {
            cargo.location = item.code;
            angular.forEach($scope.guide.warehousingDatas, function (data) {
                if(!data.location){
                    data.location = item.code;
                }
            })
        }
        function countrySelected (item) {
            $scope.guide.destCountryCode = item.code;
        }

        function getLocations () {
            if(!$scope.guide.whId){
                layer.msg($translate.instant('autopsApp.wmsIn.chooseWarehouseAlert'));
                return;
            }
            if(vm.isSaving){
                return;
            }
            if (!vm.locations || vm.locations.length == 0){
                vm.isSaving = true;
                WmsWarehouse.getLocations({warehouseId: $scope.guide.whId}, function (data) {
                    vm.isSaving = false;
                    vm.locations = data;
                    autoFillLocation();
                });
            }
        }

        var autoFillLocation = function(){
            if(vm.locations
                && vm.locations.length > 0
                && $scope.guide.warehousingDatas
                && $scope.guide.warehousingDatas.length > 0){
                angular.forEach($scope.guide.warehousingDatas, function(cargo){
                    if(!cargo.location){
                        _onLocationSelected(vm.locations[0], cargo);
                    }
                })
            }
        }

        var newCargo = {pkgType:"CTNS", pkgNum:1, companyId: $rootScope.account.companyId};
        function initRapidIn() {
            $scope.guide = {
                jobNum: null,
                createdBy: $rootScope.account.id,
                pkgNum: 1,
                shipmentType: vm.bizType == "WMS"?"WMS":"FBASEA",
                cargoType: "generalCargo",
                destCountryCode: "US",
                destCountryName: $rootScope.getCountryName("US"),
                deliveryWay: "express",
                status: "wmsIn",
                chargeByCbm: false,
                booking: {
                    divVolBy: 6000
                },
                warehousingDatas: []
            }
            vm.thisCargo= angular.copy(newCargo);
            if(vm.locations && vm.locations.length > 0){
                _onLocationSelected (vm.locations[0], vm.thisCargo);
            }
            $scope.guide.warehousingDatas.push(vm.thisCargo);
            getWarehouses();
            $scope.guide.destCountryName = $rootScope.getCountryName($scope.guide.destCountryCode);
        }
        initRapidIn();

        function addCargo() {
            if($scope.guide.plConfirmed){
                layer.msg($translate.instant('autopsApp.guide.sts.plConfirmed'));
                return;
            }
            if (!$scope.guide.warehousingDatas) {
                $scope.guide.warehousingDatas = [];
            }
            updateTtl(true);
            vm.thisCargo= angular.copy(newCargo);
            if(vm.locations && vm.locations.length > 0){
                _onLocationSelected (vm.locations[0], vm.thisCargo);
            }
            $scope.guide.warehousingDatas.splice(0, 0, vm.thisCargo);
            $timeout(function() {
                $("#gw_field").focus();
            });
        }

        function focusCargo(cargo) {
            vm.thisCargo = cargo;
        }
        function delCargo(cargo) {
            if($scope.guide.plConfirmed){
                layer.msg($translate.instant('autopsApp.guide.sts.plConfirmed'));
                return;
            }
            if(cargo.id){
                layer.confirm($translate.instant('global.confirmToDelete'), {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    layer.close(index);
                    WarehousingData.delete({
                        id: cargo.id
                    }, function(){
                        loadGuide($scope.guide.id);
                    }, function(result){
                    });
                }, function() {});
                return;
            }
            var index = $scope.guide.warehousingDatas.indexOf(cargo);
            if (index > -1) {
                $scope.guide.warehousingDatas.splice(index, 1);
            };
        }
        function deleteByGuideId(cargo) {
            if(!$scope.guide.id){
                return;
            }
            if($scope.guide.plConfirmed){
                layer.msg($translate.instant('autopsApp.guide.sts.plConfirmed'));
                return;
            }
            if($rootScope.HAVENOAUTH('AU_WAREHOUSING_DATA')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_WAREHOUSING_DATA');
                return;
            }
            layer.confirm($translate.instant('global.delAllAlert'), {
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    WarehousingData.deleteByGuideId({
                        guideId: $scope.guide.id
                    }, function(){
                        loadGuide($scope.guide.id);
                    }, function(result){
                    });
                    layer.close(index);
                }, function() {
            });
        }

        function getWarehouses () {
            if($rootScope.account.authorities.indexOf('WMS') === -1){
                return;
            }
            if (vm.warehouses && vm.warehouses.length > 0){
                if(!$scope.guide.whId){
                    warehouseSelected(vm.warehouses[0]);
                }
                return;
            }
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false,
                    shipmentType: $scope.guide.shipmentType
                }, function (data) {
                vm.warehouses = data;
                if(vm.warehouses.length > 0 && !$scope.guide.whId){
                    warehouseSelected(vm.warehouses[0]);
                    getLocations();
                }
            });
        }
        getWarehouses();

        function warehouseSelected (warehouse) {
            $scope.guide.whName = warehouse.name;
            $scope.guide.companyId = warehouse.companyId;

            if($scope.guide.whId != warehouse.id){
                $scope.guide.whId = warehouse.id;
                vm.locations = [];
                getLocations();
            }
        }

        function submitIn() {
            if($scope.guide.plConfirmed){
                layer.msg($translate.instant('autopsApp.guide.sts.plConfirmed'));
                return;
            }
            // if(!$scope.guide.shipperId){
            //     layer.msg($translate.instant('autopsApp.wmsIn.clientNullAlert'));
            //     return;
            // }
            for(var i = $scope.guide.warehousingDatas.length  -1; i >= 0; i--){
                if(!$scope.guide.warehousingDatas[i].id){
                    if(!$scope.guide.warehousingDatas[i].pkgNum){
                        $scope.guide.warehousingDatas.splice(i, 1);
                    }
                    else if (!$scope.guide.warehousingDatas[i].cargoName
                        && !$scope.guide.warehousingDatas[i].gw
                        && !$scope.guide.warehousingDatas[i].length
                        && !$scope.guide.warehousingDatas[i].width
                        && !$scope.guide.warehousingDatas[i].height
                        && !$scope.guide.warehousingDatas[i].vol){
                        $scope.guide.warehousingDatas.splice(i, 1);
                    }
                }
            }
            if($rootScope.HAVEAUTH('WMS')){
                if(!$scope.guide.whId){
                    layer.msg($translate.instant('autopsApp.wmsIn.chooseWarehouseAlert'));
                    return;
                }
                // for(var i = 0; i < $scope.guide.warehousingDatas.length; i++){
                //     if ($scope.guide.warehousingDatas[i].pkgNum
                //         && !$scope.guide.warehousingDatas[i].location){
                //         var msg = $scope.guide.warehousingDatas[i].cargoName + ": " + $translate.instant('global.choose_location_alert');
                //         $rootScope.OPS_ALERT(msg);
                //         return;
                //     }
                // }
            }
            vm.isSaving = true;
            Guide.fmsRegistIn($scope.guide, onSaveSuccess, onSaveError);
        }
        function onSaveSuccess (result) {
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submitted'));
            $scope.guide = result;
            initGuidePl();
        }
        var unsubscribeUpdateByChannel = $rootScope.$on('autopsApp:guideUpdateByChannel', function(event, result) {
            if(result.id == $scope.guide.id){
                loadGuide($scope.guide.id);
            }
        });
        function onSaveError (result) {
            vm.isSaving = false;
            if (result.status == 409) {
                layer.msg($translate.instant('autopsApp.wmsIn.jobNumConflictAlert'));
            }
            else if (result.status == 406) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.overdue'));
            }
            else if (result.status == 403) {
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    icon: 3,
                    title: $translate.instant('global.messageInfo'),
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide($scope.guide.id);
                    layer.close(index);
                }, function() {});
            }
            else {
                layer.msg($translate.instant('global.save_fail'));
            }
        }


        function updateTtl (isPkg) {
            $scope.guide.palletNum = 0;
            $scope.guide.wmsActAmt = 0;
            $scope.guide.wmsVol = 0;
            $scope.guide.wmsGw = 0;

            angular.forEach($scope.guide.warehousingDatas, function(data){
                var ttlCount;
                if(data.pkgNum){
                    $scope.guide.wmsActAmt += data.pkgNum;
                    ttlCount = data.pkgNum;
                }
                if(data.pallets){
                    $scope.guide.palletNum += data.pallets;
                    ttlCount = data.pallets;
                }
                if(ttlCount){
                    if(data.vol){
                        data.volTtl = DataUtils.round(data.vol * ttlCount, 6);
                        $scope.guide.wmsVol += data.volTtl;
                    }
                    if(data.gw){
                        data.gwTtl = DataUtils.round(data.gw * ttlCount, 4);
                        $scope.guide.wmsGw += data.gwTtl;
                    }
                }
                updateVwCw(data);
            })
            $scope.guide.wmsVol = DataUtils.round($scope.guide.wmsVol, DataUtils.getVolPrecision($scope.guide));
            $scope.guide.wmsGw = DataUtils.round($scope.guide.wmsGw, DataUtils.getGwPrecision($scope.guide));
            if(isPkg && vm.pkgsTtl && $scope.guide.wmsActAmt > vm.pkgsTtl){
                $rootScope.ALERT_ALARM();
                $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.amtAlert', {actAmt: $scope.guide.wmsActAmt, limit: vm.pkgsTtl}), {icon: 0, shade: 0.3})
            }
            // else {
            //     $rootScope.ALERT_LANG($scope.guide.pkgNum);
            // }
        }

        function updateVol (cargo) {
            if (cargo.length && cargo.width && cargo.height){
                cargo.vol = cargo.length * cargo.width * cargo.height/1000000;
                cargo.vol = DataUtils.round(cargo.vol, 6);
                updateTtl();
            }
        }

        $(document).keydown(function(event) {
            if (event.keyCode == 13) {
                if ("gw_field" == document.activeElement.id) {
                    $("#field_length").focus();
                }
                else if ("field_length" == document.activeElement.id) {
                    $("#field_width").focus();
                }
                else if ("field_width" == document.activeElement.id) {
                    $("#field_height").focus();
                }
                else if ("field_height" == document.activeElement.id) {
                    $timeout(function (){
                        $("#button_addCargo").focus();
                    });
                }
            }
        });
        $(document).keydown(function(event) {
            if (event.keyCode == 83 && event.ctrlKey) {
                event.preventDefault();
                submitIn();
            }
            else if (event.ctrlKey && (event.keyCode == 13 || event.keyCode == 107)) {
                event.preventDefault();
                addCargo();
            }
        });

        vm.pkgsTtl = null;
        $scope.wmsLabels = function(reportType) {
            if(!$scope.guide.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            var guide = angular.copy($scope.guide);
            guide.pkgNum = vm.pkgsTtl;
            WmsIn.wmsRapidLabels({
                reportType: reportType,
                fileType: 1
            }, guide, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        $scope._selected = function($item) {
            $scope.guide.booking.deliverytoId = $item.code;
            $scope.guide.deliverytoId = $scope.guide.booking.deliverytoId;

            $scope.guide.deliveryTo = $scope.guide.booking.deliveryTo;

            $scope.guide.shiptoPostcode = $item.postCode;
            $scope.guide.destCountryCode = $item.countryCode;
            $scope.guide.shiptoPostcode = $item.postCode;
            $scope.guide.destCountryName = $rootScope.getCountryName($scope.guide.destCountryCode);
            AddressBook.getFbaAddress({code: $item.code, countryCode: $item.countryCode}, function(result){
                if(result){
                    DataUtils.onGuideAddressChoosed($scope.guide, result);
                    $scope.guide.destCountryName = $rootScope.getCountryName($scope.guide.destCountryCode);

                }
            });
            autoRemoteCheck();
        }

        $scope.onChannelSelected = function(carrier) {
            $scope.guide.booking.carrierCode = carrier.code;
            $scope.guide.booking.carrierFullName = carrier.name;
            if(carrier.volCoe || carrier.volumeShare){
                if(carrier.volCoe){
                    $scope.guide.booking.divVolBy = carrier.volCoe;
                }
                if(carrier.volumeShare){
                    $scope.guide.booking.volumeShare = carrier.volumeShare;
                }
            }
            autoRemoteCheck();
        }

        $scope.deliveryWayChange = function() {
            // if($scope.guide.deliveryWay == "express" || $scope.guide.shipmentType == "FBAAIR" || $scope.guide.shipmentType == "EXPRESS"){
            //     $scope.guide.chargeByCbm = false;
            //     $scope.guide.booking.divVolBy = 6000;
            // }
            // else{
            //     $scope.guide.chargeByCbm = true;
            //     $scope.guide.booking.divVolBy = 1000;
            // }
        };

        function autoRemoteCheck(){
            if(!$scope.guide.destCountryCode
                || !$scope.guide.shiptoPostcode || $scope.guide.shiptoPostcode.length < 5
                || !$scope.guide.booking.carrierFullName){
                return;
            }
            ExpressParcel.remoteCheck({
                country: $scope.guide.destCountryCode,
                postcode: $scope.guide.shiptoPostcode,
                carrier: $scope.guide.booking.carrierFullName
            }, function(results){
                if(results && results.length > 0){
                    $scope.guide.isRemote = true;
                    $rootScope.OPS_ALERT($scope.guide.booking.carrierFullName + ": is Remote!");
                }
                else {
                    $scope.guide.isRemote = false;
                    layer.msg($scope.guide.booking.carrierFullName + ": Not Remote!");
                }
            }, function () {
            });
        }


        $scope.gwTtlChange = function(data) {
            var ttlCount = data.pkgNum;
            if(data.pallets){
                ttlCount = data.pallets;
            }
            if(ttlCount){
                data.gw = DataUtils.round(data.gwTtl / ttlCount, 3);
            }
            updateByTtl();
        };

        $scope.volTtlChange = function(data) {
            var ttlCount = data.pkgNum;
            if(data.pallets){
                ttlCount = data.pallets;
            }
            if(ttlCount){
                data.vol = DataUtils.round(data.volTtl / ttlCount, 6);
            }
            updateByTtl();
        };
        function updateByTtl () {
            $scope.guide.wmsVol = 0;
            $scope.guide.wmsGw = 0;
            angular.forEach($scope.guide.warehousingDatas, function(data){
                if(data.volTtl){
                    $scope.guide.wmsVol += data.volTtl;
                }
                if(data.gwTtl){
                    $scope.guide.wmsGw += data.gwTtl;
                }
                updateVwCw(data);
            })
            $scope.guide.wmsVol = DataUtils.round($scope.guide.wmsVol, DataUtils.getVolPrecision($scope.guide));
            $scope.guide.wmsGw = DataUtils.round($scope.guide.wmsGw, DataUtils.getGwPrecision($scope.guide));
        }
        function updateVwCw(cargo) {
            cargo.vw = 0;
            cargo.cw = 0;
            if($scope.guide.chargeByCbm){
                if($scope.guide.booking.divVolBy && cargo.gwTtl){
                    cargo.vw = cargo.gwTtl/$scope.guide.booking.divVolBy;
                    cargo.vw = DataUtils.round(cargo.vw, 6);
                    cargo.cw = cargo.vw;
                }
                if(cargo.volTtl && (!cargo.vw || cargo.volTtl > cargo.vw)){
                    cargo.cw = cargo.volTtl;
                }
            }
            else {
                if($scope.guide.booking.divVolBy && cargo.volTtl){
                    cargo.vw = cargo.volTtl * 1000000/$scope.guide.booking.divVolBy;
                    cargo.vw = DataUtils.round(cargo.vw, 3);
                    cargo.cw = cargo.vw;
                }
                if(cargo.gwTtl && (!cargo.vw || cargo.gwTtl > cargo.vw)){
                    cargo.cw = cargo.gwTtl;
                }
            }
            if(deliveryChannel && deliveryChannel.minWeight && cargo.cw < deliveryChannel.minWeight){
                cargo.cw = deliveryChannel.minWeight;
            }
            if(cargo.length != null && cargo.height != null && cargo.width != null){
                var sizes = [cargo.length, cargo.width, cargo.height];
                sizes.sort(function(a,b){return a < b?1:-1;});
                cargo.girth = (sizes[1] + sizes[2]) * 2 + sizes[0];
                cargo.girth = DataUtils.round(cargo.girth);
            }
        }


        $scope.customRequiredChange = function() {
            $scope.guide.customRequired = !$scope.guide.customRequired;
        }
        $scope.isRemoteChange = function() {
            $scope.guide.isRemote = !$scope.guide.isRemote;
        }

        var unsubscribe = $rootScope.$on('autopsApp:warehousingDataReceived', function(event, result) {
            if(!$scope.guide.id || $scope.guide.id != result.guideId){
                return;
            }
            var isNew = true;
            angular.forEach($scope.guide.warehousingDatas, function(data){
                if(data.id == result.id){
                    angular.copy(result, data);
                    isNew = false;
                }
            })
            if(isNew){
                $scope.guide.warehousingDatas.splice(0, 0, result);
            }
            updateTtl(true);
        });
        $scope.$on('$destroy', unsubscribe);

        $scope.refreshWarehousingDataByWmsIn = function() {
            if (!$scope.guide.id) {
                return;
            }
            vm.isSaving = true;
            Guide.refreshWarehousingDataByWmsIn({
                guideId: $scope.guide.id
            }, function(result) {
                getShipment();
                vm.isSaving = false;
                layer.msg($translate.instant('entity.action.loadSuccess'));
            }, $rootScope.ALERT_ERROR);
        }

        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: false,
            enableCellEditOnFocus: true,

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤
            useExternalFiltering: false,

            enableHorizontalScrollbar : 1,//表格的水平滚动条
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv : false,
            exporterMenuPdf : false,
            exporterMenuLabel : "Export",
            exporterExcelFilename: 'warehousing_data.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],

            minimumColumnSize: 30,
            columnDefs: [
                { field: 'id',
                    width: 65,
                    enableFiltering: false,
                    enableCellEdit: false,
                    enableColumnMenus: false,
                    displayName: "",
                    cellTemplate: '<div class="grid-text"><button type="button" ng-click="grid.appScope.warehousingDataSplit(row.entity)" class="btn btn-xs btn-primary" ng-disabled="!row.entity.pkgNum || row.entity.pkgNum == 1"><span class="glyphicon glyphicon-duplicate"></span></button><button type="button" ng-click="grid.appScope.vm.delCargo(row.entity)" class="btn btn-xs btn-danger" ng-disabled="row.entity.id && grid.appScope.HAVENOAUTH(\'AU_WAREHOUSING_DATA\')"><span class="glyphicon glyphicon-remove"></span></button></div>'},
                { field: 'pkgNum',
                    type: 'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsIn.pkgs'),
                    cellTemplate: '<div class="grid-text"><strong>{{row.entity.pkgNum}}</strong></div>',
                    minWidth:80},
                { field: 'gw',
                    type: 'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.wmsIn.gw'),
                    minWidth:80},
                { field: 'length',
                    type: 'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: "L(cm)",
                    minWidth:80},
                { field: 'width',
                    type: 'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: "W(cm)",
                    minWidth:80},
                { field: 'height',
                    type: 'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: "H(cm)",
                    minWidth:80},
                { field: 'vol',
                    type: 'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.wmsIn.vol'),
                    minWidth:80},
                { field: 'girth',
                    type: 'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.wmsIn.girth'),
                    minWidth:80},
                { field: 'gwTtl',
                    type: 'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsIn.gwTtl'),
                    minWidth:80},
                { field: 'volTtl',
                    type: 'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsIn.volTtl'),
                    minWidth:80},
                { field: 'vw',
                    type: 'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    enableCellEdit: false,
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsIn.vw') + "(" + $scope.guide.booking.divVolBy + ")",
                    cellTemplate: '<div class="grid-text"><strong>{{row.entity.vw}}</strong></div>',
                    minWidth:100},
                { field: 'cw',
                    type: 'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    enableCellEdit: false,
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsIn.cw'),
                    cellTemplate: '<div class="grid-text"><strong>{{row.entity.cw}}</strong></div>',
                    minWidth:100},
                { field: 'pallets',
                    type: 'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsIn.pltAmt'),
                    minWidth:80},
                { field: 'location',
                    minWidth:150,
                    visible: $rootScope.HAVEAUTH('WMS'),
                    displayName: $translate.instant('autopsApp.wmsIn.location'),
                    editableCellTemplate: '<input type="text" ng-model="row.entity.location" ng-dblclick="grid.appScope.fillLocationForce(row.entity)" ng-focus="grid.appScope.vm.getLocations()" ng-class="!row.entity.location?\'bg-alert\':\'\'" uib-typeahead="location.code as location for location in grid.appScope.vm.locations | filter: $viewValue | limitTo:100"  typeahead-template-url="locationTemplate.html" typeahead-on-select="grid.appScope.vm._onLocationSelected($item, row.entity)" typeahead-min-length="0" typeahead-editable = "true" ng-disabled="row.entity.id && grid.appScope.HAVENOAUTH(\'AU_WAREHOUSING_DATA\')"/>'},
                { field: 'cargoName',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.wmsIn.goodsName')},
                { field: 'pkgType',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.wmsIn.unit')},
                { field: 'batchNumber',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.wmsIn.batch')},
                { field: 'barcode',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.wmsIn.barcode')},
                { field: 'fbaCtnNo',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.wmsIn.fbaLabel')},                    
                { field: 'tracking',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.wmsIn.returnTracking')},
                { field: 'remarks',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.wmsIn.remarks')},
                { field: 'cloudFiles',
                    displayName: $translate.instant('global.cloudFiles'),
                    width:100,
                    enableSorting: false,
                    enableFiltering: false,
                    enableCellEdit: false,
                    enableColumnMenus: false,
                    cellClass:'append-to-body',
                    cellTemplate:'<div id="btnsId" class="btn-group flex-btn-group-container mr-5" ng-if="row.entity.id"><div class="btn-group" uib-dropdown><button type="button" class="btn btn-link btn-grid" uib-dropdown-toggle ng-click="row.entity.files=[];grid.appScope.LOADFILES(row.entity.files, \'/warehousingData/\' + row.entity.id + \'/\', grid.appScope.guide.companyId)"><span class="glyphicon glyphicon-cloud"></span><span translate="global.cloudFiles">cloudFiles</span><span class="caret"></span></button><ul class="dropdown-menu" uib-dropdown-menu role="menu"><li><a href="" ngf-select ngf-change="grid.appScope.UPLOADFILE($file, \'/warehousingData/\' + row.entity.id + \'/\', row.entity.files, grid.appScope.guide.companyId)"><span class="glyphicon glyphicon-cloud-upload"></span>&nbsp;<span translate="global.uploadFiles">uploadFiles</span></a></li><li role="separator" class="divider" ng-if="row.entity.files"></li><li ng-repeat="file in row.entity.files"><a href="" ng-click="grid.appScope.PDFPOP(file.fileurl,file.filename)"><span class="glyphicon glyphicon-cloud-download"></span>&nbsp;{{file.filename}}</a></li></ul></div></div>'},
                { field: 'opRequirment',
                    width: 360,
                    enableFiltering: false,
                    enableCellEdit: false,
                    enableColumnMenus: false,
                    displayName: '',
                    cellTemplate: '<div class="grid-text"><span class="label label-primary" ng-if="row.entity.pallets"><span translate="autopsApp.wmsIn.palletSize">palletSize</span></span><span class="label label-info" ng-if="!row.entity.pallets"><span translate="autopsApp.wmsIn.single">single</span><span translate="autopsApp.wmsIn.dimension">dimension</span></span><span class="text-danger ml-10" ng-show="row.entity.id"><span class="glyphicon glyphicon-flag" data-toggle="tooltip" data-placement="top" title="{{ \'autopsApp.wmsIn.cargoInAlert\' | translate}}"></span><span class="text-muted ml-10">{{row.entity.registrationTime | date:\'medium\'}}</span><span class="text-muted ml-10">{{row.entity.register}}</span></span></div>'}

            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 149;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "warehousingData";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "warehousingData";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                $scope.gridApi.edit.on.afterCellEdit($scope, function(rowEntity, colDef, newValue, oldValue) {
                    if(rowEntity.id && $rootScope.HAVENOAUTH('AU_WAREHOUSING_DATA')){
                        rowEntity[colDef.field] = oldValue;
                        return;
                    }
                    if(colDef.field == "length" || colDef.field == "width" || colDef.field == "height"){
                        updateVol(rowEntity);
                    }
                    else if(colDef.field == "volTtl"){
                        $scope.volTtlChange(rowEntity);
                    }
                    else if(colDef.field == "gwTtl"){
                        $scope.gwTtlChange(rowEntity);
                    }
                    else if(colDef.field == "gw" || colDef.field == "vol"){
                        updateTtl();
                    }
                    else if(colDef.field == "pkgNum"){
                        updateTtl(true);
                    }
                    else if(colDef.field == "pallets"){
                        updateTtl(false);
                    }
                })
                $scope.gridApi.edit.on.beginCellEdit($scope, function(rowEntity, colDef, triggerEvent) {
                    if(rowEntity.id && $rootScope.HAVENOAUTH('AU_WAREHOUSING_DATA')){
                        $rootScope.FORBIDDEN_BY_ALERT('AU_WAREHOUSING_DATA');
                    }
                    else {
                        focusCargo(rowEntity)
                    }
                })
                $scope.gridApi.selection.on.rowSelectionChanged($scope,function(row){
                    selectionChange();
                });
                $scope.gridApi.selection.on.rowSelectionChangedBatch($scope,function(row){
                    selectionChange();
                });
            }
        }
        function warehousingDataSplit(rowEntity) {
            var idx = $scope.guide.warehousingDatas.indexOf(rowEntity);
            var barcode = DataUtils.getNextCode(rowEntity.barcode);
            for(var i = 0; i < rowEntity.pkgNum - 1; i++){
                var newCargo = {};
                newCargo.pkgNum = 1;
                newCargo.gw = rowEntity.gw;
                newCargo.vol = rowEntity.vol;
                newCargo.length = rowEntity.length;
                newCargo.width = rowEntity.width;
                newCargo.height = rowEntity.height;
                newCargo.cargoName = rowEntity.cargoName;
                newCargo.location = rowEntity.location;
                newCargo.remarks = rowEntity.remarks;
                newCargo.pkgType = rowEntity.pkgType;
                newCargo.batchNumber = rowEntity.batchNumber;
                newCargo.barcode = barcode;
                barcode = DataUtils.getNextCode(newCargo.barcode);
                newCargo.pallets = null;
                $scope.guide.warehousingDatas.splice(idx+1, 0, newCargo);
            }
            rowEntity.pkgNum = 1;
            updateTtl();
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.ALL );
            $translate.refresh();
        }

        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            if(entity && entity.guideId){
                loadGuide(entity.guideId);
            }

            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 149
            }, function(result){
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
            });
            window.setTimeout(resize,1000);
        });
        function resize() {
            $scope.gridOptions.data = $scope.guide.warehousingDatas;
            var tabHeight = $scope.gridOptions.data.length * 30 + 200;
            var winHeight = $(window).height() - $("#gridDialogId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 500){
                tabHeight = 500;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }
        vm.rowsSelected = false;
        function selectionChange() {
            vm.rowsSelected = $scope.gridApi.selection.getSelectedRows().length > 0;
        }

        function delSelected() {
            if($scope.guide.plConfirmed){
                layer.msg($translate.instant('autopsApp.guide.sts.plConfirmed'));
                return;
            }
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if (selectedRows.length == 0) {
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            if(!$scope.guide.id){
                return;
            }
            if($rootScope.HAVENOAUTH('AU_WAREHOUSING_DATA')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_WAREHOUSING_DATA');
                return;
            }
            layer.confirm($translate.instant('global.delSelectedAlert', {ttlAmt: selectedRows.length}), {
                icon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    for(var i = 0; i < selectedRows.length; i++){
                        if(selectedRows[i].id){
                            continue;
                        }
                        var idx = $scope.guide.warehousingDatas.indexOf(selectedRows[i]);
                        if (idx > -1) {
                            $scope.guide.warehousingDatas.splice(idx, 1);
                        };
                    }
                    selectedRows = $scope.gridApi.selection.getSelectedRows();
                    if (selectedRows.length > 0) {
                        // for(var i = 0; i < selectedRows.length; i++){
                        //     if(selectedRows[i].id){
                        //         WarehousingData.delete({
                        //             id: selectedRows[i].id
                        //         }, function(){
                        //             if(i == selectedRows.length){
                        //                 loadGuide($scope.guide.id);
                        //             }
                        //         }, function(result){
                        //         });
                        //     }
                        // }
                        var idsToRemove = [];
                        for(var i = 0; i < selectedRows.length; i++){
                            if(selectedRows[i].id){
                                idsToRemove.push(selectedRows[i].id);
                            }
                        }
                        if (idsToRemove.length > 0) {
                            WarehousingData.removeByIds(idsToRemove, function() {
                                loadGuide($scope.guide.id);
                            }, function() {
                            });
                        }
                    }
                    $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.ALL );
                    layer.close(index);
                }, function() {
            });
        }
        vm.isBatchAmend = false;
        function batchAmend() {
            vm.isBatchAmend = !vm.isBatchAmend;
        }
        vm.batchEntity = {};
        function batchAmendChange(fd) {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            angular.forEach(selectedRows, function(data){
                data[fd] = vm.batchEntity[fd];
                if(fd != "gw"){
                    updateVol(data);
                }
            })
            if(fd == "gw"){
                updateTtl();
            }
        }

        $scope.warehousingDataSplit = function(rowEntity) {
            if(rowEntity.pkgNum > 1){
                layer.confirm($translate.instant('autopsApp.guide.warehousingDataSplitAlert', {param: rowEntity.pkgNum}), {
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                    }, function(index) {
                        layer.close(index);
                        warehousingDataSplit(rowEntity);
                    }, function() {
                });
            }
        }

        vm.autoPrint = false;
        vm.continuousPrinting = false;
        vm.printExpressLabel = false;
        function autoPrintLabel () {
            if(!vm.autoPrint){
                return;
            }
            layer.load(1, { shade: 0.3 });
            if(vm.printExpressLabel){
                Guide.autoPrintLabel({
                    id: $scope.guide.id,
                    page: null,
                    labelUrl: $scope.guide.labelUrl
                }, onAutoPrintLabelSuccess, function(error) {
                    layer.closeAll();
                    if(vm.continuousPrinting){
                        vm.clearGuide();
                        $timeout(function () {
                            $("#job_num_field").focus();
                        });
                    }
                    layer.msg($translate.instant('global.loadFileFailed'));
                });
            }
            else {
                var guides = [];
                guides.push($scope.guide);
                Guide.printLabel({
                    reportType: 750
                }, guides, onAutoPrintLabelSuccess, function(error) {
                    layer.closeAll();
                    if(vm.continuousPrinting){
                        vm.clearGuide();
                        $timeout(function () {
                            $("#job_num_field").focus();
                        });
                    }
                    layer.msg($translate.instant('global.loadFileFailed'));
                });
            }
        }

        function onAutoPrintLabelSuccess (result) {
            layer.closeAll();
            // $rootScope.PRINTBYTES(result.data);
            $rootScope.PRINT_BYTES_DIR(result.data);
            if(vm.continuousPrinting){
                vm.clearGuide();
                $timeout(function () {
                    $("#job_num_field").focus();
                });
            }
        }
        $scope.fillLocationForce = function(row){
            if(!row.location){
                return;
            }
            layer.confirm($translate.instant('autopsApp.guide.forceLocationChange', {param: row.location}), {
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                angular.forEach($scope.gridOptions.data, function(data){
                    data.location = row.location;
                })
                $translate.refresh();
            }, function() {
                return;
            });

        }
        $scope.zipWarehousingDataFiles = function() {
            if(!$scope.guide.warehousingDatas || !$scope.guide.warehousingDatas.length){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            OpsFiles.zipWarehousingDataFiles({
                guideId: $scope.guide.id
            }, function(result) {
                $rootScope.DOWNLOADEXCEL(result, $scope.guide.jobNum + "_cargo_pic.zip");
            })
        };



    }
})();
