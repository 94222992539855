(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BookingCtnsDialogController', BookingCtnsDialogController);

    BookingCtnsDialogController.$inject = ['$scope', '$http', '$rootScope', '$uibModalInstance', '$timeout', '$translate', '$uibModal',
        'entity', 'DataUtils', 'OpsFiles', 'BookingCtns', 'BookingCtnsSearch', 'Guide', 'OpsReport', 'WmsIn', 'MixedPl'
    ];

    function BookingCtnsDialogController($scope, $http, $rootScope, $uibModalInstance, $timeout, $translate, $uibModal,
        entity, DataUtils, OpsFiles, BookingCtns, BookingCtnsSearch, Guide, OpsReport, WmsIn, MixedPl) {

        $scope.guide = entity;
        var guideCopy;
        $scope.initGuidePl = function() {
            angular.forEach($scope.guide.booking.bookingCtns, function(data){
                if (data.pkgNum) {
                    if (data.gw && !data.gwTtl) {
                        data.gwTtl = DataUtils.round(data.pkgNum * data.gw, 2);
                    }
                    if (data.nw) {
                        data.nwTtl = DataUtils.round(data.pkgNum * data.nw, 2);
                    }
                    if (data.vol && !data.volTtl) {
                        data.volTtl = DataUtils.round(data.pkgNum * data.vol, 5);
                    }
                }
                if($scope.guide.destCountryCode){
                    if(!data.importCountryCode){
                        data.importCountryCode = $scope.guide.destCountryCode;
                    }
                    if(data.mixedPls && data.mixedPls.length > 0){
                        angular.forEach(data.mixedPls, function(pl){
                            if(!pl.importCountryCode){
                                pl.importCountryCode = $scope.guide.destCountryCode;
                            }
                        })
                    }
                }
            })
        };
        $timeout(function() {
            if(!$scope.guide.booking){
                loadGuide();
                return;
            }
            if(!$scope.guide.booking.bookingCtns){
                $scope.guide.booking.bookingCtns = [];
            }
            $scope.initGuidePl();
            getCargpPic();
            var bookingChooseId = $scope.guide.bookingChooseId
            if (bookingChooseId){
                var element = document.getElementById('bookingCtn-'+ bookingChooseId);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        },100);

        var loadGuide = function() {
            Guide.get({
                id: $scope.guide.id
            }, function(result) {
                angular.copy(result, $scope.guide);
                $scope.initGuidePl();
                getCargpPic();
            });
        }
        $scope.addCtn = function() {
            $scope.guide.booking.bookingCtns.push({importCountryCode:$scope.guide.destCountryCode});
            $translate.refresh();
        };

        $scope.addMixPl = function(bookingCtn) {
            if(!bookingCtn.mixedPls || bookingCtn.mixedPls.length == 0){
                bookingCtn.mixedPls = [];
                var mixPl = angular.copy(bookingCtn);
                mixPl.id = null;
                mixPl.plId = null;
                mixPl.pl = null;
                bookingCtn.mixedPls.push(mixPl);
            }
            else {
                bookingCtn.mixedPls.push({importCountryCode:$scope.guide.destCountryCode});
            }
            $translate.refresh();
        };

        $scope.deleteCtn = function(bookingCtn) {
            if($scope.guide.isClosed){return;}
            if (!angular.equals(guideCopy, $scope.guide) && $scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if (bookingCtn.id && $scope.guide.id) {
                var loadIndex = layer.load(1, {shade: 0.3});
                BookingCtns.delete({
                    id: bookingCtn.id
                }, function(){
                    layer.close(loadIndex);
                    loadGuide();
                }, function(result){
                    layer.close(loadIndex);
                    if(result.status == 409){
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.cargoIsOutAlert'), {icon:0});
                    }
                    else {
                        layer.msg($translate.instant('global.delete_fail'));
                    }
                });
            }
            else {
                var index = $scope.guide.booking.bookingCtns.indexOf(bookingCtn);
                if (index > -1) {
                    $scope.guide.booking.bookingCtns.splice(index, 1);
                };
            }
        };
        $scope.deleteMixPl = function(mixPl, bookingCtn) {
            if($scope.guide.isClosed){return;}
            if (mixPl.id && $scope.guide.id) {
                if (!angular.equals(guideCopy, $scope.guide) && $scope.guide.id) {
                    layer.msg($translate.instant('global.saveAlert'));
                    return;
                };
                var loadIndex = layer.load(1, {shade: 0.3});
                MixedPl.delete({
                    id: mixPl.id
                }, function(){
                    layer.close(loadIndex);
                    loadGuide();
                }, function(result){
                    layer.close(loadIndex);
                    if(result.status == 409){
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.cargoIsOutAlert'), {icon:0});
                    }
                    else {
                        layer.msg($translate.instant('global.delete_fail'));
                    }
                })
            }
            else {
                var index = bookingCtn.mixedPls.indexOf(mixPl);
                if (index > -1) {
                    bookingCtn.mixedPls.splice(index, 1);
                }
            }
        }

        $scope.clear = function() {
            $uibModalInstance.dismiss('dismiss');
        };
        $scope.gwChange = function(bookingCtn) {
            if(bookingCtn.pkgNum && bookingCtn.gw){
                bookingCtn.gwTtl = DataUtils.round(bookingCtn.gw * bookingCtn.pkgNum, 4);
                $scope.updateVolGw();
            }
        };
        $scope.volChange = function(bookingCtn) {
            if(bookingCtn.pkgNum && bookingCtn.vol){
                bookingCtn.volTtl = DataUtils.round(bookingCtn.vol * bookingCtn.pkgNum, 5);
                $scope.updateVolGw();
            }
        };
        $scope.pkgChange = function(bookingCtn) {
            if(bookingCtn.pkgNum){
                if(bookingCtn.vol){
                    bookingCtn.volTtl = DataUtils.round(bookingCtn.vol * bookingCtn.pkgNum, 5);
                }
                if(bookingCtn.gw){
                    bookingCtn.gwTtl = DataUtils.round(bookingCtn.gw * bookingCtn.pkgNum, 4);
                }
                $scope.updateVolGw();
            }
        };

        $scope.gwTtlChange = function(bookingCtn) {
            if(bookingCtn.pkgNum && bookingCtn.gwTtl){
                bookingCtn.gw = DataUtils.round(bookingCtn.gwTtl / bookingCtn.pkgNum, 2);
            }
            updateByTtl();
        };
        $scope.volTtlChange = function(bookingCtn) {
            if(bookingCtn.pkgNum && bookingCtn.volTtl){
                bookingCtn.vol = DataUtils.round(bookingCtn.volTtl / bookingCtn.pkgNum, 5);
            }
            updateByTtl();
        };

        function updateByTtl () {
            $scope.guide.vol = 0;
            $scope.guide.gw = 0;
            angular.forEach($scope.guide.booking.bookingCtns, function(data){
                if(data.volTtl){
                    $scope.guide.vol += data.volTtl;
                }
                if(data.gwTtl){
                    $scope.guide.gw += data.gwTtl;
                }
            })
            $scope.guide.vol = DataUtils.round($scope.guide.vol, DataUtils.getVolPrecision($scope.guide));
            $scope.guide.gw = DataUtils.round($scope.guide.gw, DataUtils.getGwPrecision($scope.guide));
            DataUtils.updateGuideCw($scope.guide);
        }

        $scope.dutyAuditedChange = function(bookingCtn) {
            if (bookingCtn.dutyAudited) {
                bookingCtn.auditor = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            } else {
                bookingCtn.auditor = null;
            }
        };
        $scope.updatePrice = function(bookingCtn) {
            if (bookingCtn.pieces && bookingCtn.unitPrice) {
                bookingCtn.totalPrice = DataUtils.round(bookingCtn.pieces * bookingCtn.unitPrice, 2);
                $scope.updateDuty(bookingCtn);
                updateValuePieces();
            }

        };

        var updateValuePieces = function(){
            var pieces = 0;
            var cargoValue = 0;
            angular.forEach($scope.guide.booking.bookingCtns, function(bcn){
                if(bcn.pieces){
                    pieces += bcn.pieces;
                }
                if(bcn.totalPrice){
                    cargoValue += bcn.totalPrice;
                }

            })
            $scope.guide.pieces = pieces;
            $scope.guide.cargoValue = cargoValue;
        }

        $scope.updateVol = function(bookingCtn) {
            if (bookingCtn.length && bookingCtn.width && bookingCtn.height) {
                bookingCtn.width = bookingCtn.width.toFixed(4) - 0;
                bookingCtn.length = bookingCtn.length.toFixed(4) - 0;
                bookingCtn.height = bookingCtn.height.toFixed(4) - 0;
                bookingCtn.vol = bookingCtn.length * bookingCtn.width * bookingCtn.height / 1000000;
                bookingCtn.vol = DataUtils.round(bookingCtn.vol, 5);
                $scope.volChange(bookingCtn);
            }
        };
        $scope.updateDuty = function(bookingCtn) {
            bookingCtn.dutyAmt = DataUtils.getDuty(bookingCtn).dutyAmt;
            $scope.mixPlChange(bookingCtn);
        };

        $scope.mixPlChange = function(bookingCtn) {
            if(!bookingCtn.mixedPls || bookingCtn.mixedPls.length == 0){
                return;
            }
            var gwTtl = 0, volTtl = 0, dutyAmt = 0, pieces = 0, totalPrice = 0;
            angular.forEach(bookingCtn.mixedPls, function(data){
                if(data.gwTtl){
                    gwTtl += data.gwTtl;
                }
                if(data.volTtl){
                    volTtl += data.volTtl;
                }
                if(data.dutyAmt){
                    dutyAmt += data.dutyAmt;
                }
                if(data.pieces){
                    pieces += data.pieces;
                }
                if(data.totalPrice){
                    totalPrice += data.totalPrice;
                }
            })
            bookingCtn.gwTtl = DataUtils.round(gwTtl, 2);
            if(bookingCtn.pkgNum){
                bookingCtn.gw = DataUtils.round(gwTtl/bookingCtn.pkgNum, 3);
            }
            bookingCtn.volTtl = volTtl;
            if(bookingCtn.pkgNum){
                bookingCtn.vol = DataUtils.round(volTtl/bookingCtn.pkgNum, 5);
            }
            bookingCtn.dutyAmt = dutyAmt;
            bookingCtn.pieces = pieces;
            bookingCtn.totalPrice = DataUtils.round(totalPrice, 2);
            updateValuePieces();
        };


        function getCargpPic() {
            angular.forEach($scope.guide.booking.bookingCtns, function(bcn){
                var filePath = "/" + "bookingCtns/" + bcn.id + "/cargoPic/";
                OpsFiles.getFilesList({
                    filepath: filePath,
                    companyId: $scope.guide.companyId
                }, function(data) {
                    bcn.cargoPics = data;
                    guideCopy = angular.copy($scope.guide);
                });
                if(bcn.mixedPls && bcn.mixedPls.length > 0){
                    angular.forEach(bcn.mixedPls, function(mixPl){
                        var filePath = "/" + "mixedPl/" + mixPl.id + "/cargoPic/";
                        OpsFiles.getFilesList({
                            filepath: filePath,
                            companyId: $scope.guide.companyId
                        }, function(data) {
                            mixPl.cargoPics = data;
                            console.log(angular.toJson(mixPl.cargoPics[0], true) )
                            guideCopy = angular.copy($scope.guide);
                        });
                    })
                }
            })
        }

        $scope._onDutySelected = function(duty, bookingCtn) {
            bookingCtn.hsCode = duty.hsCode;
            bookingCtn.dutyRate = duty.dutyRate;
            if(!bookingCtn.cargoName){
                bookingCtn.cargoName = duty.cargoName;
            }
            if(!bookingCtn.cargoNameEn){
                bookingCtn.cargoNameEn = duty.cargoNameEn;
            }
            if(!bookingCtn.material){
                bookingCtn.material = duty.material;
            }
            if(!bookingCtn.materialEn){
                bookingCtn.materialEn = duty.materialEn;
            }
            if(!bookingCtn.useAge){
                bookingCtn.useAge = duty.useAge;
            }
            if(!bookingCtn.useAgeEn){
                bookingCtn.useAgeEn = duty.useAgeEn;
            }
        }

        $scope.dutyList = [];
        $scope.loadDutyList = function(cargoName, hsCode, countryCode) {
            if(!countryCode){
                return;
            }
            if($rootScope.HAVENOAUTH('FBA')){
                return;
            }
            if(!cargoName && !hsCode){
                return;
            }
            var regCn = /^[\u4e00-\u9fa5]/;
            if(hsCode){
                var regEn = /^[A-Za-z]/;
                if(regCn.test(hsCode) || regEn.test(hsCode)){
                    cargoName = hsCode;
                    hsCode = null;
                }
                else {
                    hsCode = "*"+hsCode+"*";
                }
            }
            if(cargoName){
                if(!regCn.test(cargoName)){
                    cargoName = "*"+cargoName+"*";
                }
            }

            return BookingCtnsSearch.query({
                countryCode: countryCode,
                cargoName: cargoName,
                hsCode: hsCode,
                page: 0,
                size: 20,
                sort: ['id,desc']
            }).$promise;
        }
        KEYBOARD = "plDialog";
        var saved = false;
        $(document).keydown(function(event) {
            if( KEYBOARD != "plDialog"){
                return;
            }
            else if (event.keyCode == 83 && event.ctrlKey) {
                event.preventDefault();
                if (saved){
                    return;
                }
                else {
                    saved = true;
                    $scope.save();
                }
            }
        });

        $scope.save = function() {
            if ($scope.isSaving) {
                return;
            }
            $scope.isSaving = true;
            if ($scope.guide.isVirtual && !$scope.guide.whId) {
                $scope.isSaving = false;
                layer.msg($translate.instant('autopsApp.guide.warehouseNullAlert'));
                return;
            }
            if ($scope.guide.isClosed) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if ($scope.guide.isVoid) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.voidedAlert'));
                return;
            }

            if (angular.equals($scope.guide, guideCopy)) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.no_amendmend'));
                return;
            };
            // if ($rootScope.isZb($rootScope.account.companyId)) {
            //     var isAllOk = true;
            //     var cargoNames = new Set();
            //     angular.forEach($scope.guide.booking.bookingCtns, function(ctn){
            //         if(ctn.cargoName && !ctn.location){
            //             if(cargoNames.has(ctn.cargoName)){
            //                 isAllOk = false;
            //             }
            //             else {
            //                 cargoNames.add(ctn.cargoName);
            //             }
            //         }
            //     })
            //     if(!isAllOk){
            //         $scope.isSaving = false;
            //         layer.msg($translate.instant('autopsApp.booking.cargoNameRepeat'));
            //         return;
            //     }
            // }
            angular.forEach($scope.guide.booking.bookingCtns, function(ctn){
                if(ctn.mixedPls && ctn.mixedPls.length > 0){
                    var dutyAudited = true;
                    angular.forEach(ctn.mixedPls, function(data){
                        if(!data.dutyAudited){
                            dutyAudited = false;
                        }
                    })
                    ctn.dutyAudited = dutyAudited;
                }
            })
            if($rootScope.account.companyId){
                Guide.update($scope.guide, onSaveSuccess, onSaveError);
            }
            else {
                Guide.updateGuideBooking($scope.guide, onSaveSuccess, onSaveError);
            }
        };

        var onSaveSuccess = function(result) {
            saved = false;
            $scope.isSaving = false;
            angular.copy(result, $scope.guide);
            getCargpPic();
            guideCopy = angular.copy($scope.guide);
            document.title = result.jobNum;
            layer.msg($translate.instant('global.messages.submitted'));
            $scope.initGuidePl();
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
            if (result.status == 409) {
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {});
            }
            else if (result.status == 403) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.lockedAlert'));
            }
            else if (result.status == 406) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.overdue'));
            }
            else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
            $translate.refresh();
        };
        $rootScope.LOAD_REPORT_TEMPLATES();
        var sendEmailPop = function(entity) {
            $uibModal.open({
                templateUrl: 'app/entities/email/email-dialog.html',
                controller: 'EmailDialogController',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                $scope.guide = result;
            }, function(result) {});
        }
        $scope.previewReport = function(code, truckId) {
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.packingList = guideReportDTO.booking.bookingCtns;
            if (truckId) {
                for (var i = 0; i < $scope.bookingTrucks.length; i++) {
                    if ($scope.bookingTrucks[i].id == truckId) {
                        guideReportDTO.bookingTruck = angular.copy($scope.bookingTrucks[i]);
                    }
                }
            }
            guideReportDTO.reportType = code;
            guideReportDTO.opType = 1;
            guideReportDTO.fileType = 1;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {

            });
        }

        $scope.exportExcelPdf = function(format, code, fileName, idCopy) {
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.packingList = guideReportDTO.booking.bookingCtns;
            if ((code >= 140 && code < 150) || (code >= 160 && code < 170)) {
                for (var i = 0; i < $scope.bookingTrucks.length; i++) {
                    if ($scope.bookingTrucks[i].id == idCopy) {
                        guideReportDTO.bookingTruck = angular.copy($scope.bookingTrucks[i]);
                    }
                }
            }
            guideReportDTO.reportType = code;
            guideReportDTO.opType = 1;
            guideReportDTO.fileType = format;
            guideReportDTO.fileName = fileName;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                $rootScope.EXPORTDOWNLOAD(result, format, fileName);
            });
        }

        function ratesPop() {
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            $rootScope.GUIDE_RATES_POP($scope.guide);
        }
        var unsubscribeGuide = $rootScope.$on('autopsApp:loadGuide', function(event, result) {
            if(result.id == $scope.guide.id){
                loadGuide();
            }
        });
        $scope.$on('$destroy', unsubscribeGuide);

        $scope.palletsChange = function() {
            $scope.guide.palletNum = 0;
            angular.forEach($scope.guide.booking.bookingCtns, function(data) {
                if (data.pallets) {
                    $scope.guide.palletNum += data.pallets;
                    $scope.guide.isPallet = true;
                }
            })
        }

        $scope.updateVolGw = function() {
            DataUtils.updateGuidePl($scope.guide);
        }
        $scope.importPlByExcel = function (file) {
            if (!file) {return;}
            if(!$scope.guide.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if($scope.guide.isClosed){
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            layer.load(1, {shade: 0.3});
            var fd = new FormData();
            fd.append('file', file);
            fd.append('guideId', $scope.guide.id);
            $http.post(
                'api/bookingCtnss/importByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    layer.closeAll();
                    loadGuide();
                    layer.msg($translate.instant('global.importSuccess'));
                }).error(function(error) {
                    layer.closeAll();
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                });
        };

        $scope.generateCharges = function () {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var allDutyAudited = true;
            angular.forEach($scope.guide.booking.bookingCtns, function(data){
                if(data.mixedPls && data.mixedPls.length > 0){
                    angular.forEach(data.mixedPls, function(pl){
                        if(!pl.dutyAudited){
                            allDutyAudited = false;
                        }
                    })
                }
                else if(!data.dutyAudited){
                    allDutyAudited = false;
                }
            })
            if(!allDutyAudited){
                layer.msg($translate.instant('autopsApp.guide.dutyNotAudited'));
                return;
            }

            layer.confirm($translate.instant('autopsApp.booking.bookingCtns.generateChargesTips'), {
                icon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    Guide.generateDutyCharges(angular.copy($scope.guide), function(result){
                        ratesPop();
                        layer.msg($translate.instant('global.save_success'));
                    }, function(result){
                        if(result.status == 304){
                            layer.msg($translate.instant('global.noRecord'));
                        }
                        else {
                            layer.msg($translate.instant('global.messages.submittedfailed'));
                        }
                    })
                    layer.close(index);
                }, function() {
            });
        }
        $scope.excelExport = function() {
            var guides = [];
            guides.push($scope.guide);
            var fileName = $translate.instant('autopsApp.guide.packingList') + ".xlsx";
            OpsFiles.exportPackingList({
                enCn: $translate.instant('global.enCn')
            }, guides, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }

        $scope.printIn = function(reportType){
            // if($rootScope.account.authorities.indexOf('WMS') === -1){
            //     layer.msg($translate.instant('global.forbiddenWMS'));
            //     return;
            // }
            if(!$scope.guide.customerId){
                layer.msg($translate.instant('autopsApp.guide.clientNotChoosedAlert'));
                return;
            }
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.guide.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var wmsIn = {isOut:false};
            wmsIn.guideId = $scope.guide.id;

            OpsFiles.generateWmsInPaper({reportType: reportType},wmsIn,function(result){
                $rootScope.PRINTBYTES(result.data);
            },function(error){
                layer.msg($translate.instant('autopsApp.wmsIn.viewWmsInPaperFailed'));
            });
        }

        $scope.wmsLabels = function(reportType) {
            if(!$scope.guide.customerId){
                layer.msg($translate.instant('autopsApp.guide.clientNotChoosedAlert'));
                return;
            }
            if($scope.guide.isVoid){
                layer.msg($translate.instant('autopsApp.guide.home.voidedAlert'));
                return;
            }
            var wmsIn = {};
            wmsIn.guideId = $scope.guide.id;
            wmsIn.companyId = $scope.guide.companyId;
            $scope.format = 1;
            WmsIn.wmsLabels({
                reportType: reportType,
                fileType: $scope.format
            }, wmsIn, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        $scope.saveToDatabase = function(btn) {
            var commodityDatabase = angular.copy(btn);
            commodityDatabase.id = null;
            commodityDatabase.commodity = btn.cargoName;
            commodityDatabase.idCode = btn.idCode;
            commodityDatabase.companyId = $scope.guide.companyId;
            commodityDatabase.venderId = $scope.guide.customerId;
            commodityDatabase.venderName = $scope.guide.customerName;
            commodityDatabase.commodityCountrys = [{
                importCountryCode: btn.importCountryCode,
                hsCode: btn.hsCode,
                dutyRate: btn.dutyRate,
                country: $rootScope.getCountryName(btn.importCountryCode)
            }];
            $uibModal.open({
                templateUrl: 'app/entities/commodity-database/commodity-database-dialog.html',
                controller: 'CommodityDatabaseDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                resolve: {
                    entity: function () {
                        return commodityDatabase;
                    },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('commodityDatabase');
                    $translatePartialLoader.addPart('commodityCountry');
                    return $translate.refresh();
                }]
                }
            }).result.then(function(result) {
                if(result.id){
                    DataUtils.onCommSelected(result, btn);
                }
            }, function() {
            });
        }

        $scope._onCommSelected = function(item, btn) {
            DataUtils.onCommSelected(item, btn);
        }



    }
})();
