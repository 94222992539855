(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('DnDialogController', DnDialogController);

    DnDialogController.$inject = ['$rootScope', '$scope', '$timeout', '$translate', '$uibModalInstance', '$translatePartialLoader', '$uibModal', 
    'JhiSignature', 'OpsBill', 'entity', 'Guide', 'OpsReport', 'ExpressParcel', 'WmsIn', 'OpsVender', 'DateUtils', 'GuideRates'];
    
    function DnDialogController($rootScope, $scope, $timeout, $translate, $uibModalInstance, $translatePartialLoader, $uibModal,
        JhiSignature, OpsBill, entity, Guide, OpsReport, ExpressParcel, WmsIn, OpsVender, DateUtils, GuideRates) {

        $translatePartialLoader.addPart('opsBill');
        $scope.guide = entity;
        $scope.payers = [];
        $scope.showUnbilled = false;
        var isDnCopy = entity.isDn;
        var shipmentType = entity.shpmtType;
        var billNumCopy = entity.billNum;

        $scope.opsBills = [];
        $scope.opsAccounts = [];
        $scope.opsBill = {};
        var billCopy = {};

        var checkTimeStamp = function(shipmentType, id, timeStamp) {
            if(!timeStamp || !id || !shipmentType){
                return;
            }
            GuideRates.checkTimeStamp({
                shipmentType: shipmentType,
                id: id,
                timeStamp: timeStamp
            }, function(result){
                if(result.diffSeconds){
                    $rootScope.OPS_ALERT($translate.instant('global.timeStampOverdue', {"ref": result.diffSeconds}));
                    $uibModalInstance.close();
                }
            })
        }

        $timeout(function (){
            checkTimeStamp(entity.shpmtType, entity.id, entity.timeStamp);

        	initGuideRates();
            getAccounts();
            if(shipmentType == 'expressParcel'){
                OpsBill.getAll({
                    companyId: $scope.guide.companyId,
                    expressParcelId: $scope.guide.id
                }, function(result){
                    $scope.opsBills = result;
                    checkBillsNum();
                    initOpsBills();
                    // setTimeout(getAccounts(),3000);
                })
            }
            else if(shipmentType == 'guide'){
                OpsBill.getAll({
                    companyId: $scope.guide.companyId,
                    guideId: $scope.guide.id
                }, function(result){                    
                    $scope.opsBills = result;
                    checkBillsNum();
                    initOpsBills();
                    // setTimeout(getAccounts(),3000);
                })
            }
            else {
                OpsBill.getAll({
                    companyId: $scope.guide.companyId,
                    wmsInId: $scope.guide.id
                }, function(result){                    
                    $scope.opsBills = result;
                    checkBillsNum();
                    initOpsBills();
                    // setTimeout(getAccounts(),3000);
                })    
            }   
            var vids = new Set();
            for (var i = $scope.guide.guideRates.length - 1; i >= 0; i--) {
                if ($scope.guide.guideRates[i].payer && !vids.has($scope.guide.guideRates[i].venderId)) {
                    $scope.payers.push({
                        "Company_name": $scope.guide.guideRates[i].payer,
                        "choosedId": $scope.guide.guideRates[i].venderId
                    });
                    vids.add($scope.guide.guideRates[i].venderId);
                }
            }
        })

        $scope.reviseBillNo = function(bill) {
            for(var i = $scope.guide.guideRates.length - 1; i >= 0; i--){
                if($scope.guide.guideRates[i].billNum == bill.billNo 
                    && $rootScope.HAVEAUTH('DNLOCKBILL')
                    && $scope.guide.guideRates[i].dnNum){
                    layer.msg($translate.instant('autopsApp.opsBill.lockedAlert'));
                    return;
                }
            }
            $scope.changeBill(bill);
            var billNo = bill.billNo;
            layer.prompt({
                formType: 0,
                value: billNo,
                title: $translate.instant('autopsApp.opsBill.reviseBillNo')
            }, function(value, index, elem) {
                layer.close(index);
                value = value.replace(/\s+/g, "");
                if(!value){
                    return;
                }
                if(bill.billNo == value){
                    layer.msg($translate.instant('global.no_amendmend'));
                    return;
                }
                if((value.substring(0, 2) == "DB" || value.substring(0, 2) == "CR") && value.length == 12){
                    layer.msg($translate.instant('autopsApp.opsBill.reviseBlAlert'));
                    return;
                }
                var checkEntity = angular.copy(bill);
                checkEntity.billNo = value;
                OpsBill.checkBillNum(checkEntity, function(result){
                    angular.forEach($scope.guide.guideRates, function(rate) {
                        if (rate.billNum == bill.billNo){
                            rate.billNum = value;
                        }
                    });
                    bill.billNo = value;
                    $scope.save();
                    $translate.refresh();
                }, $rootScope.ALERT_ERROR);
            });
        }
        var getBillRate = function(billNum) {
            var billRate;
            angular.forEach($scope.guide.guideRates, function(rate) {
                if(billNum == rate.billNum && rate.venderId){
                    billRate = rate;
                }
            });
            return billRate;
        }
        var checkBillsNum = function() {
            var billNums = new Set();
            angular.forEach($scope.opsBills, function(bill) {
                billNums.add(bill.billNo);
            });   
            var lostBills = new Set();      
            angular.forEach($scope.guide.guideRates, function(rate) {
                if(rate.billNum && !billNums.has(rate.billNum)){
                    lostBills.add(rate.billNum);
                }
            });
            lostBills.forEach(function(billNum){
                var rate = getBillRate(billNum);
                var newBill = getNewBill();
                newBill.billNo = billNum;
                newBill.createdBy = $rootScope.account.id;
                newBill.creater = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                newBill.venderId = rate.venderId;
                newBill.payer = rate.payer;
                var hasDn = false;
                var hasCn = false;
                angular.forEach($scope.guide.guideRates, function(rate){
                    if(billNum == rate.billNum){
                        if(rate.isPay){
                            hasCn = true;
                        }
                        else {
                            hasDn = true;
                        }                        
                    }
                })    
                if(hasDn && hasCn){
                    newBill.isDiff = true;
                }
                if(hasCn){
                    newBill.isDn = false;
                }
                updateTotal(newBill);
                Guide.getRevDeadLine({opsVenderId: newBill.venderId}, angular.copy($scope.guide), function(result) {
                    newBill.deadlineDate = result.revDeadLine;
                    OpsBill.save(newBill, function(result){
                        $scope.opsBills.push(result);
                        $scope.changeBill(result);
                    })
                })
            })
        }

        var getNewBill = function() {
            var opsBill = {};
            opsBill.companyId = $scope.guide.companyId;
            if (shipmentType == 'expressParcel'){
                opsBill.expressParcelId = $scope.guide.id;
                opsBill.jobNum = $scope.guide.jobNum;
            }
            else if(shipmentType == 'guide'){
                opsBill.guideId = $scope.guide.id;
                opsBill.jobNum = $scope.guide.jobNum;
            }
            else {
                opsBill.wmsInId = $scope.guide.id;
                opsBill.jobNum = $scope.guide.warehousingNumber;
            }

            opsBill.issueDate = new Date();
            opsBill.venderId = null;
            opsBill.payer = "";
            opsBill.isDn = true;
            opsBill.isDiff = false;
            angular.forEach($scope.guide.guideRates, function(rate){
                if(!rate.isHidden && !rate.billNum && rate.venderId && !rate.isPay){
                    opsBill.venderId = rate.venderId;
                    opsBill.payer = rate.payer;
                }
            })     
            if(!opsBill.venderId){
                angular.forEach($scope.guide.guideRates, function(rate){
                    if(!rate.isHidden && !rate.billNum && rate.venderId && rate.isPay){
                        opsBill.venderId = rate.venderId;
                        opsBill.payer = rate.payer;
                        opsBill.isDn = false;
                    }
                })    
            }        
            if ($scope.opsAccounts && $scope.opsAccounts.length > 0){
                opsBill.account = $scope.opsAccounts[0].signature;
            }            
            opsBill.remarks = null;
            opsBill.isEnNote = false;
            return opsBill;
        }

        var getAccounts = function() {
            JhiSignature.query({companyId: $rootScope.account.companyId}, function(result) {
                $scope.opsAccounts = result;          
            });
        }      

        var initGuideRates = function() {    
            for(var i = $scope.guide.guideRates.length -1; i >= 0; i--){
                if($scope.guide.guideRates[i].isHidden 
                    || !$scope.guide.guideRates[i].venderId 
                    || (!$scope.guide.guideRates[i].payAmount && $scope.guide.guideRates[i].payAmount != 0)
                    || !$scope.guide.guideRates[i].payCurrency 
                    || !$scope.guide.guideRates[i].payer){
                    $scope.guide.guideRates.splice(i, 1);
                }
                else if($rootScope.HAVEAUTH('FB_VIEW_OTH_RATE')){
                    var usrName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                    if(usrName != $scope.guide.guideRates[i].inputBy){
                        $scope.guide.guideRates.splice(i, 1);
                    }
                }
            }
            angular.forEach($scope.guide.guideRates, function(rate) {
                if(rate.isPay && rate.payAmount){
                    rate.showAmt = -rate.payAmount;
                }
                else {
                    rate.showAmt = rate.payAmount;
                }
            });            
        }

        var initOpsBills = function() {
            for(var i = $scope.opsBills.length -1; i >= 0; i--){
                if ($rootScope.account.authorities.indexOf("AU_VIEW_EXP") === -1 || ($rootScope.account.authorities.indexOf("FUN_VIEWCOST") === -1 && $scope.guide.hidenCost)) {
                    for(var j = $scope.guide.guideRates.length -1; j >= 0; j--){
                        if($scope.guide.guideRates[j].billNum == $scope.opsBills[i].billNo && $scope.guide.guideRates[j].isPay){
                            $scope.opsBills.splice(i, 1);
                            break;
                        }
                    }
                }
                if ($rootScope.account.authorities.indexOf("AU_VIEW_REV") === -1 || ($rootScope.account.authorities.indexOf("FUN_VIEWSELLING") === -1 && $scope.guide.hidenSelling)) {
                    for(var j = $scope.guide.guideRates.length -1; j >= 0; j--){
                        if($scope.guide.guideRates[j].billNum == $scope.opsBills[i].billNo && !$scope.guide.guideRates[j].isPay){
                            $scope.opsBills.splice(i, 1);
                            break;
                        }
                    }
                }
                if ($rootScope.account.authorities.indexOf("FUN_VIEW_ONE_HIDDEN") === -1) {
                    for(var j = $scope.guide.guideRates.length -1; j >= 0; j--){
                        if($scope.guide.guideRates[j].billNum == $scope.opsBills[i].billNo && $scope.guide.guideRates[j].hiddenRate){
                            $scope.opsBills.splice(i, 1);
                            break;
                        }
                    }
                }
                if ($rootScope.account.authorities.indexOf("FUN_VIEW_PAYER") === -1) {
                    for(var j = $scope.guide.guideRates.length -1; j >= 0; j--){
                        if($scope.guide.guideRates[j].billNum == $scope.opsBills[i].billNo && $scope.guide.guideRates[j].payer && !$scope.guide.guideRates[j].isPay){
                            $scope.opsBills.splice(i, 1);
                            break;
                        }
                    }
                }
                if ($rootScope.account.authorities.indexOf("FUN_VIEW_PAYEE") === -1) {
                    for(var j = $scope.guide.guideRates.length -1; j >= 0; j--){
                        if($scope.guide.guideRates[j].billNum == $scope.opsBills[i].billNo && $scope.guide.guideRates[j].payer && $scope.guide.guideRates[j].isPay){
                            $scope.opsBills.splice(i, 1);
                            break;
                        }
                    }
                }
            }
            
            for(var i = $scope.guide.guideRates.length -1; i >= 0; i--){
                if ($rootScope.account.authorities.indexOf("FUN_VIEWCOST") === -1) {
                    if($scope.guide.guideRates[i].isPay && $scope.guide.hidenCost){
                        $scope.guide.guideRates.splice(i, 1);
                        continue;
                    }
                }
                if ($rootScope.account.authorities.indexOf("FUN_VIEWSELLING") === -1) {
                    if(!$scope.guide.guideRates[i].isPay && $scope.guide.hidenSelling){
                        $scope.guide.guideRates.splice(i, 1);
                        continue;
                    }
                }
                if ($rootScope.account.authorities.indexOf("FUN_VIEW_ONE_HIDDEN") === -1) {
                    if($scope.guide.guideRates[i].hiddenRate){
                        $scope.guide.guideRates.splice(i, 1);
                        continue;
                    }
                }
                if ($rootScope.account.authorities.indexOf("FUN_VIEW_PAYER") === -1) {
                    if(!$scope.guide.guideRates[i].isPay){
                        $scope.guide.guideRates.splice(i, 1);
                        continue;
                    }
                }
                if ($rootScope.account.authorities.indexOf("FUN_VIEW_PAYEE") === -1) {
                    if($scope.guide.guideRates[i].isPay){
                        $scope.guide.guideRates.splice(i, 1);
                        continue;
                    }
                }
                if ($rootScope.account.authorities.indexOf("AU_VIEW_EXP") === -1) {
                    if($scope.guide.guideRates[i].isPay){
                        $scope.guide.guideRates.splice(i, 1);
                        continue;
                    }
                }
                if ($rootScope.account.authorities.indexOf("AU_VIEW_REV") === -1) {
                    if(!$scope.guide.guideRates[i].isPay){
                        $scope.guide.guideRates.splice(i, 1);
                        continue;
                    }
                }
                if($scope.guide.guideRates[i].dnNum && $scope.guide.guideRates[i].dnNum.indexOf("CS") == 0){
                    $scope.guide.guideRates.splice(i, 1);
                    continue;
                }
            }
            if ($scope.opsBills && $scope.opsBills.length > 0){
                $scope.opsBill = {};
                if(billNumCopy){
                    angular.forEach($scope.opsBills, function(bill){
                        if(bill.billNo == billNumCopy){
                            $scope.opsBill = bill;
                        }
                    })
                }
                if(!$scope.opsBill.id && isDnCopy != null){
                    angular.forEach($scope.opsBills, function(bill){
                        if(bill.isDn == isDnCopy){
                            $scope.opsBill = bill;
                        }
                    })
                }
                if(!$scope.opsBill.id){
                    $scope.opsBill = $scope.opsBills[0];                    
                    authorityCheck();                    
                }
                updateTotal($scope.opsBill);
                billCopy = angular.copy($scope.opsBill);
                $translate.refresh();
            }
            else {
                $scope.opsBill = {};
                billCopy = angular.copy($scope.opsBill);
            }
        }

        $scope.addBill = function() {
            if(!angular.equals(billCopy, $scope.opsBill)){
                layer.confirm($translate.instant('global.saveAlert'), {
                    icon: 0, 
                    title: $translate.instant('global.messageInfo'),
                    btn: [$translate.instant('entity.action.save'), $translate.instant('entity.action.back')] //按钮
                }, function(index) {
                    $scope.save();
                    layer.close(index);
                    return;
                }, function() {

                });
            }
            else {
                var newBill = getNewBill();
                if(!newBill.venderId){
                    layer.msg($translate.instant('autopsApp.guide.allChargesBilled'));
                    return;
                }
                OpsBill.getBillNo(newBill, function(result){
                    var guideCopy = angular.copy($scope.guide);
                    guideCopy.dnSentTime = result.issueDate;
                    Guide.getRevDeadLine({opsVenderId: result.venderId}, guideCopy, function(gResult) {
                        result.deadlineDate = gResult.revDeadLine;
                        $scope.opsBills.push(result);
                        $scope.changeBill(result);
                    })
                })
            }
        }
        
        $scope.changeBill = function(bill) {
            if(!angular.equals(billCopy, $scope.opsBill)){
                layer.confirm($translate.instant('global.saveAlert'), {
                    icon: 0, 
                    title: $translate.instant('global.messageInfo'),
                    btn: [$translate.instant('entity.action.save'), $translate.instant('entity.action.back')] //按钮
                }, function(index) {
                    $scope.save();
                    layer.close(index);
                    return;
                }, function() {

                });
            }
            else {
                $scope.opsBill = bill; 
                if(!$scope.opsBill.billAmtStr){  
                    $scope.filterRateByVenderId();
                }
                else {
                    updateTotal($scope.opsBill);
                }
                authorityCheck();
                billCopy = angular.copy($scope.opsBill);
            }
        }
        function authorityCheck(){
            if ($scope.opsBill.createdBy == $rootScope.account.id 
                    && $rootScope.account.authorities.indexOf("AU_AMEND_OWN_BILL") === -1) {
                $rootScope.FORBIDDEN_BY_MSG('AU_AMEND_OWN_BILL');

            }
            else if ($scope.opsBill.createdBy && $scope.opsBill.createdBy != $rootScope.account.id 
                    && $rootScope.account.authorities.indexOf("AU_AMEND_OTHER_BILL") === -1) {
                $rootScope.FORBIDDEN_BY_MSG('AU_AMEND_OTHER_BILL');
            }
        }
        
        $scope.choosedRate = function(item) {
            if (item.dnNum && item.dnNum.substring(0,2) == "CS"){
                return false;
            }
            return !item.isHidden && $scope.opsBill.billNo && item.billNum == $scope.opsBill.billNo;
        };

        $scope.unchoosedRate = function(item) {
            if (item.dnNum && item.dnNum.substring(0,2) == "CS"){
                return false;
            }
            return !item.isHidden && !item.billNum;
        };

        var isSetDn = false;
        $scope.setIsDn = function(isDn) {
            $scope.opsBill.isDn = isDn;
            isSetDn = true;
            var billNumCopy = $scope.opsBill.billNo;
            if ($scope.opsBill.billNo.length == 12 && ($scope.opsBill.billNo.indexOf("DB") == 0 || $scope.opsBill.billNo.indexOf("CR") == 0)){
                $scope.opsBill.billNo = (isDn?"DB":"CR") + $scope.opsBill.billNo.substring(2);
            }              
            if(billNumCopy != $scope.opsBill.billNo){
                angular.forEach($scope.guide.guideRates, function(rate) {
                    if(rate.billNum == billNumCopy){
                        rate.billNum = $scope.opsBill.billNo;
                    }
                })
            }
            $scope.filterRateByVenderId();
        }

        var setDnCn = function(opsBill, isDn) {
            opsBill.isDn = isDn;
            var billNumCopy = opsBill.billNo;
            if (opsBill.billNo.length == 12 && (opsBill.billNo.indexOf("DB") == 0 || opsBill.billNo.indexOf("CR") == 0)){
                opsBill.billNo = (isDn?"DB":"CR") + opsBill.billNo.substring(2);
            }  
            if(billNumCopy != opsBill.billNo){
                angular.forEach($scope.guide.guideRates, function(rate) {
                    if(rate.billNum == billNumCopy){
                        rate.billNum = opsBill.billNo;
                    }
                })
            }
        }   

        $scope.totalList = [];
        var total = 0;
        var updateTotal = function(opsBill) {
            $scope.totalList = [];
            var currencys = [];
            angular.forEach($scope.guide.guideRates, function(rate) {
                if (rate.billNum == opsBill.billNo && rate.payCurrency && currencys.indexOf(rate.payCurrency) === -1){
                    currencys.push(rate.payCurrency);
                }
            });         
            var billAmtStr = "";
            angular.forEach(currencys, function(currency){
                total = 0;
                angular.forEach($scope.guide.guideRates, function(rate) {
                    if (rate.billNum == opsBill.billNo && rate.payCurrency == currency && rate.payAmount){
                        if (rate.isPay) {
                            total = total - rate.payAmount;
                        }
                        else {
                            total = total + rate.payAmount;
                        }
                    }
                });
                $scope.totalList.push({currency: currency, total: total.toFixed(2) -0}); 
                billAmtStr = billAmtStr + currency + total.toFixed(2) + " ";
            })     
            if(!isSetDn && $scope.totalList.length > 0){ 
                var isDn = false;
                angular.forEach($scope.totalList, function(data){
                    if(data.total >= 0){
                        isDn = true;
                    }
                })   
                setDnCn(opsBill, isDn);
            }
            isSetDn = false;
            opsBill.billAmtStr = billAmtStr;
        }

        $scope.clear = function() {
            $uibModalInstance.dismiss($scope.guide);
        };

        $scope.delRate = function(rate) {
            if ($scope.opsBill.createdBy == $rootScope.account.id 
                    && $rootScope.account.authorities.indexOf("AU_AMEND_OWN_BILL") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_AMEND_OWN_BILL');
                return;
            }
            if ($scope.opsBill.createdBy && $scope.opsBill.createdBy != $rootScope.account.id 
                    && $rootScope.account.authorities.indexOf("AU_AMEND_OTHER_BILL") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_AMEND_OTHER_BILL');
                return;
            }
            if(rate.dnNum && $rootScope.HAVEAUTH('DNLOCKBILL')){
                layer.msg($translate.instant('autopsApp.opsBill.lockedAlert'));
                return;
            }
            rate.billNum = null;
            updateTotal($scope.opsBill);
        };
        $scope.amendBillForbidden = function() {
            if ($scope.opsBill.createdBy == $rootScope.account.id 
                    && $rootScope.account.authorities.indexOf("AU_AMEND_OWN_BILL") === -1) {
                return true;
            }
            if ($scope.opsBill.createdBy && $scope.opsBill.createdBy != $rootScope.account.id 
                    && $rootScope.account.authorities.indexOf("AU_AMEND_OTHER_BILL") === -1) {
                return true;
            }
            if($rootScope.HAVEAUTH('DNLOCKBILL')){
                for(var i = $scope.guide.guideRates.length - 1; i >= 0; i--){
                    if($scope.guide.guideRates[i].billNum == $scope.opsBill.billNo 
                        && $scope.guide.guideRates[i].dnNum){
                        return true;
                    }
                }
            }
            return false;
        }
        $scope.addRate = function(rate) {
            if ($scope.opsBill.createdBy == $rootScope.account.id 
                    && $rootScope.account.authorities.indexOf("AU_AMEND_OWN_BILL") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_AMEND_OWN_BILL');
                return;
            }
            if ($scope.opsBill.createdBy && $scope.opsBill.createdBy != $rootScope.account.id 
                    && $rootScope.account.authorities.indexOf("AU_AMEND_OTHER_BILL") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_AMEND_OTHER_BILL');
                return;
            }
            if($scope.amendBillForbidden()){
                layer.msg($translate.instant('autopsApp.opsBill.lockedAlert'));
                return;
            }
            if (rate.venderId != $scope.opsBill.venderId){
                layer.msg($translate.instant('autopsApp.guide.wrongVid'));
                return;
            }
            rate.billNum = $scope.opsBill.billNo;
            updateTotal($scope.opsBill);
        };
        $scope._onPayerSelected = function(data) {
            $scope.opsBill.venderId = data.choosedId;
            $scope.opsBill.payer = data.Company_name;
            $scope.filterRateByVenderId();

            var guideCopy = angular.copy($scope.guide);
            guideCopy.dnSentTime = $scope.opsBill.issueDate;
            Guide.getRevDeadLine({opsVenderId: $scope.opsBill.venderId}, guideCopy, function(result) {
                $scope.opsBill.deadlineDate = result.revDeadLine;
            })
        }
        $scope.onAccountSelected = function(data) {
            $scope.opsBill.account = data.signature;
        }
        $scope.filterRateByVenderId = function() {
            if (!$scope.opsBill.venderId){
                return;
            }
            angular.forEach($scope.guide.guideRates, function(rate) {
                if (rate.billNum == $scope.opsBill.billNo){
                    rate.billNum = null;                 
                }
            });
            if(!isSetDn){
                var haveRate = false;
                if($scope.opsBill.isDn){                
                    angular.forEach($scope.guide.guideRates, function(rate) {
                        if (!rate.isPay && rate.venderId == $scope.opsBill.venderId){
                             haveRate = true;
                        }
                    });
                    if(!haveRate){
                        $scope.opsBill.isDn = false;
                    }
                }
                else {
                    angular.forEach($scope.guide.guideRates, function(rate) {
                        if (rate.isPay && rate.venderId == $scope.opsBill.venderId){
                             haveRate = true;
                        }
                    });
                    if(!haveRate){
                        $scope.opsBill.isDn = true;
                    }
                }            
            }
            angular.forEach($scope.guide.guideRates, function(rate) {
                if (!rate.isHidden && rate.venderId == $scope.opsBill.venderId && !rate.billNum && (!rate.dnNum || $rootScope.HAVENOAUTH('DNLOCKBILL'))){
                    // && (!rate.dnNum || $rootScope.HAVENOAUTH('DNLOCKBILL'))
                    // rate.billNum = $scope.opsBill.billNo;
                    if ($scope.opsBill.isDiff || $scope.opsBill.isDn == null){
                        rate.billNum = $scope.opsBill.billNo;
                    }
                    else if($scope.opsBill.isDn && !rate.isPay ){
                        rate.billNum = $scope.opsBill.billNo;                        
                    }
                    else if(!$scope.opsBill.isDn && rate.isPay ){
                        rate.billNum = $scope.opsBill.billNo;                        
                    }                    
                }
            });
            updateTotal($scope.opsBill);
        }

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }
        function getOpsBillDTO(data){
            var opsBillDTO = {};
            if(data){
                opsBillDTO.opsBill = angular.copy(data);
            }
            else {
                opsBillDTO.opsBill = angular.copy($scope.opsBill);
            }
            opsBillDTO.guideRates = angular.copy($scope.guide.guideRates);
            angular.forEach(opsBillDTO.guideRates, function(rate){
                rate.priceDec = rate.price;
                rate.countDec = rate.count;
                rate.totalDec = rate.total;
                rate.orgTotal = rate.total;
            });
            return opsBillDTO;
        }

        $scope.save = function() {
            if (!$scope.opsBill.billNo){
                layer.msg($translate.instant('autopsApp.opsBill.billNoNullAlert'));
                return;
            }
            if ($scope.amendBillForbidden()){
                layer.msg($translate.instant('autopsApp.opsBill.lockedAlert'));
                return;
            }
            $scope.isSaving = true; 
            OpsBill.saveBill(getOpsBillDTO(), onSaveSuccess, onSaveError);   
        };

        var onSaveSuccess = function(result) {
            if($scope.opsBill.billNo != result.billNo){
                for(var i = $scope.guide.guideRates.length - 1; i >= 0; i--){
                    if($scope.guide.guideRates[i].billNum == $scope.opsBill.billNo){
                        $scope.guide.guideRates[i].billNum = result.billNo;
                    }
                }
            }
            angular.copy(result, $scope.opsBill);
            billCopy = angular.copy($scope.opsBill);
            layer.msg($translate.instant('global.save_success'));
            $scope.isSaving = false;
            $translate.refresh();
        }
        

        $scope.defualtReportCode = 200;
        $scope.getDefualtReportCode = function() {
            if($scope.opsBill.isDn){
                if(shipmentType == 'expressParcel'){
                    $scope.defualtReportCode = 730;
                }
                else if(shipmentType == 'wms'){
                    $scope.defualtReportCode = 850;
                }
                else if ($scope.guide.shipmentType == 'AIR' || $scope.guide.shipmentType == 'FBAAIR'){
                    $scope.defualtReportCode = 410;
                }
                else {
                    $scope.defualtReportCode = 200;
                }
            }
            else {
                if(shipmentType == 'expressParcel'){
                    $scope.defualtReportCode = 740;
                }
                else if(shipmentType == 'wms'){
                    $scope.defualtReportCode = 860;
                }
                else if ($scope.guide.shipmentType == 'AIR' || $scope.guide.shipmentType == 'FBAAIR'){
                    $scope.defualtReportCode = 420;
                }
                else {
                    $scope.defualtReportCode = 210;
                }
            }     
            // alert($scope.defualtReportCode);
        }
        $rootScope.LOAD_REPORT_TEMPLATES();

        var previewPDF = false;
        var opsBillCopy;
        $scope.dnSend = function(emailType, reportCode, fileType) {
            if (!$scope.opsBill.billNo){
                layer.msg($translate.instant('autopsApp.opsBill.billNoNullAlert'));
                return;
            }   
            $scope.isSaving = true; 
            opsBillCopy = angular.copy($scope.opsBill);
            opsBillCopy.opType = 3;
            opsBillCopy.fileType = fileType;
            opsBillCopy.reportType = reportCode; 
			opsBillCopy.emailType = emailType;      
            previewPDF = false;      
            OpsBill.saveBill(getOpsBillDTO(opsBillCopy), generateReport, onSaveError);   
        };

        $scope.generateDN = function(fileType, reportCode) {
            if (!$scope.opsBill.billNo){
                layer.msg($translate.instant('autopsApp.opsBill.billNoNullAlert'));
                return;
            }
            if(!reportCode){
                if($scope.opsBill.isDn){
                    if(shipmentType == 'expressParcel'){
                        reportCode = 730;
                    }
                    else if(shipmentType == 'wms'){
                        reportCode = 850;
                    }
                    else if ($scope.guide.shipmentType == 'AIR'){
                        reportCode = 410;
                    }
                    else {
                        reportCode = 200;
                    }
                }
                else {
                    if(shipmentType == 'expressParcel'){
                        reportCode = 740;
                    }
                    else if(shipmentType == 'wms'){
                        reportCode = 860;
                    }
                    else if ($scope.guide.shipmentType == 'AIR'){
                        reportCode = 420;
                    }
                    else {
                        reportCode = 210;
                    }
                }
            }

            $scope.isSaving = true; 
            opsBillCopy = angular.copy($scope.opsBill);
            opsBillCopy.opType = 1;
            opsBillCopy.fileType = fileType;
            opsBillCopy.reportType = reportCode;
            previewPDF = false;
            OpsBill.saveBill(getOpsBillDTO(opsBillCopy), generateReport, onSaveError);   
        };

        $scope.previewReport = function(reportCode) {
            if (!$scope.opsBill.billNo){
                layer.msg($translate.instant('autopsApp.opsBill.billNoNullAlert'));
                return;
            }
            if(!reportCode){
                if($scope.opsBill.isDn){
                    if(shipmentType == 'expressParcel'){
                        reportCode = 730;
                    }
                    else if(shipmentType == 'wms'){
                        reportCode = 850;
                    }
                    else if ($scope.guide.shipmentType == 'AIR'){
                        reportCode = 410;
                    }
                    else {
                        reportCode = 200;
                    }
                }
                else {
                    if(shipmentType == 'expressParcel'){
                        reportCode = 740;
                    }
                    else if(shipmentType == 'wms'){
                        reportCode = 860;
                    }
                    else if ($scope.guide.shipmentType == 'AIR'){
                        reportCode = 420;
                    }
                    else {
                        reportCode = 210;
                    }
                }
            }
            $scope.isSaving = true; 
            opsBillCopy = angular.copy($scope.opsBill);
            opsBillCopy.opType = 1;
            opsBillCopy.fileType = 1;
            opsBillCopy.reportType = reportCode;    
            previewPDF = true;    
            OpsBill.saveBill(getOpsBillDTO(opsBillCopy), generateReport, onSaveError);   
        };

        var loadGuide = function() {
            if(shipmentType == 'expressParcel'){
                ExpressParcel.getGuideAndRates({
                    id: $scope.guide.id
                }, function(result) {
                    $scope.guide = result;
                    initGuideRates();
                });
            }
            else if (shipmentType == 'guide'){
                Guide.getGuideAndRates({
                    id: $scope.guide.id
                }, function(result) {
                    $scope.guide = result;
                    initGuideRates();
                });
            }
            else {
                 WmsIn.getGuideRates({
                    id: $scope.guide.id
                }, function(result) {
                    $scope.guide = result;
                    initGuideRates();
                })
            }
        }
        var onSaveError = function(result) {
            $scope.isSaving = false;
            if (result.status == 409){
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {});
            }
            else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }            
        };

        var generateReport = function () {
            var guideReportDTO = angular.copy($scope.guide);
            for (var i = guideReportDTO.guideRates.length -1; i >= 0; i --){
                if (guideReportDTO.guideRates[i].dnNum && guideReportDTO.guideRates[i].dnNum.substring(0,2) == "CS"){
                    guideReportDTO.guideRates.splice(i, 1);
                }
                else if (guideReportDTO.guideRates[i].isHidden || guideReportDTO.guideRates[i].billNum != opsBillCopy.billNo){
                    guideReportDTO.guideRates.splice(i, 1);
                }
                else {
                    guideReportDTO.guideRates[i].orgTotal = guideReportDTO.guideRates[i].total;
                }
            }

            if (!guideReportDTO.guideRates || guideReportDTO.guideRates.length == 0) {
                layer.msg($translate.instant('global.noRecord'));
                $scope.isSaving = false;
                return;
            } 
            
            guideReportDTO.isEn = opsBillCopy.isEnNote;
            guideReportDTO.userBankInfo = opsBillCopy.account;
            guideReportDTO.issueDate = DateUtils.convertLocalDateToServer(opsBillCopy.issueDate);          
            guideReportDTO.deadlineDate = DateUtils.convertLocalDateToServer(opsBillCopy.deadlineDate);   
            guideReportDTO.payer = opsBillCopy.payer;
            guideReportDTO.billNumStr = opsBillCopy.billNo;
            guideReportDTO.dnRemarks = opsBillCopy.remarks;

			guideReportDTO.opType = opsBillCopy.opType;
			guideReportDTO.fileType = opsBillCopy.fileType;
			guideReportDTO.reportType = opsBillCopy.reportType;

            if(guideReportDTO.companyId == 366 || guideReportDTO.companyId == 54){
                guideReportDTO.fileName = "Invoice-" + opsBillCopy.billNo 
                    + "-" + (guideReportDTO.poNum?guideReportDTO.poNum:guideReportDTO.jobNum) + "-" + opsBillCopy.billAmtStr.replace("USD", "$").trim();
            }
            else if (opsBillCopy.isDn){
                if (guideReportDTO.soNum){
                    guideReportDTO.fileName = guideReportDTO.soNum + "-DB";
                }
                else if (guideReportDTO.mblNum){
                    guideReportDTO.fileName = guideReportDTO.mblNum + "-DB";
                }
                else {
                    guideReportDTO.fileName = guideReportDTO.jobNum + "-DB";
                }                
            }
            else{
                if (guideReportDTO.soNum){
                    guideReportDTO.fileName = guideReportDTO.soNum + "-CR";
                }
                else if (guideReportDTO.mblNum){
                    guideReportDTO.fileName = guideReportDTO.mblNum + "-CR";
                }
                else {
                    guideReportDTO.fileName = guideReportDTO.jobNum + "-CR";
                }     
            }            
            if (guideReportDTO.opType == 1){
                OpsReport.generateGuide({}, guideReportDTO, function(result) {
                    if (guideReportDTO.fileType == 2) {
                        var file = new Blob([result.data], {
                            type: 'application/vnd.ms-excel'
                        });
                        if (file.size == 0) {
                            layer.msg($translate.instant('global.loadFileFailed'));
                            return;
                        }
                        var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                        var aTag = document.createElement("a");
                        aTag.download = guideReportDTO.fileName + ".xlsx";
                        aTag.href = blobURL;
                        aTag.click();
                    } else {
                        var file = new Blob([result.data], {
                            type: 'application/pdf'
                        });
                        if (file.size == 0) {
                            layer.msg($translate.instant('global.loadFileFailed'));
                            return;
                        }
                        if(previewPDF){
                            var fileURL = URL.createObjectURL(file);
                            var previewDialog = layer.open({
                                type: 2,
                                area: ['1000px', '680px'],
                                maxmin: true,
                                title: $translate.instant("entity.action.preview"),
                                shade: 0.3,
                                moveType: 0,
                                shift: 0,
                                content: fileURL
                            });
                        }
                        else {
                            var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                            var aTag = document.createElement("a");
                            aTag.download = guideReportDTO.fileName + ".PDF";
                            aTag.href = blobURL;
                            aTag.click();
                        }
                    }
                    $scope.isSaving = false;
                });
            }
            else {
                OpsReport.getMultiAttachments({
                    reportTypes: guideReportDTO.reportType,
                    fileType: opsBillCopy.fileType
                }, guideReportDTO, function(result) {
                    var resultMap = {};
                    resultMap.reportCode = opsBillCopy.reportType;
                    resultMap.emailType = opsBillCopy.emailType;                    
                    resultMap.venderId = opsBillCopy.venderId;
                    resultMap.billNo = opsBillCopy.billNo;
                    resultMap.billAmtStr = opsBillCopy.billAmtStr;
                    resultMap.guide = angular.copy($scope.guide);
                    resultMap.report = result;
                    $uibModalInstance.close(resultMap);
                    $scope.isSaving = false;
                });
            }
        }
        $scope.delBill = function(opsBill) {    
            if (opsBill.createdBy == $rootScope.account.id 
                    && $rootScope.account.authorities.indexOf("AU_DEL_OWN_BILL") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_DEL_OWN_BILL');
                return;
            }
            if (opsBill.createdBy != $rootScope.account.id 
                    && $rootScope.account.authorities.indexOf("AU_DEL_OTHER_BILL") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_DEL_OTHER_BILL');
                return;
            }
            if(!angular.equals(billCopy, $scope.opsBill) && !angular.equals(opsBill, $scope.opsBill)){
                layer.confirm($translate.instant('global.saveAlert'), {
                    icon: 0, 
                    title: $translate.instant('global.messageInfo'),
                    btn: [$translate.instant('entity.action.save'), $translate.instant('entity.action.back')] //按钮
                }, function(index) {
                    $scope.save();
                    layer.close(index);
                    return;
                }, function() {
                    return;
                });
                return;
            }
            for(var i = $scope.guide.guideRates.length - 1; i >= 0; i--){
                if($scope.guide.guideRates[i].billNum == opsBill.billNo 
                    && $scope.guide.guideRates[i].dnNum && $rootScope.HAVEAUTH('DNLOCKBILL')){
                    layer.msg($translate.instant('autopsApp.opsBill.lockedAlert'));
                    return;
                }
            }
            if (opsBill.id){   
                $scope.isSaving = true;
                OpsBill.delBill(getOpsBillDTO(opsBill), function(result){
                    $scope.isSaving = false;  
                    layer.msg($translate.instant('global.messages.submitted'));
                    delOneBill(opsBill);
                }, onSaveError);
            }
            else {
                delOneBill(opsBill);
            }
        }
        function delOneBill(opsBill) {
            angular.forEach($scope.guide.guideRates, function(rate) {
                if (rate.billNum == opsBill.billNo){
                    rate.billNum = null;
                }
            });
            var index = $scope.opsBills.indexOf(opsBill);
            if (index > -1) {
                $scope.opsBills.splice(index, 1);
                initOpsBills();
            };
        }

        $scope.invoiceRequestShow = function() {
            if (shipmentType == 'guide' && (!$scope.guide.submited)) {
                layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                return;
            }
            if (!$scope.guide.etd) {
                layer.msg($translate.instant('autopsApp.guide.etdNullAlert'));
                return;
            };
            // if (shipmentType == 'guide' && $scope.guide.companyId == 367 && !$scope.guide.ratesLocked) {
            //     layer.msg($translate.instant('autopsApp.guide.sts.rateNotLocked'));
            //     return;
            // }

            var guide = angular.copy($scope.guide);
            for(var i = guide.guideRates.length - 1; i >= 0; i --){
                if(guide.guideRates[i].dnNum || guide.guideRates[i].isHidden || guide.guideRates[i].billNum != $scope.opsBill.billNo){
                    guide.guideRates.splice(i, 1);
                }
            }
            if(guide.guideRates.length == 0){
                layer.msg($translate.instant('global.allChargesBilled'));
                return;
            }
            guide.billMakeDate = $scope.opsBill.issueDate;
            $uibModal.open({
                templateUrl: 'app/entities/guide/invocie-request.html',
                controller: 'InvoiceRequestController',
                size: 'lg',
                backdrop: false,
                resolve: {
                    entity: [function() {
                        return guide;
                    }]
                }
            }).result.then(function(result) {
                loadGuide();
            }, function() {});
        }
        $scope.paymentRequestShow = function() {
            if (shipmentType == 'guide' && (!$scope.guide.submited)) {
                layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));
                return;
            }
            if (!$scope.guide.etd) {
                layer.msg($translate.instant('autopsApp.guide.etdNullAlert'));
                return;
            };
            // if (shipmentType == 'guide' && $scope.guide.companyId == 367 && !$scope.guide.ratesLocked) {
            //     layer.msg($translate.instant('autopsApp.guide.sts.rateNotLocked'));
            //     return;
            // }
            var guide = angular.copy($scope.guide);
            for(var i = guide.guideRates.length - 1; i >= 0; i --){
                if(guide.guideRates[i].dnNum || guide.guideRates[i].isHidden || guide.guideRates[i].billNum != $scope.opsBill.billNo){
                    guide.guideRates.splice(i, 1);
                }
            }            
            if(guide.guideRates.length == 0){
                layer.msg($translate.instant('global.allChargesBilled'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/guide/payment-request.html',
                controller: 'PaymentRequestController',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return guide;
                    }]
                }
            }).result.then(function(result) {
                loadGuide();
            }, function() {});
        }


    }
})();
