(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BizAnalysisController', BizAnalysisController);

    BizAnalysisController.$inject = ['$scope', '$rootScope', '$translate', '$filter', 'Principal', 'GuideRates', 'DateUtils', 'OpsVender', 'OpsFiles', 'Email', 'OpsCompany'];

    function BizAnalysisController($scope, $rootScope, $translate, $filter, Principal, GuideRates, DateUtils, OpsVender, OpsFiles, Email, OpsCompany) {
        $scope.companys = [];
        $scope.filterDTO = {};
        $scope.filterDTO.dateFrom = new Date();
        $scope.filterDTO.dateTo = new Date();

        function loadCompanys () {
            OpsCompany.getGroupCompanys(function(result){
                $scope.companys = result;
                $scope.filterDTO.scope = $rootScope.account.companyId;
            })
        }

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                if($rootScope.account.isSuper){
                    loadCompanys();
                }               
            });
        }
        else {
            if($rootScope.account.isSuper){
                loadCompanys();
            }   
        }   

        $scope.datePickerOpenStatus = {};
        $scope.monthFormat = "yyyy-MM";
        $scope.monthDatepickerOptions = {
            minMode: 'month'
        }
        $scope.datePickerOpenStatus.dateFrompicker = false;
        $scope.datePickerOpenStatus.dateTopicker = false;

        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }


        $scope.bizStates = [];
        $scope.reportavailalbe = false;
        $scope.getContetns = function() {
            if (!$scope.filterDTO.dateFrom) {
                layer.msg($translate.instant('stats.stat.lackTimeDateFrom'));
                return;
            }
            if (!$scope.filterDTO.dateTo) {
                layer.msg($translate.instant('stats.stat.lackTimeDateTo'));
                return;
            }

            var year = $scope.filterDTO.dateFrom.getFullYear();         
            var month = $scope.filterDTO.dateFrom.getMonth();
            $scope.filterDTO.dateFrom = new Date(year, month, 1);

            year = $scope.filterDTO.dateTo.getFullYear();        
            month = $scope.filterDTO.dateTo.getMonth();
            if (month == 11) {
                month = month-12;
                year++;
            }            
            $scope.filterDTO.dateTo = new Date(year, month+1, 0);
            if (DateUtils.getDaysDiff($scope.filterDTO.dateFrom, $scope.filterDTO.dateTo) > 365) {
                layer.msg($translate.instant('global.messages.etdPeriodAlert'));
                return;
            }
            if ($scope.filterDTO.dateFrom > $scope.filterDTO.dateTo) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;
            }
            $scope.filterDTO.companyId = $rootScope.account.companyId;
            $scope.isSaving = true;

            OpsFiles.statsBizState($scope.filterDTO, function(result) {
                $scope.isSaving = false;
                if (!result || result.length == 0) {
                    $scope.bizStates = result;
                    initContent();
                    layer.msg($translate.instant('stats.stat.resultNullAlert'));
                    return;
                }
                $scope.bizStates = [];
                $scope.bizStates = result;
                $scope.reportavailalbe = true;
                // setTimeout(function() {frzTable("divTable", "showTable", null);}, 100); 
            }, function(response) {
                $scope.isSaving = false;
                $scope.reportavailalbe = false;

            });
        }

        var initContent = function() {
            $scope.bizStates = [];
            $scope.bizStates.bizDetailList = [];           

            for (var j = 0; j < 3; j++) {
                $scope.bizStates.push({});
            }

            for (var j = 0; j < 3; j++) {
                $scope.bizStates[j].bizDetailList = [];   
                for (var i = 0; i <= 6; i++) {
                    $scope.bizStates[j].bizDetailList.push({});
                }
            }
            // setTimeout(function() {frzTable("divTable", "showTable", null);}, 100); 
        }
        initContent();
        
        $scope.exportReport = function(format) {
            $scope.fileType = format;
            $scope.fileName = "";
            $scope.fileName_btn = layer.open({
                type: 1,
                area: ['300px', '180px'],
                btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')],
                title: $translate.instant('global.fileName'),
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#templateReportName"),
                success: function(layero, index) {
                    $("#fileName").focus();
                },
                yes: function(index, layero) {
                    if ($scope.fileName == '' || angular.isUndefined($scope.fileName)) {
                        $scope.fileName = 'BizAnalysis';
                    }
                    $scope.getBizAnalysisFile();
                    layer.close($scope.fileName_btn);
                },
                no: function(index, layero) {
                    layer.close(index);
                }
            });


        }

        $("#fileName").keydown(function(event) {
            $scope.keycode = window.event ? event.keyCode : event.which;
            if ($scope.keycode == 13) {
                if ($scope.fileName == '' || angular.isUndefined($scope.fileName)) {
                    $scope.fileName = 'BizAnalysis';
                }
                $scope.getBizAnalysisFile();
                layer.close($scope.fileName_btn);
            }
        })
        $scope.getBizAnalysisFile = function() {
            OpsFiles.bizAnalysisFile({
                    dateFrom:$filter('date')($scope.filterDTO.dateFrom, 'yyyy-MM'),
                    dateTo:$filter('date')($scope.filterDTO.dateTo, 'yyyy-MM'),
                    fileType:$scope.fileType
            },$scope.bizStates, function(result) {
                $scope.exportExcelOrPdf(result);
            }, function(error) {
                layer.msg($translate.instant('stats.stat.noData'));
            });
        }

        $scope.exportExcelOrPdf = function(result) {
            if (angular.isDefined($scope.fileType) && $scope.fileType == 2) {
                var file = new Blob([result.data], {
                    type: 'application/vnd.ms-excel'
                });
                var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                var aTag = document.createElement("a");
                aTag.download = $scope.fileName + ".xlsx";
                aTag.href = blobURL;
                aTag.click();
            } else {
                    var file = new Blob([result.data], {
                        type: 'application/pdf'
                    });
                    var fileURL = URL.createObjectURL(file);
                    var previewDialog = layer.open({
                        type: 2,
                        area: ['1200px', '800px'],
                        maxmin: true,
                        title: $translate.instant("stats.stat.viewBizAnalysis"),
                        shade: 0.3,
                        moveType: 0,
                        shift: 0,
                        content: fileURL
                    })
                }
        }
    }
})();