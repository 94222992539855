
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsMapDetailController', WmsMapDetailController);

    WmsMapDetailController.$inject = ['$timeout', '$scope', '$rootScope', '$http', '$uibModalInstance', '$translate', '$uibModal',
    'entity', 'AccountBind', 'AddressBook', 'WmsWarehouse'];

    function WmsMapDetailController ($timeout, $scope, $rootScope, $http, $uibModalInstance, $translate, $uibModal,
        entity, AccountBind, AddressBook, WmsWarehouse) {
        var vm = this;

        vm.type = entity.type;
        vm.customExcelImportRecord = entity.customExcelImportRecord;
        vm.clear = clear;
        vm.save = save;
        vm.clientSelected = clientSelected;
        vm.loadAddressBooks = loadAddressBooks;
        vm.onAddressSelected = onAddressSelected;
        vm.getWarehouses = getWarehouses;
        vm.warehouseSelected = warehouseSelected;
        vm.wmsMaping = wmsMaping;
        vm.delOne = delOne;
        vm.accountBind = {};
        $timeout(function (){
            if(vm.type == "ALL"){
                vm.type = "FMS";
            }
            angular.element('.form-group:eq(1)>input').focus();
            AccountBind.getAll({type: vm.type}, function(result){
                vm.accountBinds = result;
            });
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            if(!vm.accountBind){
                return;
            }
            vm.accountBind.enabled = null;
            var accountBind = angular.copy(vm.accountBind);
            if(accountBind.autoChannelJson){
                accountBind.autoChannelJson = angular.toJson(accountBind.autoChannelJson);
            }
            vm.isSaving = true;
            if (accountBind.id !== null) {
                AccountBind.update(accountBind, onSaveSuccess, onSaveError);
            } else {
                AccountBind.save(accountBind, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.accountBind = result;
            if(vm.accountBind && vm.accountBind.autoChannelJson){
                vm.accountBind.autoChannelJson = angular.fromJson(vm.accountBind.autoChannelJson, true);
            }
            layer.msg($translate.instant('global.save_success'));
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function clientSelected (client) {
            vm.accountBind.venderId = client.id;
            vm.accountBind.userId = client.userId;
        }

        vm.addressBooks = [];
        function loadAddressBooks () {
            if (vm.addressBooks.length == 0 && !vm.isSaving) {
                vm.isSaving = true;
                AddressBook.getAll(function(result) {
                    vm.addressBooks = result;
                    vm.isSaving = false;
                });
            };
        };

        function onAddressSelected (data, fd) {
            vm.accountBind[fd] = data.id;
        }

        vm.wmswarehouses = [];
        function getWarehouses () {
            if(vm.wmswarehouses.length > 0){
                return;
            }
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: true
                }, function (data) {
                vm.wmswarehouses = data;
            });
        }

        function warehouseSelected (data) {
            vm.accountBind.warehouseId = data.id;
        }

        function wmsMaping () {
            if(!vm.accountBind || !vm.accountBind.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/wms-map/wms-map-dialog.html',
                controller: 'WmsMapDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsMap');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return angular.copy(vm.accountBind);
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }


        vm.choosedName;
        $scope._onAccountBindSelected = function (data) {
            vm.accountBind = angular.copy(data);
            if(vm.accountBind && vm.accountBind.autoChannelJson){
                vm.accountBind.autoChannelJson = angular.fromJson(vm.accountBind.autoChannelJson, true);
            }
        }
        $scope.choosedChange = function (oldname) {
            if(!oldname){
                vm.accountBind = {};
            }
        }
        $scope.beforeImport = function () {
            save();
        }

        function delOne () {
            if(!vm.accountBind || !vm.accountBind.id){
                vm.accountBind = {};
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/account-bind/account-bind-delete-dialog.html',
                controller: 'AccountBindDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return AccountBind.get({id : vm.accountBind.id}).$promise;
                    }]
                }
            }).result.then(function() {
                AccountBind.getAll(function(results){
                    vm.accountBinds = results;
                })
                vm.accountBind = {};
            }, function() {
            });
        }

        $scope.importOrdersByExcel = function (file) {
            if (!file) {return;}
            vm.accountBind.enabled = null;
            var accountBind = angular.copy(vm.accountBind);
            if(accountBind.autoChannelJson){
                accountBind.autoChannelJson = angular.toJson(accountBind.autoChannelJson);
            }
            vm.isSaving = true;
            if(accountBind.id !== null) {
                AccountBind.update(accountBind, function(result){
                    vm.accountBind = result;
                    if(vm.accountBind && vm.accountBind.autoChannelJson){
                        vm.accountBind.autoChannelJson = angular.fromJson(vm.accountBind.autoChannelJson, true);
                    }
                    vm.isSaving = false;
                    doExcelImport(file);
                }, onSaveError);
            } else {
                AccountBind.save(accountBind, function(result){
                    vm.accountBind = result;
                    if(vm.accountBind && vm.accountBind.autoChannelJson){
                        vm.accountBind.autoChannelJson = angular.fromJson(vm.accountBind.autoChannelJson, true);
                    }
                    vm.isSaving = false;
                    doExcelImport(file);
                }, onSaveError);
            }
        };

        function doExcelImport(file) {
            if(!vm.accountBind || !vm.accountBind.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            layer.msg("Parsing...", {
                shade: 0.3,
                time: 2000000
            });
            var url = "api/guide-import/importOrdersByExcel";
            if(vm.type == "EMS" || vm.type == "TMS"){
                // 自定义导入
                if (vm.customExcelImportRecord) {
                    $rootScope.importOrdersByCustomize(vm.customExcelImportRecord, file, null, null, vm.accountBind.id);
                    return;
                }
                url = 'api/express-parcels/importOrdersByExcel';
            }
            vm.isSaving = true;
            var fd = new FormData();
            fd.append('file', file);
            fd.append('isExport', $rootScope.isImportExcel?false:true);
            fd.append('accountBindId', vm.accountBind.id);
            $http.post(url, fd, {
                    transformRequest: angular.identity,
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    layer.closeAll();
                    vm.isSaving = false;
                    var result = new Object();
                    result['data'] = data;
                    $rootScope.DOWNLOADTXT(result, "importResult.txt");
                    $scope.$emit('autopsApp:loadAllRequred');
                    $uibModalInstance.close();
                }).error(function(error) {
                    layer.closeAll();
                    vm.isSaving = false;
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                });

        }


        $scope.bindedAccounts = [];
        $scope.getAllAccounts = function() {
            if($rootScope.account.companyId && $rootScope.HAVENOAUTH('AU_LABEL_ACCOUNT')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_LABEL_ACCOUNT');
                return;
            }
            if ($scope.bindedAccounts.length == 0 && !$scope.isSaving) {
                $scope.isSaving = true;
                AddressBook.getAllAccounts({includeChargeChannel: true}, function(result) {
                    $scope.bindedAccounts = result;
                    $scope.isSaving = false;
                });
            };
        };

        $scope.autoChannelEnable = function() {
            if(!vm.accountBind){
                return;
            }
            vm.accountBind.autoChannel = !vm.accountBind.autoChannel;
            if(!vm.accountBind.autoChannel){
                vm.accountBind.autoChannelJson = [];
            }
            else{
                save();
            }
        }

        $scope.shipServiceSelected = function(item) {
            if(!vm.accountBind.autoChannelJson){
                vm.accountBind.autoChannelJson = [];
            }
            var isNew = true;
            angular.forEach(vm.accountBind.autoChannelJson, function(data){
                if(data.id){
                    if(item.id == data.id){
                        isNew = false;
                    }
                }
                else if(item.code == data.code){
                    isNew = false;
                }
            })
            if(isNew){
                var service = {
                    "id": item.id,
                    "code": item.code
                }
                vm.accountBind.autoChannelJson.push(service);
            }
            vm.accountBind.channelCode=null;
        }

        $scope.delService = function(index) {
            vm.accountBind.autoChannelJson.splice(index, 1);
        };

        // $scope.autoCorrectChange = function() {
        //     if(!vm.accountBind){
        //         return;
        //     }
        //     vm.accountBind.autoCorrect = !vm.accountBind.autoCorrect;
        // }


    }
})();
