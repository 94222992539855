(function() {
	'use strict';

	angular
		.module('autopsApp')
		.controller('ComStatsController', ComStatsController);

	ComStatsController.$inject = ['$scope', '$rootScope', '$translate', '$state', '$uibModal', '$timeout', 'Principal', 
	'OpsFiles', 'uiGridConstants', 'uiGridGroupingConstants', 'GridState', 'DateUtils', 'DataUtils', 'AsyncTask'];

	function ComStatsController($scope, $rootScope, $translate, $state, $uibModal, $timeout, Principal,
		OpsFiles, uiGridConstants, uiGridGroupingConstants, GridState, DateUtils, DataUtils, AsyncTask) {		

		$scope.statParams = {};
        $scope.statParams.statType = "month";
        $scope.statParams.month = new Date();
        $scope.statParams.filterRequired = true;

		$scope.datePickerOpenStatus = {};
		$scope.yearFormat = "yyyy";
		$scope.monthFormat = "yyyy-MM";
		$scope.dayFormat = "yyyy-MM-dd";
		$scope.yearDatepickerOptions = {
			minMode: 'year'
		}
		$scope.monthDatepickerOptions = {
			minMode: 'month'
		}
		$scope.dayDatepickerOptions = {
			minMode: 'day'
		}
		$scope.openCalendar = function(date) {
			$scope.datePickerOpenStatus[date] = true;
		}
		
		$scope.isSaving = false;
		$scope.reportavailalbe = false;
		$scope.getContents = function() {           
			DataUtils.updateStatParams($scope.statParams);
			if(!$scope.statParams.etdFM || !$scope.statParams.etdTO){
				layer.msg($translate.instant('stats.stat.dateNullAlert'));				
				return;
			}
            $scope.isSaving = true;
            var loadIndex = layer.load(1, {shade: 0.3});  
            $scope.statParams.timezoneOffset = new Date().getTimezoneOffset()/60;
            $scope.reportavailalbe = false;
			OpsFiles.comStats($scope.statParams, function(result) {
				$scope.isSaving = false;
                layer.close(loadIndex);  
				if (!result || result.length == 0) {
					layer.msg($translate.instant('global.messages.resultNull'));
				}
                $scope.gridOptions.data = result;   
                resize();           
                initContent();
			}, function(response) {
				$scope.isSaving = false;
                $rootScope.ALERT_ERROR(response);
			});
		}

        window.onresize = function(){
            resize();
        }

        function resize() {
            var tabHeight = $scope.gridOptions.data.length * 30 + 190;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 680){
                tabHeight = 680;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }
        var colExist = function(field) {
            var colExist = false;
            angular.forEach($scope.gridOptions.columnDefs, function(col){
                if(field == col.field){
                    colExist = true;
                }
            })
            return colExist;
        }
        
        var initContent = function() {
            var curList;
            var profit = 0;
            var rev = 0;
            angular.forEach($scope.gridOptions.data, function(data){
				data.gw = data.gw?(data.gw.toFixed(2) -0):null;
				data.vol = data.vol?(data.vol.toFixed(3) -0):null;
				data.chargeWeight = data.chargeWeight?(data.chargeWeight.toFixed(2) -0):null;
                data.salesName = data.salesName?data.salesName.replace(/[ ]/g,""):"NULL";
                data.opName = data.opName?data.opName.replace(/[ ]/g,""):"NULL";
                data.docName = data.docName?data.docName.replace(/[ ]/g,""):"NULL";
                data.finName = data.finName?data.finName.replace(/[ ]/g,""):"NULL";
                data.checkByName = data.checkByName?data.checkByName.replace(/[ ]/g,""):"NULL";
                data.bookingBy = data.bookingBy?data.bookingBy.replace(/[ ]/g,""):"NULL";
                data.customBy = data.customBy?data.customBy.replace(/[ ]/g,""):"NULL";
                data.cusName = data.cusName?data.cusName.replace(/[ ]/g,""):"NULL";
                data.truckBy = data.truckBy?data.truckBy.replace(/[ ]/g,""):"NULL";
                if(data.polRegion){
                    data.polRegion = $translate.instant('global.lanes.' + data.polRegion);
                }
                if(data.podRegion){
                    data.podRegion = $translate.instant('global.lanes.' + data.podRegion);
                }           
                if(!curList){
                    curList = data.currencys;
                }     
                if(data.profitRMB){
                    profit += data.profitRMB;
                }
                if(data.totalRMB){
                    rev += data.totalRMB;
                }
            })
            if(rev){
                $scope.avgGp = DataUtils.round(profit/rev*100, 2) + "%";
            }
            else {
                $scope.avgGp = "N/A";
            }
            if($scope.statParams.showOrgCur){
                $scope.gridOptions.columnDefs = angular.copy(columnDefsCopy);
                angular.forEach(curList, function(cur){
                    if(!colExist("rev" + cur)){
                        $scope.gridOptions.columnDefs.push({
                            field: "rev" + cur, 
                            minWidth:120,
                            type:'number',
                            treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                            aggregationType: uiGridConstants.aggregationTypes.sum,
                            filters: [
                                {
                                  condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                                  placeholder: ' >= '
                                },
                                {
                                  condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                                  placeholder: ' <= '
                                }
                            ],
                            displayName: "Rev(" + cur + ")"
                        })
                    }
                })
                angular.forEach($scope.gridOptions.data, function(data){
                    for(var i =0; i < curList.length; i++){
                        data["rev" + curList[i]] = data.currencyRevList[i].totalB;
                    }                
                })
                angular.forEach(curList, function(cur){
                    if(!colExist("exp" + cur)){
                        $scope.gridOptions.columnDefs.push({
                            field: "exp" + cur, 
                            minWidth:120,
                            type:'number',
                            treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                            aggregationType: uiGridConstants.aggregationTypes.sum,
                            filters: [
                                {
                                  condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                                  placeholder: ' >= '
                                },
                                {
                                  condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                                  placeholder: ' <= '
                                }
                            ],
                            displayName: "Exp(" + cur + ")"
                        })
                    }
                })
                angular.forEach($scope.gridOptions.data, function(data){
                    for(var i =0; i < curList.length; i++){
                        data["exp" + curList[i]] = data.currencyExpList[i].totalB;
                    }                
                })
                angular.forEach(curList, function(cur){
                    if(!colExist("gp" + cur)){
                        $scope.gridOptions.columnDefs.push({
                            field: "gp" + cur, 
                            minWidth:120,
                            type:'number',
                            treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                            aggregationType: uiGridConstants.aggregationTypes.sum,
                            filters: [
                                {
                                  condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                                  placeholder: ' >= '
                                },
                                {
                                  condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                                  placeholder: ' <= '
                                }
                            ],
                            displayName: "GP(" + cur + ")"
                        })
                    }
                })
                angular.forEach($scope.gridOptions.data, function(data){
                    for(var i =0; i < curList.length; i++){
                        data["gp" + curList[i]] = data.currencyGpList[i].totalB;
                    }
                })

                angular.forEach(curList, function(cur){
                    if(!colExist("sp" + cur)){
                        $scope.gridOptions.columnDefs.push({
                            field: "sp" + cur, 
                            minWidth:120,
                            type:'number',
                            treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                            aggregationType: uiGridConstants.aggregationTypes.sum,
                            filters: [
                                {
                                  condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                                  placeholder: ' >= '
                                },
                                {
                                  condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                                  placeholder: ' <= '
                                }
                            ],
                            displayName: "SP(" + cur + ")"
                        })
                    }
                })
                angular.forEach($scope.gridOptions.data, function(data){
                    for(var i =0; i < curList.length; i++){
                        data["sp" + curList[i]] = data.currencySpList[i].totalB;
                    }
                })
            }

            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
            setTimeout(function(){
                getCtnTypeStr();
            }, 1000);
        }

        $scope.ctnTypeStr = "";
        var getCtnTypeStr = function() {
            $scope.ctnTypeStr = "";
            var ctnTypes = new Set();
            var lclCount = 0;
            var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
            var profit = 0;
            var rev = 0;
            angular.forEach(allvisiblerows, function(data){
                if(data.entity.profitRMB){
                    profit += data.entity.profitRMB;
                }
                if(data.entity.totalRMB){
                    rev += data.entity.totalRMB;
                }
                if(!data.entity.isVoid && !data.entity.localTimeTwo && (!data.entity.ctnTypeNum || data.entity.ctnTypeNum.indexOf('X') == -1)){
                    lclCount++;
                }
                if(!data.entity.isVoid && !data.entity.localTimeTwo && data.entity.ctnTypeNum){
                    var volumes = data.entity.ctnTypeNum.split(' ');
                    angular.forEach(volumes, function(volume){
                        if (volume){
                            var teus = volume.split('X');
                            if(teus.length == 2){
                                ctnTypes.add(teus[1]);
                            }
                        }
                    })
                }
            })
            if(rev){
                $scope.avgGp = DataUtils.round(profit/rev*100, 2) + "%";
            }
            else {
                $scope.avgGp = "N/A";
            }

            var amt = 0;
            ctnTypes.forEach(function(ctnType){
                amt = 0;
                angular.forEach(allvisiblerows, function(data){
                    if(!data.entity.isVoid && !data.entity.localTimeTwo && data.entity.ctnTypeNum){
                        var volumes = data.entity.ctnTypeNum.split(' ');
                        angular.forEach(volumes, function(volume){
                            if (volume){
                                var teus = volume.split('X');
                                if(teus.length == 2 && teus[1] == ctnType){
                                    amt += parseInt(teus[0]);
                                }
                            }
                        })
                    }
                })
                $scope.ctnTypeStr += amt + "X" + ctnType + " ";
            })
            if(lclCount){
                $scope.ctnTypeStr += lclCount + "XLCL";
            }
            $translate.refresh();
        }
        function getTitleStr() {
            var outStr = $translate.instant('global.menu.admin.profitStats');
            outStr += "(" + DateUtils.convertLocalDateToServer($scope.statParams.etdFM) 
                + " - " 
                + DateUtils.convertLocalDateToServer($scope.statParams.etdTO)
                + ")";
            return outStr;
        }
        var baseCur = $rootScope.account.baseCurrency?$rootScope.account.baseCurrency:"RMB";
        var statsPageSize = 5000;
        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: false,   

            enableGridMenu : true,//表格 菜单  
            showGridFooter: true,//表格的footer  
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤            

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            enableRowHeaderSelection : true,  //行选中
            enableRowSelection: true, //行选中
            enableSelectAll: true, //全部选中
            enableFullRowSelection : false, //行任意位置选中
            multiSelect: true,//多行选中

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: $translate.instant('global.menu.admin.profitStats') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],       

            paginationPageSizes: [1000, 5000, 10000, 15000, 20000],
            paginationPageSize: statsPageSize,
            useExternalPagination: false,

            minimumColumnSize: 70,
            gridFooterTemplate:'<div class="ui-grid-footer-info ui-grid-grid-footer"><span translate="stats.stat.ttlRows">ttlRows</span>: <strong>{{grid.appScope.gridOptions.data.length}}</strong><span ng-if="grid.selection.selectedCount" class="ml-4">(<span translate="stats.stat.choosedRows">choosedRows</span>: {{grid.selection.selectedCount}})</span><span ng-if="grid.selection.selectedCount"><button type="button" class="btn btn-default btn-xs ml-10 mr-5" ng-click="grid.appScope.delAllChecked()"><span class="glyphicon glyphicon-remove-circle"></span><span class="ml-4" translate="entity.action.delAllChecked">delAllChecked</span></button><button type="button" class="btn btn-default btn-xs" ng-click="grid.appScope.delAllUnChecked()"><span class="glyphicon glyphicon-remove-circle"></span><span class="ml-4" translate="entity.action.delAllUnChecked">delAllUnChecked</span></button></span></div>',
            columnDefs: [
                { field: 'jobNum', 
                    width: 180,
                    displayName: $translate.instant('stats.stat.jobNum'),
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<span class="ml-5"><a href=""ng-click="grid.appScope.VIEWGE(row.entity, grid.appScope.gridOptions.data)">{{row.entity.jobNum}}&nbsp;</a><span class="glyphicon glyphicon-ok-circle"ng-if="row.entity.tkState == \'3\'"></span></span>'},
                { field: 'hmMark',
                    displayName: "M/H",
                    visible:false,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.HM_TYPES
                    },
                    minWidth:60},
                { field: 'mblNum', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.mblNum')},
                { field: 'isExport', 
                    displayName: $translate.instant('stats.stat.expImp'), 
                    minWidth:120,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [
                        { value: true, label: $translate.instant('autopsApp.guide.home.isExport')},
                        { value: false, label: $translate.instant('autopsApp.guide.home.isImport')}]
                    },
                    cellTemplate: '<span class="ml-5" ng-show="row.entity.isExport != null" translate="{{row.entity.isExport?\'stats.stat.isExport\':\'stats.stat.isImport\'}}"></span>'},
                { field: 'salesName', 
                    displayName: $translate.instant('stats.stat.filterBySales'), 
                    minWidth:120},
                { field: 'customerCode', 
                    displayName: $translate.instant('stats.stat.customerCode'), 
                    minWidth:120},
                { field: 'clientName', 
                    displayName: $translate.instant('stats.stat.filterByClient'), 
                    minWidth:120},
                { field: 'totalRMB', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.totalRMB', {currency: baseCur}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'uncollectedRMB', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.uncollectedRMB', {currency: baseCur}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'ctnsLoadedTime', 
                    displayName: $translate.instant('stats.stat.ctnsLoadedTime'), 
                    minWidth:120},
                { field: 'ctnAvailableTime', 
                    displayName: $translate.instant('stats.stat.ctnAvailableTime'), 
                    minWidth:120},
                { field: 'transferOutTime', 
                    displayName: $translate.instant('stats.stat.transferOutTime'), 
                    minWidth:120},
                { field: 'ctnReturnTime', 
                    displayName: $translate.instant('stats.stat.ctnReturnTime'), 
                    minWidth:120},
                { field: 'dnSentTime', 
                    displayName: $translate.instant('stats.stat.billed'), 
                    minWidth:120},
                { field: 'allBilled', 
                    displayName: $translate.instant('stats.stat.locked'), 
                    minWidth:80,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.allBilled"class="label label-success"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.allBilled"class="label label-info"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'invoiceIssuedTime', 
                    displayName: $translate.instant('stats.stat.invoiceIssued'), 
                    minWidth:120},
                { field: 'invoiceReceivedTime', 
                    displayName: $translate.instant('stats.stat.invoiceRcvd'), 
                    minWidth:120},
                { field: 'paymentRcvDate', 
                    displayName: $translate.instant('stats.stat.paymentRcvDate'), 
                    minWidth:120},
                { field: 'paymentPaidDate', 
                    displayName: $translate.instant('stats.stat.paymentPaidDate'), 
                    minWidth:120},
                { field: 'costRmbTtl', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.costRmbTtl', {currency: baseCur}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'profitRMB', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.profitRMB', {currency: baseCur}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'actRev', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.actRev', {currency: baseCur}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'actExp', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.actExp', {currency: baseCur}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'unPaidRMB', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.unPaidRMB', {currency: baseCur}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'actAdvanced', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.actAdvanced', {currency: baseCur}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'gp', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.gp'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<span class="grid-text" ng-if="row.entity.gp">{{row.entity.gp}}%</span>',
                    footerCellTemplate: '<div class="grid-text" ng-show="grid.appScope.avgGp">Avg: {{grid.appScope.avgGp}}</div>',
                    minWidth:100},
                { field: 'assistExp', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.assistExp', {currency: baseCur}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'assistExpProfit', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.assistExpProfit', {currency: baseCur}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'assistExpGp', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.assistExpGp'), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.AVG,
                    aggregationType: uiGridConstants.aggregationTypes.avg,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'ctnTypeNum', 
                    displayName: $translate.instant('stats.stat.ctnQuantityStr'), 
                    minWidth:120,
                    footerCellTemplate: '<div >{{grid.appScope.ctnTypeStr}}</div>'},
                { field: 'teus', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.teu'), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'pkgs', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.pkgs'), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'gw', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.gw'), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'vol', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.vol'), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'chargeWeight', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.chargeWeight'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'chargeWeightKg', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.chargeWeight') + "(KG)", 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'chargeWeightCbm', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.chargeWeight') + "(CBM)", 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'soNum', 
                    displayName: $translate.instant('stats.stat.soNum'), 
                    minWidth:120},
                { field: 'clientSoNum', 
                    displayName: $translate.instant('stats.stat.clientSoNum'), 
                    minWidth:120},
                { field: 'hblNum', 
                    displayName: $translate.instant('stats.stat.hblNum'), 
                    minWidth:120},
                { field: 'poNum', 
                    displayName: $translate.instant('stats.stat.poNum'), 
                    minWidth:120},
                { field: 'ctnNumStr', 
                    displayName: $translate.instant('stats.stat.ctnNumsStr'), 
                    minWidth:120},       
                { field: 'clientRole',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.clientRole')},       
                { field: 'clientSource',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.clientSource')},
                { field: 'creditType',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.creditType')},
                { field: 'creditRating',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.creditRating')},                    
                { field: 'clientCode',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.clientCode')},
                { field: 'clientIdCode',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.clientIdCode')},
                { field: 'clientKcNum',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.clientKcNum')},
                { field: 'latestPaymentDate',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.latestPaymentDate')},  
                { field: 'paymentAdvancedDays', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.paymentAdvancedDays'), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.AVG,
                    aggregationType: uiGridConstants.aggregationTypes.avg,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'shipper', 
                    displayName: $translate.instant('stats.stat.filterByShiperCnee'), 
                    minWidth:120},
                { field: 'cneeName', 
                    displayName: $translate.instant('stats.stat.cneeName'), 
                    minWidth:120},
                { field: 'bookingToName', 
                    displayName: $translate.instant('stats.stat.filterByColoader'), 
                    minWidth:120},
                { field: 'overseaAgentName', 
                    displayName: $translate.instant('stats.stat.filterByAgent'), 
                    minWidth:120},
                { field: 'mblCnee', 
                    displayName: $translate.instant('stats.stat.cneeName') + "(MBL)", 
                    minWidth:120},
                { field: 'branch', 
                    displayName: $translate.instant('stats.stat.bbranch'), 
                    minWidth:120},
                { field: 'department', 
                    displayName: $translate.instant('stats.stat.bteam'), 
                    minWidth:120},
                { field: 'opName', 
                    displayName: $translate.instant('stats.stat.filterByOperator'), 
                    minWidth:120},
                { field: 'cusName', 
                    displayName: $translate.instant('stats.stat.filterByCs'), 
                    minWidth:120},
                { field: 'docName', 
                    displayName: $translate.instant('stats.stat.filterByDocument'), 
                    minWidth:120},
                { field: 'finName', 
                    displayName: $translate.instant('stats.stat.filterByAccount'), 
                    minWidth:120},
                { field: 'overseasCs', 
                    displayName: $translate.instant('stats.stat.filterByOverSeasCs'), 
                    minWidth:120},
                { field: 'checkByName', 
                    displayName: $translate.instant('stats.stat.filterByMarket'), 
                    minWidth:120},
                { field: 'bookingBy', 
                    displayName: $translate.instant('stats.stat.bookingBy'), 
                    minWidth:120},
                { field: 'truckBy', 
                    displayName: $translate.instant('stats.stat.truckBy'), 
                    minWidth:120},
                { field: 'customBy', 
                    displayName: $translate.instant('stats.stat.customBy'), 
                    minWidth:120},
                { field: 'bizType', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.bizType')},
                { field: 'internalType', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.internalType')},                    
                { field: 'shipmentType', 
                    displayName: $translate.instant('stats.stat.shipmentType'), 
                    minWidth:120},
                { field: 'porName', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.porName')},
                { field: 'polName', 
                    displayName: $translate.instant('stats.stat.polName'), 
                    minWidth:120},
                { field: 'podName', 
                    displayName: $translate.instant('stats.stat.podName'), 
                    minWidth:120},
                { field: 'destName',
                    displayName: $translate.instant('stats.stat.destName'), 
                    minWidth:120},       
                { field: 'clearanceAgent',
                    displayName: $translate.instant('stats.stat.clearanceAgent'), 
                    minWidth:120},
                { field: 'deliveryToCode',
                    displayName: $translate.instant('stats.stat.deliveryto') + "(Code)", 
                    minWidth:120},
                { field: 'deliveryToName',
                    displayName: $translate.instant('stats.stat.deliveryto'), 
                    minWidth:120},
                { field: 'destCountryCode',
                    displayName: $translate.instant('stats.stat.destCountryCode'), 
                    minWidth:120},                    
                { field: 'opPlace',
                    displayName: $translate.instant('stats.stat.opPlace'), 
                    minWidth:120},
                { field: 'dock',
                    displayName: $translate.instant('autopsApp.guide.areaOfCall'), 
                    minWidth:120},
                { field: 'createTime', 
                    displayName: $translate.instant('stats.stat.createTime'), 
                    minWidth:120},
                { field: 'bizDate', 
                    minWidth:120, 
                    displayName: $translate.instant('stats.stat.bizDate')}, 
                { field: 'bookingEtd', 
                    displayName: $translate.instant('stats.stat.bookingEtd'), 
                    minWidth:120},           
                { field: 'etd', 
                    displayName: $translate.instant('stats.stat.guideRates.etd'), 
                    minWidth:120},
                { field: 'eta', 
                    displayName: $translate.instant('stats.stat.guideRates.eta'), 
                    minWidth:120},
                { field: 'atd', 
                    displayName: $translate.instant('stats.stat.atd'), 
                    minWidth:120},
                { field: 'ata', 
                    displayName: $translate.instant('stats.stat.ata'), 
                    minWidth:120},
                { field: 'vsl', 
                    displayName: $translate.instant('stats.stat.vsl'), 
                    minWidth:120},
                { field: 'voy', 
                    displayName: $translate.instant('stats.stat.voy'), 
                    minWidth:120},
                { field: 'carrier', 
                    displayName: $translate.instant('stats.stat.bcarrier'), 
                    minWidth:120},
                { field: 'deliveryChannel', 
                    displayName: $translate.instant('stats.stat.deliveryChannel'), 
                    minWidth:120},
                { field: 'polRegion', 
                    displayName: $translate.instant('stats.stat.polRegion'), 
                    minWidth:120},
                { field: 'podRegion', 
                    displayName: $translate.instant('stats.stat.podRegion'), 
                    minWidth:120},
                { field: 'lane', 
                    displayName: $translate.instant('stats.stat.lane'), 
                    minWidth:120},
                { field: 'contractNum', 
                    displayName: $translate.instant('stats.stat.contractNum'), 
                    minWidth:120},
                { field: 'cargoName', 
                    displayName: $translate.instant('stats.stat.cargoName'), 
                    minWidth:120},
                { field: 'cargoType', 
                    displayName: $translate.instant('stats.stat.cargoType'), 
                    minWidth:120},
                { field: 'tradeTerm', 
                    displayName: $translate.instant('stats.stat.tradeTerm'), 
                    minWidth:120},
                { field: 'ctn20', 
                    type:'number',
                    displayName: "20'", 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'ctn40', 
                    type:'number',
                    displayName: "40'", 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'ctn40HQ', 
                    type:'number',
                    displayName: "40HQ", 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'ctn45', 
                    type:'number',
                    displayName: "45'", 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'remarks', 
                    displayName: $translate.instant('stats.stat.remarks'), 
                    minWidth:120},
                { field: 'shipperName', 
                    displayName: $translate.instant('stats.stat.blShipper'), 
                    minWidth:120},
                { field: 'deliveryWay', 
                    displayName: $translate.instant('stats.stat.deliveryWay'), 
                    minWidth:120},
                { field: 'cargoReadyDate', 
                    displayName: $translate.instant('stats.stat.cargoReadyDate'), 
                    minWidth:120},
                { field: 'wmsInTime', 
                    displayName: $translate.instant('stats.stat.wmsInTime'), 
                    minWidth:120},                   
                { field: 'wmsOutTime', 
                    displayName: $translate.instant('stats.stat.wmsOutTime'), 
                    minWidth:120},        
                { field: 'customReleaseTime', 
                    displayName: $translate.instant('stats.stat.isExport') + $translate.instant('stats.stat.customsRelease'), 
                    minWidth:120},        
                { field: 'customClearTime', 
                    displayName: $translate.instant('stats.stat.isImport') + $translate.instant('stats.stat.customsRelease'), 
                    minWidth:120},
                { field: 'deliveryDate', 
                    displayName: $translate.instant('stats.stat.deliveryDate'), 
                    minWidth:120},  
                { field: 'deliveryAppointmentTime', 
                    displayName: $translate.instant('stats.stat.deliveryAppointmentTime'), 
                    minWidth:120},
                { field: 'deliveryTime', 
                    displayName: $translate.instant('stats.stat.deliveryTime'), 
                    minWidth:120},
                { field: 'productName', 
                    displayName: $translate.instant('stats.stat.productName'), 
                    minWidth:120},
                { field: 'customsInspection', 
                    displayName: $translate.instant('stats.stat.customsInspection'), 
                    minWidth:80,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.customsInspection"class="label label-success"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.customsInspection"class="label label-info"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'customsInspectionExp', 
                    displayName: $translate.instant('stats.stat.customsInspectionExp'), 
                    minWidth:80,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.customsInspectionExp"class="label label-success"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.customsInspectionExp"class="label label-info"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'shiptoPostcode', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.shiptoPostcode')},       
                    { field: 'isResidential', 
                    minWidth:120,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.isResidential"class="label label-success"><span translate="global.yes_answer">yes_answer</span></span><span ng-if="!row.entity.isResidential"class="label label-info"><span translate="global.no_answer">no_answer</span></span></span>',
                    displayName: $translate.instant('stats.stat.isResidential')},                    
                { field: 'isRemote', 
                    minWidth:120,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.isRemote"class="label label-success"><span translate="global.yes_answer">yes_answer</span></span><span ng-if="!row.entity.isRemote"class="label label-info"><span translate="global.no_answer">no_answer</span></span></span>',
                    displayName: $translate.instant('stats.stat.isRemote')},
                { field: 'superRemote', 
                    minWidth:120,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.superRemote"class="label label-success"><span translate="global.yes_answer">yes_answer</span></span><span ng-if="!row.entity.superRemote"class="label label-info"><span translate="global.no_answer">no_answer</span></span></span>',
                    displayName: $translate.instant('stats.stat.superRemote')},
                { field: 'superRemote2', 
                    minWidth:120,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.superRemote2"class="label label-success"><span translate="global.yes_answer">yes_answer</span></span><span ng-if="!row.entity.superRemote2"class="label label-info"><span translate="global.no_answer">no_answer</span></span></span>',
                    displayName: $translate.instant('stats.stat.superRemote') + "(2)"},
                { field: 'superRemote3', 
                    minWidth:120,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.superRemote3"class="label label-success"><span translate="global.yes_answer">yes_answer</span></span><span ng-if="!row.entity.superRemote3"class="label label-info"><span translate="global.no_answer">no_answer</span></span></span>',
                    displayName: $translate.instant('stats.stat.superRemote') + "(3)"},             
                { field: 'mjobNum', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.mjobNum')},
                { field: 'shipmentId', 
                    minWidth:120,
                    displayName: "Shipment ID"},
                { field: 'fbaRef', 
                    minWidth:120,
                    displayName: "Reference ID"},
                { field: 'refNum', 
                    minWidth:120,
                    displayName: "EDI Ref#"},
                { field: 'thirdpartyPlatformRef', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.thirdpartyPlatformRef')},  
                { field: 'cbMark',
                    displayName: $translate.instant('autopsApp.guide.cbMark'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.cbMark"class="label label-primary"><span translate="global.yes_answer">isExport</span></span><span ng-hide="row.entity.cbMark"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'cbNo',
                    displayName: $translate.instant('autopsApp.guide.cbNo'),
                    minWidth:120},
                { field: 'whName', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.whName')},
                { field: 'year', 
                    minWidth:120,
                    displayName: "Year"},
                { field: 'yearMonth', 
                    minWidth:120,
                    displayName: "Month"},
                { field: 'yearWeek', 
                    minWidth:120,
                    displayName: "Week"},
                { field: 'companyAbb', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.companyAbb')},
                { field: 'deliveryBySelf', 
                    displayName: $translate.instant('stats.stat.deliveryBySelf'), 
                    minWidth:80,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.deliveryBySelf"class="label label-success"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.deliveryBySelf"class="label label-info"><span translate="global.no_answer">no_answer</span></span></span>'},
                
                { field: 'directDelivery', 
                    displayName: $translate.instant('stats.stat.directDelivery'), 
                    minWidth:80,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.directDelivery"class="label label-success"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.directDelivery"class="label label-info"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'closeDate', 
                    minWidth:120,
                    displayName:  $translate.instant('stats.stat.closeDate')},
                { field: 'isClosed', 
                    displayName: $translate.instant('stats.stat.isClosed'), 
                    minWidth:80,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.isClosed"class="label label-success"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.isClosed"class="label label-info"><span translate="global.no_answer">no_answer</span></span></span>'},
                 { field: 'revLock',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    displayName: $translate.instant('autopsApp.guide.revLock'),
                    cellTemplate: '<span ng-class="row.entity.revLock?\'label-primary\':\' label-danger\'"translate="{{row.entity.revLock?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"></span>'},
                 { field: 'expLock',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    displayName: $translate.instant('autopsApp.guide.expLock'),
                    cellTemplate: '<span ng-class="row.entity.expLock?\'label-primary\':\' label-danger\'"translate="{{row.entity.expLock?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"</span>'},
                { field: 'ratesLocked', 
                    displayName: $translate.instant('stats.stat.ratesLocked'), 
                    minWidth:80,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.ratesLocked"class="label label-success"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.ratesLocked"class="label label-info"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'mblOnly', 
                    displayName: $translate.instant('stats.stat.mblOnly'), 
                    minWidth:80,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.mblOnly"class="label label-success"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.mblOnly"class="label label-info"><span translate="global.no_answer">no_answer</span></span></span>'}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    $scope.saveSate();
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if($scope.choosedOne.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson($scope.choosedOne.gridState).gridState);
                    }     
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);                   
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = getTitleStr();
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "comStats";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);                   
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = getTitleStr();
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "comStats";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }, {
                title: $translate.instant('entity.action.exportSelExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = getTitleStr();
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = $scope.gridApi.selection.getSelectedRows();
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "comStats";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 5
            }, {
                title: $translate.instant('entity.action.delSelected'),
                action: function($event) {
                    for(var i = $scope.gridOptions.data.length - 1; i >= 0; i--){
                        var row = $scope.gridApi.grid.getRow($scope.gridOptions.data[i]);
                        if(row.isSelected){
                            $scope.gridOptions.data.splice(i, 1);
                        }
                    }
                    $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );          
                },
                order: 6
            }, {
                title: $translate.instant('entity.action.delNotSelected'),
                action: function($event) {
                    for(var i = $scope.gridOptions.data.length - 1; i >= 0; i--){
                        var row = $scope.gridApi.grid.getRow($scope.gridOptions.data[i]);
                        if(!row.isSelected){
                            $scope.gridOptions.data.splice(i, 1);
                        }
                    }
                    $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );                    
                },
                order: 7
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi; 
                $scope.gridApi.treeBase.on.rowExpanded($scope,function(row) {
                	resize();
                });
                gridApi.core.on.filterChanged($scope, function() {
                    clearTimeout(filterTimer);
                    filterTimer = setTimeout(function(){
                        getCtnTypeStr();
                    }, 1000);//查询延时时间，单位ms
                });
            }
        }
        var filterTimer;
        var removeCurCol = function(field) {
            if(!columnDefsCopy || !columnDefsCopy.length){
                return;
            }
            for(var i = columnDefsCopy.length - 1; i >= 0; i--){
                if(columnDefsCopy[i].field && columnDefsCopy[i].field.indexOf('gp') == 0 && columnDefsCopy[i].field.length == 5){
                    columnDefsCopy.splice(i, 1);
                }
                else if(columnDefsCopy[i].field && columnDefsCopy[i].field.indexOf('rev') == 0 && columnDefsCopy[i].field.length == 6){
                    columnDefsCopy.splice(i, 1);
                }
                else if(columnDefsCopy[i].field && columnDefsCopy[i].field.indexOf('exp') == 0 && columnDefsCopy[i].field.length == 6){
                    columnDefsCopy.splice(i, 1);
                }
                else if(columnDefsCopy[i].field && columnDefsCopy[i].field.indexOf('sp') == 0 && columnDefsCopy[i].field.length == 5){
                    columnDefsCopy.splice(i, 1);
                }
            }
        }

        var columnDefsCopy = angular.copy($scope.gridOptions.columnDefs);
        removeCurCol();
        $scope.gridStateList = [];
        var gridStateCopy = null;
        $scope.choosedOne = {};
        $timeout(function (){
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridStateList({
                userId: $rootScope.account.id,
                gridId: 66
            }, function(result){
                $scope.gridStateList = result;     
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);           
            })
        });

        $scope.saveSate = function() {
            if(!$scope.choosedOne.stateName){
                layer.msg($translate.instant('stats.stat.statsNameNull'));
                return;
            }            
            var gridStateAndStats = {};
            gridStateAndStats.gridState = $scope.gridApi.saveState.save();
            gridStateAndStats.stats = angular.copy($scope.statParams);

			if (gridStateAndStats.stats.statType == "irregular") {
				gridStateAndStats.stats.etdFM = DateUtils.convertLocalDateToServer(gridStateAndStats.stats.etdFM);
				gridStateAndStats.stats.etdTO= DateUtils.convertLocalDateToServer(gridStateAndStats.stats.etdTO);
			}
			if (gridStateAndStats.stats.statType == "year") {
				gridStateAndStats.stats.year= DateUtils.convertLocalDateToServer(gridStateAndStats.stats.year);
			}
			if (gridStateAndStats.stats.statType == "month") {
				gridStateAndStats.stats.month= DateUtils.convertLocalDateToServer(gridStateAndStats.stats.month);
			}
			if (gridStateAndStats.stats.statType == "day") {
				gridStateAndStats.stats.day= DateUtils.convertLocalDateToServer(gridStateAndStats.stats.day);
			}

            $scope.choosedOne.gridState = angular.toJson(gridStateAndStats);
            $scope.choosedOne.userId = $rootScope.account.id;
            $scope.choosedOne.gridId = 66;

            GridState.update($scope.choosedOne, function(result){
                if(!$scope.choosedOne.id){
                    $scope.gridStateList.push(result);
                }
                angular.copy(result, $scope.choosedOne);
                layer.msg($translate.instant('global.save_success'));
            }, function(error){
                layer.msg($translate.instant('global.save_fail'));
            });
        }


        $scope.selectSate = function(gridState) {
        	if(gridState.id == $scope.choosedOne.id){
        		return;
        	}
            if(!gridState || !gridState.id){
                $scope.gridApi.saveState.restore($scope, gridStateCopy);
                return;
            }
            $scope.choosedOne = gridState;
            var jsonObj = angular.fromJson($scope.choosedOne.gridState);            
            $scope.gridApi.saveState.restore($scope, jsonObj.gridState);
            if(!$scope.gridOptions.paginationPageSize){
                $scope.gridOptions.paginationPageSize = statsPageSize;
            }
            columnDefsCopy = angular.copy($scope.gridOptions.columnDefs);
            removeCurCol();
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            // $scope.gridOptions.data = [];
            $scope.statParams = jsonObj.stats;
			if ($scope.statParams.statType == "irregular") {
				$scope.statParams.etdFM = DateUtils.convertLocalDateFromServer($scope.statParams.etdFM);
				$scope.statParams.etdTO= DateUtils.convertLocalDateFromServer($scope.statParams.etdTO);
			}
			if ($scope.statParams.statType == "year") {
				$scope.statParams.year= DateUtils.convertLocalDateFromServer($scope.statParams.year);
			}
			if ($scope.statParams.statType == "month") {
				$scope.statParams.month= DateUtils.convertLocalDateFromServer($scope.statParams.month);
			}
			if ($scope.statParams.statType == "day") {
				$scope.statParams.day= DateUtils.convertLocalDateFromServer($scope.statParams.day);
			}
        }

		if ($rootScope.id && $rootScope.id.opsCrms){
			$scope.statParams.statBy = "jobNum";
			$scope.statParams.month = new Date();
			$scope.statParams.filterBy = "filterByClient";
			$scope.statParams.clientName = $rootScope.id.companyName;
			$scope.statParams.shipperId = $rootScope.id.id;
			$scope.statParams.statType = "month";
			$scope.statParams.filterRequired = true;
			$translate.refresh();
		}

		$scope.statParamsPop = function(gridState, statParams) {
            var entity = {};
            if(gridState){
                entity = angular.fromJson(gridState.gridState).stats;            
            }
            else if (statParams){
            	entity = angular.copy(statParams); 
                entity.isDateObj = true;
            }
            else {
                entity.statType = "month";
                entity.filterRequired = true;
            	$scope.choosedOne = {};
            }

            $uibModal.open({
                templateUrl: 'app/entities/stats/statParams-dialog.html',
                controller: 'StatParamsDialogController',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
				$scope.statParams = result;
                // $scope.saveSate();
            }, function() {

            });
        }
		$scope.deleteState = function(gridState) {
            $uibModal.open({
                templateUrl: 'app/entities/grid-state/grid-state-delete-dialog.html',
                controller: 'GridStateDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['GridState', function(GridState) {
                        return GridState.get({id : gridState.id}).$promise;
                    }],
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                    $translatePartialLoader.addPart('gridState');
	                    return $translate.refresh();
	                }]
                }
            }).result.then(function() {
	            var index = $scope.gridStateList.indexOf(gridState);
	            if (index > -1) {
	                $scope.gridStateList.splice(index, 1);
	            };
	            $scope.choosedOne = {};

	            $scope.statParams = {};
		        $scope.statParams.statType = "month";
                $scope.statParams.filterRequired = true;
            }, function() {

            });
		}
        
        $scope._onClerkSelected = function(data) {
            if(!$scope.choosedOne.id){
                return;
            }

            var newOne = angular.copy($scope.choosedOne);
            newOne.id = null;
            newOne.userId = data.id;
            GridState.update(newOne, function(result){
                layer.msg($translate.instant('global.shareSuccess'));
            }, function(error){
                layer.msg($translate.instant('global.save_fail'));
            });
        }

        $scope.plSummary = function(fileType) {
            DataUtils.updateStatParams($scope.statParams);
            OpsFiles.plSummary({
                    period: "JOB DATE: " + DateUtils.convertLocalDateToServer($scope.statParams.etdFM) + " TO " + DateUtils.convertLocalDateToServer($scope.statParams.etdTO),
                    printTime: DateUtils.convertDateTimeToString(new Date()),
                    fileType:fileType,
                    reportType:960
            }, $scope.gridOptions.data, function(result) {
                if (fileType == 2) {
                    var file = new Blob([result.data], {
                        type: 'application/vnd.ms-excel'
                    });
                    var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                    var aTag = document.createElement("a");
                    aTag.download = "PLSummary.xlsx";
                    aTag.href = blobURL;
                    aTag.click();
                } else {
                    $rootScope.PRINTBYTES(result.data);
                }
            }, function(error) {
                layer.msg($translate.instant('stats.stat.noData'));
            });
        }

        $scope.excelExport = function(dataList) {
            if(!dataList || dataList.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var excelDTO = {};
            excelDTO.guides = dataList;
            var title = $translate.instant('global.menu.admin.profitStats');
            title = title + "(From " + DateUtils.convertLocalDateToServer($scope.statParams.etdFM) + " To " + DateUtils.convertLocalDateToServer($scope.statParams.etdTO) + ")";
            OpsFiles.excelProfitStatByUserDTO({
                title: title,
                enCn: $translate.instant('global.enCn')
            }, excelDTO, function(result) {
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('global.menu.admin.profitStats') + ".xlsx");
            })
        }
        
        $scope.uifStatsSheet = function(type) {
            if(!$scope.gridOptions.data || $scope.gridOptions.data.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var excelDTO = {};
            excelDTO.guides = $scope.gridOptions.data;
            var title = "工作单明细报表";
            if(type == "09"){
                title = "航线-揽货方式毛利分析表";
            }
            else if(type == "14"){
                title = "装箱方式毛利分析表";
            }
            else if(type == "03P" || type == "03E"){
                title = "业务员箱量利润统计表";
            }

            title = title + "(From " + DateUtils.convertLocalDateToServer($scope.statParams.etdFM) + " To " + DateUtils.convertLocalDateToServer($scope.statParams.etdTO) + ")";
            OpsFiles.uifStatsSheet({
                type: type,
                byAccountDate: $scope.statParams.byAccountDate == true,
                etdFM: DateUtils.convertLocalDateToServer($scope.statParams.etdFM),
                etdTO: DateUtils.convertLocalDateToServer($scope.statParams.etdTO),
                title: title
            }, excelDTO, function(result) {
                if(type == "03P"){
                    $rootScope.PRINTBYTES(result.data);
                }
                else {
                    $rootScope.DOWNLOADEXCEL(result, title + "-UIF.xlsx");
                }                
            })
        }

        $scope.plDetailedSummary = function() {            
            var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
            var sordedRows = [];
            angular.forEach(allvisiblerows, function(value){
                sordedRows.push(value.entity);
            });
            if(sordedRows.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            OpsFiles.plDetailedSummary({}, sordedRows, function(result) {
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('autopsApp.guide.profitLoss') + ".xlsx");
            })
        }    

        $scope.shipmentsReport = function() {
            if(!$scope.gridOptions.data || $scope.gridOptions.data.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var excelDTO = {};
            excelDTO.guides = $scope.gridOptions.data;
            var title = $translate.instant('stats.stat.shipmentsReport');
            title = title + "(From " + DateUtils.convertLocalDateToServer($scope.statParams.etdFM) + " To " + DateUtils.convertLocalDateToServer($scope.statParams.etdTO) + ")";
            OpsFiles.shipmentsReport({
                title: title
            }, excelDTO, function(result) {
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('stats.stat.shipmentsReport') + ".xlsx");
            })
        }    

        $scope.statParams.searchType = 'Job#';
        $scope.setSearchType = function(type) {
            $scope.statParams.searchType = type;
        }
        function addNewData(data) {
            var isNew = true;
            angular.forEach($scope.gridOptions.data, function(oldOne){
                if(oldOne.jobNum == data.jobNum){
                    isNew = false;
                }
            })
            if(isNew){
                $scope.gridOptions.data.push(data);
            }
        }
        $scope.comStatsByNums = function(bizType) { 
            if(!$scope.statParams.searchStr){
                layer.msg($translate.instant('global.search'));
                return;
            }
            $scope.statParams.bizType = bizType;
            $scope.isSaving = true;
            var loadIndex = layer.load(1, {shade: 0.3});  
            OpsFiles.comStatsByNums($scope.statParams, function(result) {
                $scope.isSaving = false;
                layer.close(loadIndex);  
                if (!result || result.length == 0) {
                    layer.msg($translate.instant('global.messages.resultNull'));
                    return;
                }
                if(!$scope.gridOptions.data){
                    $scope.gridOptions.data = [];
                }
                angular.forEach(result, function(data){
                    addNewData(data);
                })
                resize();           
                initContent();
            }, function(response) {
                $scope.isSaving = false;
                layer.close(loadIndex);  
                $scope.reportavailalbe = false;
                if(response.status == 404){
                    layer.msg($translate.instant('stats.stat.noData'));
                }
            });
        }

        $scope.haiYuanGpSheet = function(type) {
            if(!$scope.gridOptions.data || $scope.gridOptions.data.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var excelDTO = {};
            excelDTO.guides = $scope.gridOptions.data;
            var title;
            if(type == "fmsGp"){
                title = "毛利表";
            }
            else{
                title = "快递毛利表";
            }

            title = title + "(From " + DateUtils.convertLocalDateToServer($scope.statParams.etdFM) + " To " + DateUtils.convertLocalDateToServer($scope.statParams.etdTO) + ")";
            OpsFiles.haiYuanGpSheet({
                type: type,
                title: title
            }, excelDTO, function(result) {
                $rootScope.DOWNLOADEXCEL(result, title + ".xlsx");               
            })
        }

        $scope.exportGpSheet = function(type) {
            if(!$scope.gridOptions.data || $scope.gridOptions.data.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var excelDTO = {};
            excelDTO.guides = $scope.gridOptions.data;
            var title = "毛利表(From " + DateUtils.convertLocalDateToServer($scope.statParams.etdFM) + " To " + DateUtils.convertLocalDateToServer($scope.statParams.etdTO) + ")";
            OpsFiles.exportGpSheet({
                type: type,
                title: title
            }, excelDTO, function(result) {
                $rootScope.DOWNLOADEXCEL(result, title + ".xlsx");               
            })
        }

        $scope.yyComStatsReport = function() {
            if(!$scope.gridOptions.data || $scope.gridOptions.data.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var excelDTO = {};
            excelDTO.guides = $scope.gridOptions.data;
            var title = "统计报表(From " + DateUtils.convertLocalDateToServer($scope.statParams.etdFM) + " To " + DateUtils.convertLocalDateToServer($scope.statParams.etdTO) + ")";
            OpsFiles.yyComStatsReport({
                title: title
            }, excelDTO, function(result) {
                $rootScope.DOWNLOADEXCEL(result, title + ".xlsx");               
            })
        }
        $scope.customizedReport = function(reportType) {
            if(!$scope.gridOptions.data || $scope.gridOptions.data.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var statsResult = {};
            statsResult.statsJobList = $scope.gridOptions.data;
            statsResult.dateFm = DateUtils.convertLocalDateToServer($scope.statParams.etdFM);
            statsResult.dateTo = DateUtils.convertLocalDateToServer($scope.statParams.etdTO);
            OpsFiles.customizedReport({
                fileType: 1,
                reportType: reportType
            }, statsResult, function(result) {
                $rootScope.PRINTBYTES(result.data);             
            })
        }
        $scope.showUifReport = function() {
            if($rootScope.account.companyId == 266 || $rootScope.account.companyId == 267 || $rootScope.account.companyId == 54
                 || $rootScope.account.companyId == 320 || $rootScope.account.companyId == 337 || $rootScope.account.companyId == 338){
                return true;
            }
            return false;
        }
        $scope.statsCustomReport = function(type) {
            if(!$scope.gridOptions.data || $scope.gridOptions.data.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var excelDTO = {};
            excelDTO.guides = $scope.gridOptions.data;
            var title = "客户箱量利润统计表";
            title = title + "(From " + DateUtils.convertLocalDateToServer($scope.statParams.etdFM) + " To " + DateUtils.convertLocalDateToServer($scope.statParams.etdTO) + ")";
            OpsFiles.statsCustomReport({
                type: type,
                etdFM: DateUtils.convertLocalDateToServer($scope.statParams.etdFM),
                etdTO: DateUtils.convertLocalDateToServer($scope.statParams.etdTO),
                title: title
            }, excelDTO, function(result) {
                if(type == "01P"){
                    $rootScope.PRINTBYTES(result.data);
                }
                else {
                    $rootScope.DOWNLOADEXCEL(result, title + ".xlsx");
                }                
            })
        }
        $scope.yyProfitStatement = function() {
            if(!$scope.gridOptions.data || $scope.gridOptions.data.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var excelDTO = {};
            excelDTO.guides = $scope.gridOptions.data;
            var title = "销售利润报表(From " + DateUtils.convertLocalDateToServer($scope.statParams.etdFM) + " To " + DateUtils.convertLocalDateToServer($scope.statParams.etdTO) + ")";
            var loadIndex = layer.load(1, {shade: 0.3});  
            OpsFiles.yyProfitStatement(excelDTO, function(result) {
                layer.close(loadIndex);  
                $rootScope.DOWNLOADEXCEL(result, title + ".xlsx");               
            }, function(){
                layer.close(loadIndex);  
            })
        }

        $scope.searchGuidePop = function() {            
            if($rootScope.HAVENOAUTH('FMS') && $rootScope.HAVENOAUTH('FBA') && $rootScope.HAVENOAUTH('WMS')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            var searchParams = {
                forComStats: true,
                companyId: $rootScope.account.companyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/guide/guide-search-dialog.html',
                controller: 'GuideSearchDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(result) {
                $scope.gridOptions.data = result;
                resize();           
                initContent();
            }, function() {
            });
        }
        $scope.searchParcelsPop = function(shipmentType) {    
            if($rootScope.HAVENOAUTH('TMS') && shipmentType == "TMS"){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            if($rootScope.HAVENOAUTH('PARCEL') && shipmentType == "PARCEL"){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            var searchParams = {
                shipmentType: shipmentType,
                forComStats: true,
                companyId: $rootScope.account.companyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/express-parcel/express-parcel-detail.html',
                controller: 'ExpressParcelDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(result) {
                $scope.gridOptions.data = result;
                resize();           
                initContent();
            }, function() {
            });
        }

        $scope.asyncTasks = function(allCols) {
            DataUtils.updateStatParams($scope.statParams);
            if(!$scope.statParams.etdFM || !$scope.statParams.etdTO){
                layer.msg($translate.instant('stats.stat.dateNullAlert'));              
                return;
            }
            var gridState = $scope.gridApi.saveState.save();
            DataUtils.fillGridDisplayName(gridState, $scope.gridOptions.columnDefs, allCols);
            
            var body = {};
            body.statParams = $scope.statParams;
            body.gridState = gridState;
            body.taskType = "STATS";

            $scope.isSaving = true;        
            var loadIndex = layer.load(1, {shade: 0.3});
            AsyncTask.submitAsyncTask(body, function(result) {
                $scope.isSaving = false;
                layer.close(loadIndex);
                $rootScope.OPS_ALERT($translate.instant('autopsApp.asyncTask.tips.taskSubmitSuccess'));
            }, $rootScope.ALERT_ERROR);
        }
        $scope.delAllChecked = function() {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            for(var i = $scope.gridOptions.data.length -1; i>= 0; i--){
                var isSelected = false;
                angular.forEach(selectedRows, function(data){
                    if(angular.equals(data, $scope.gridOptions.data[i])){
                        isSelected = true;
                    }
                })
                if(isSelected){
                    $scope.gridOptions.data.splice(i, 1);
                }
            }            
        }

        $scope.delAllUnChecked = function() {
            $scope.gridOptions.data = $scope.gridApi.selection.getSelectedRows();
        }
	}
})();