(function() {
	'use strict';

	angular
		.module('autopsApp')
		.controller('IncomeListController', IncomeListController);

	IncomeListController.$inject = ['$scope', '$translate', 'OpsFiles', 'DateUtils'];

	function IncomeListController($scope, $translate, OpsFiles, DateUtils) {
		
		$scope.statParams = {};
		$scope.datePickerOpenStatus = {};
		$scope.dayFormat = "yyyy-MM-dd";
		$scope.datePickerOpenStatus.dateFromPicker = false;
		$scope.datePickerOpenStatus.dateToPicker = false;

		$scope.openCalendar = function(date) {
			$scope.datePickerOpenStatus[date] = true;
		}
		
		$scope.isSaving = false;
		$scope.incomeList = function(fileType) {
			if ($scope.statParams.dateFrom > $scope.statParams.dateTo) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;
            }
            if (DateUtils.getDaysDiff($scope.statParams.dateFrom, $scope.statParams.dateTo) > 365) {
                layer.msg($translate.instant('global.messages.etdPeriodAlert'));
                return;
            }
			$scope.isSaving = true;
			$scope.statParams.fileType = fileType;
			OpsFiles.incomeList($scope.statParams, function(result) {
				$scope.isSaving = false;
				if (fileType == 2) {
					var file = new Blob([result.data], {
						type: 'application/vnd.ms-excel'
					});
					var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
					var aTag = document.createElement("a");
					aTag.download = "incomeList.xlsx";
					aTag.href = blobURL;
					aTag.click();
				} else {
	                var file = new Blob([result.data], {
	                    type: 'application/pdf'
	                });
	                var fileURL = URL.createObjectURL(file);
	                var previewDialog = layer.open({
	                    type: 2,
	                    area: ['1000px', '680px'],
	                    maxmin: true,
	                    title: $translate.instant("stats.stat.viewMonthlyStatement"),
	                    shade: 0.3,
	                    moveType: 0,
	                    shift: 0,
	                    content: fileURL
	                })
	            }
				}, function(response) {
					$scope.isSaving = false;
			});
		}




	}
})();