(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BookingCustomDeleteController', BookingCustomDeleteController);

    BookingCustomDeleteController.$inject = ['$scope', '$rootScope', '$uibModal', '$uibModalInstance', '$translate', '$timeout', '$http',
        'entity', 'BookingTruck', 'OpsReport', 'OpsVender', 'Guide', 'OpsFiles', 'DateUtils', 'GuideCtns', 'DataUtils'
    ];

    function BookingCustomDeleteController($scope, $rootScope, $uibModal, $uibModalInstance, $translate, $timeout, $http,
        entity, BookingTruck, OpsReport, OpsVender, Guide, OpsFiles, DateUtils, GuideCtns, DataUtils) {
        $scope.guide = entity;
        $scope.bookingTrucks = [{}];

        $timeout(function() {
            BookingTruck.get({
                id: $scope.guide.booking.id
            }, function(result) {
                $scope.bookingTrucks = result;
                if(!$scope.bookingTrucks || $scope.bookingTrucks.length == 0){
                    $scope.addTruckOrder();
                }
                else {
                    $scope.loadCtns($scope.bookingTrucks[0]);
                }
                initFilesFolder();
            });
        })
        function initFilesFolder(){
            angular.forEach($scope.bookingTrucks, function(data){
                data.files = [];
            })
        }

        $scope.clear = function  () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.getCtnWeight = function (truck, isForce) {
            if((isForce || !truck.ctnWeight) && $scope.guide.gw){
                truck.ctnWeight = $scope.guide.gw;
            }
        }


        var onSaveError = function(result) {
            layer.msg($translate.instant('global.messages.submittedfailed'));
            $scope.isSaving = false;
        };

        $scope.save = function(truck) {
            if ($scope.guide.isClosed) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.lockedAlert'));
                return;
            }
            $scope.isSaving = true;
            DataUtils.checkBookingTruck(truck);
            BookingTruck.save(truck, function(result) {
                $scope.$emit('autopsApp:bookingTruckUpdate', result);
                saveGCtns(result);
                $scope.isSaving = false;
                angular.copy(result, truck);
                result.files = [];
                layer.msg($translate.instant('global.messages.submitted'));
            }, onSaveError);
        };

        var saveGCtns = function (truck) {
            if (!$scope.guideCtns || angular.equals($scope.guideCtns, guideCtnsCopy) || $scope.guideCtns.length == 0){
                return;
            };
            $scope.isSaving = true;
            GuideCtns.update({id:truck.id}, $scope.guideCtns, onCtnsSaveSuccess, onSaveError);
        };
        var onCtnsSaveSuccess = function (result) {
            $scope.isSaving = false
            $scope.guideCtns = result;
        };
        $scope.addTruckOrder = function() {
            var newTruck = {
                status: "new",
                companyId: $rootScope.account.companyId,
                bookingId: $scope.guide.booking.id,
                createdBy: $rootScope.account.id,
                isExport: $scope.guide.isExport,
                files: []
            };
            $scope.bookingTrucks.push(newTruck);
        }


        $scope.deleteTruckOrder = function(truck) {
            if (truck.id != null) {
                BookingTruck.delete({
                    id: truck.id
                });
            };
            var index = $scope.bookingTrucks.indexOf(truck);
            if (index > -1) {
                $scope.bookingTrucks.splice(index, 1);
            };
        };

        $scope.sendTruckorder = function(truck) {
            if (!truck.truckerName || !truck.factoryName || !truck.attn ||
                !truck.attnMob || !truck.loadTime || !truck.loadAddress) {
                layer.msg($translate.instant('global.messages.truckInvalid'));
                return;
            }
            var title = $translate.instant('autopsApp.booking.home.truck_comfirm_title');
            var content = truck.truckerName + $translate.instant('autopsApp.booking.home.truck_comfirm_content');
            layer.confirm(content, {
                title: title,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function() {
                layer.closeAll();
                truck.status = "Requested";
                truck.companyId = $rootScope.account.companyId;
                truck.createdBy = $rootScope.account.id;
                truck.orderTime = new Date();
                truck.forwarder = $rootScope.account.companyName;
                $scope.save(truck);
            }, function() {
                return;
            });
        };

        $scope.resendTruckorder = function(truck) {
            if (!truck.truckerName || !truck.factoryName || !truck.attn ||
                !truck.attnMob || !truck.loadTime || !truck.loadAddress) {
                layer.msg($translate.instant('global.messages.truckInvalid'));
                return;
            }
            var title = $translate.instant('autopsApp.booking.home.truck_comfirm_title');
            var content = truck.truckerName + $translate.instant('autopsApp.booking.home.truck_comfirm_content');
            layer.confirm(content, {
                title: title,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function() {
                layer.closeAll();
                truck.status = "Amended";
                truck.companyId = $rootScope.account.companyId;
                truck.createdBy = $rootScope.account.id;
                truck.orderTime = new Date();
                truck.forwarder = $rootScope.account.companyName;
                $scope.save(truck);
            }, function() {
                return;
            });
        };

        $scope.amendTruckorder = function(truck) {
            truck.status = "Amending";
        }

        $scope.cancelTruckOrder = function(truck) {
            var title = $translate.instant('autopsApp.booking.home.truck_cancel_title');
            var content = truck.truckerName + $translate.instant('autopsApp.booking.home.truck_cancel_content');
            layer.confirm(content, {
                title: title,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                truck.status = "Cancelled";
                truck.orderTime = new Date();
                $scope.save(truck);
            }, function() {
                return;
            });
        };
        //----------------------------------------------- 拖车安排 --------------------------------------------------------------------------
        $scope.exportTruck = function(truckId, format, reportCode) {
            if (!truckId) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            $scope.exportExcelPdf(format, reportCode, $scope.guide.jobNum, truckId);
        }
        $scope.exportExcelPdf = function(format, code, fileName, idCopy) {
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.packingList = guideReportDTO.booking.bookingCtns;
            if ((code >= 140 && code < 150) || (code >= 160 && code < 170)) {
                for (var i = 0; i < $scope.bookingTrucks.length; i++) {
                    if ($scope.bookingTrucks[i].id == idCopy) {
                        guideReportDTO.bookingTruck = angular.copy($scope.bookingTrucks[i]);
                    }
                }
            }
            guideReportDTO.reportType = code;
            guideReportDTO.opType = 1;
            guideReportDTO.fileType = format;
            guideReportDTO.fileName = fileName;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                $rootScope.EXPORTDOWNLOAD(result, format, fileName);
            });
        }

        $scope.viewBookingTruckFile = function(truckId, reportCode) {
            if (!truckId) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            $scope.previewReport(reportCode, truckId)
        }

        $scope.sendTruckorderEmail = function(truck, type) {
            if (!truck.truckerName || !truck.factoryName || !truck.attn ||
                !truck.attnMob || !truck.loadTime || !truck.loadAddress || !truck.truckerVid) {
                layer.msg($translate.instant('global.messages.truckInvalid'));
                return;
            }

            if (!truck.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }

            truck.companyId = $rootScope.account.companyId;
            truck.createdBy = $rootScope.account.id;
            truck.status = "Requested";
            truck.orderTime = new Date();
            $scope.isSaving = true;
            truck.forwarder = $rootScope.account.companyName;
            BookingTruck.updateOne(truck, function(result) {
                $scope.isSaving = false;
                var email = {};
                email.attachments = [];
                email.head = truck.truckerName;
                email.subject = $rootScope.account.companyName + "--DO: " + truck.soNum + "/ " + DateUtils.convertDateTimeToString(truck.loadTime) + "--" + $translate.instant('entity.email.truckSubject');
                email.to = truck.truckerEmail + "; " + $rootScope.account.email + "; ";
                email.cc = truck.truckerCc;
                email.content = "Dear " + truck.truckerAttn + ",\n\n" + $translate.instant('entity.email.truckContent') + "\n";
                if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                    email.content = email.content + $rootScope.account.signatures[0].signature;
                }
                var entity = {};
                entity.email = email;
                entity.guide = $scope.guide;
                entity.emailType = type;
                entity.code = null;
                entity.files = $scope.files;
                entity.bookingTruck = result;
                sendEmailPop(entity);
            }, function(result) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submittedfailed'));
                $translate.refresh();
            });
        }
        $scope.files = new Array();

        $scope.truckLock = function(truck) {
            if (truck.isLocked) {
                truck.status = "locked";
            } else {
                truck.status = "unlocked";
            }
            $scope.save(truck);
        }


        $rootScope._onTruckBrokerSelected = function(data, truck) {
            OpsVender.getDTO({
                id: data.id
            }, function(result) {
                truck.customDocsDeliveryAddress = result.address;
                truck.customDocsDeliveryPic = result.contactList[0].attn;
                truck.customDocsDeliveryMob = result.contactList[0].mob;
            });
        }

        // 工厂
        $scope.opsFactorys = [];
        $scope.loadOpsFactory = function() {
            if (!$scope.opsFactorys || $scope.opsFactorys.length == 0) {
                OpsVender.getOpsFactorys({
                    venderId: $scope.guide.shipperId
                }, function(result) {
                    $scope.opsFactorys = result;
                });
            };
        };
        $scope._factoryOnSelected = function(data, truck) {
            truck.factoryName = data.companyName;
            truck.loadAddress = data.address;
            truck.attn = data.attn;
            truck.attnTel = data.tel;
            truck.attnFax = data.fax;
            truck.attnMob = data.mob;
            truck.attnEmail = data.email;
        }
        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }
        $scope.generateSo = function(index, isDb) {
            if (isDb) {
                if (null == $scope.guide.soNum) {
                    $scope.bookingTrucks[index].soNum = $scope.guide.mblNum;
                } else {
                    $scope.bookingTrucks[index].soNum = $scope.guide.soNum;
                }
            } else {
                if (!$scope.bookingTrucks[index].soNum) {
                    if (null == $scope.guide.soNum) {
                        $scope.bookingTrucks[index].soNum = $scope.guide.mblNum;
                    } else {
                        $scope.bookingTrucks[index].soNum = $scope.guide.soNum;
                    }
                }
            }
        }

        $scope.generateCtnTypeNum = function(index, isDb) {
            if (isDb) {
                $scope.bookingTrucks[index].ctnNumType = "";
                for (var i = 0; i < $scope.guide.guideFclCtns.length; i++) {
                    $scope.bookingTrucks[index].ctnNumType = $scope.bookingTrucks[index].ctnNumType + $scope.guide.guideFclCtns[i].num + " X " + $scope.guide.guideFclCtns[i].ctnType + "; ";
                }
            } else {
                if (!$scope.bookingTrucks[index].ctnNumType) {
                    $scope.bookingTrucks[index].ctnNumType = "";
                    for (var i = 0; i < $scope.guide.guideFclCtns.length; i++) {
                        $scope.bookingTrucks[index].ctnNumType = $scope.bookingTrucks[index].ctnNumType + $scope.guide.guideFclCtns[i].num + " X " + $scope.guide.guideFclCtns[i].ctnType + "; ";
                    }
                }
            }

        }

        $scope.getTruckCost = function(truck) {
            if (truck.truckPrice || !truck.truckerVid || !$scope.guide.id) {
                return;
            }
            Guide.getTruckCost({
                guideId: $scope.guide.id,
                venderId: truck.truckerVid
            }, function(result) {
                if(!truck.remarks){
                    truck.remarks = "";
                }
                if(result.remarks){
                    truck.remarks = result.remarks + "\n" + truck.remarks;
                }
                truck.truckPrice = result.truckPrice;
            }, function(result) {});
        }
        $scope.previewReport = function(code, truckId) {
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.packingList = guideReportDTO.booking.bookingCtns;
            if (truckId) {
                for (var i = 0; i < $scope.bookingTrucks.length; i++) {
                    if ($scope.bookingTrucks[i].id == truckId) {
                        guideReportDTO.bookingTruck = angular.copy($scope.bookingTrucks[i]);
                    }
                }
            }
            guideReportDTO.reportType = code;
            guideReportDTO.opType = 1;
            guideReportDTO.fileType = 1;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {

            });
        }
        var sendEmailPop = function(entity) {
            $uibModal.open({
                templateUrl: 'app/entities/email/email-dialog.html',
                controller: 'EmailDialogController',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                $scope.guide = result;
            }, function(result) {});
        }

        $scope.setExImport = function(truck, value) {
            truck.isExport = value;
        }

        $scope._truckSelected = function(truck, data) {
            truck.truckInfo = "";
            if(data.ctnType){
                truck.truckInfo += "车型: " + data.ctnType + "\n";
            }
            if(data.truckNum){
                truck.truckInfo += "车牌: " + data.truckNum + "\n";
            }
            if(data.ctnNum){
                truck.truckInfo += "柜号: " + data.ctnNum + "\n";
            }
            if(data.driverName){
                truck.truckInfo += "司机: " + data.driverName + "\n";
            }
            if(data.driverMob){
                truck.truckInfo += "电话: " + data.driverMob + "\n";
            }
            if(data.remarks){
                truck.truckInfo += "备注: " + data.remarks + "\n";
            }
        }

        $scope.addGCtn = function(truck) {
            if(!truck.truckerVid){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.bookingTruck.chooseTrucker'));
                return;
            }
            $scope.guideCtns.push({
                truckerId: truck.truckerVid,
                smsSent:false
            });
        };

        var guideCtnsCopy;
        $scope.guideCtns = [];
        $scope.loadCtns = function (truck) {
            $scope.guideCtns = [];
            GuideCtns.get({id : $scope.guide.id}, function(result) {
                $scope.guideCtns = result;
                $scope.initGuideCtns(truck);
                guideCtnsCopy = angular.copy($scope.guideCtns);
            });
        };

        $scope.initGuideCtns = function(truck) {
            angular.forEach($scope.guideCtns, function(ctn){
                if (!ctn.truckNum && !ctn.driverName && !ctn.driverMob){
                    ctn.truckerId = truck.truckerVid;
                }
                else if (!ctn.truckerId){
                    ctn.truckerId = truck.truckerVid;
                }
            })
        }

        $scope.smSend = function(ctn, truck) {
            var entity = {};
            entity.ctn = ctn;
            var guide = angular.copy($scope.guide);
            guide.booking = {};
            guide.booking.bookingTrucks = [];
            guide.booking.bookingTrucks.push(truck);
            entity.guide = guide;
            $uibModal.open({
                templateUrl: 'app/entities/bookingCtns/bookingCtns-delete-dialog.html',
                controller: 'BookingCtnsDeleteController',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
            }, function(result) {
            });
        }

        $scope.deleteGCtn = function(ctn) {
            if (ctn.id != null) {
                GuideCtns.delete({
                    id: ctn.id
                });
            };
            var index = $scope.guideCtns.indexOf(ctn);
            if (index > -1) {
                $scope.guideCtns.splice(index, 1);
            };
        };

        $scope.lockCtn = function(ctn) {
            if (ctn.locked) {
                ctn.locked = false;
            } else {
                ctn.locked = true;
            }
        }

        $scope.checkCtnRepeat = function(ctn) {
            if (!ctn.ctnNum) {
                return;
            }
            angular.forEach($scope.guideCtns, function(data) {
                if (data != ctn && data.ctnNum == ctn.ctnNum) {
                    $rootScope.OPS_ALERT(data.ctnNum + ": " + $translate.instant('autopsApp.guide.home.ctnRepeatedAlert'));
                }
            })
        }

    }
})();
