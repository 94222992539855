(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('SubTrackingDeleteController',SubTrackingDeleteController);

    SubTrackingDeleteController.$inject = ['$uibModalInstance', '$scope','$rootScope', '$translate', '$timeout', 'entity', 'ExpressParcel', 'WmsIn', 'Guide'];

    function SubTrackingDeleteController($uibModalInstance, $scope,$rootScope, $translate, $timeout, entity, ExpressParcel, WmsIn, Guide) {
        var vm = this;

        vm.pushOrdersDTO = angular.copy(entity);
        vm.clear = clear;
        vm.pushOrders = pushOrders;
        vm.productChange = productChange;
        vm.getChannels = getChannels;
        vm.getGoeosV2Services = getGoeosV2Services;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        vm.wmsIds = [];
        vm.services = [];

        // 需要选择仓库或渠道的edi，配置后，在后端/apiFms/getWarehouses、/apiFms/getChannels可以返回相关的数据
        vm.showWarehouses = ["TPL", "usniuku"];
        vm.showChannel = ["TPL", "usniuku"];

        $timeout(function (){
            if (vm.pushOrdersDTO.accountBind.type == 'MoreLinkBC') {
                getMoreLinkChannels();
                getMoreLinkWarehouses();
            }
            if (vm.pushOrdersDTO.accountBind.type == 'MoreLinkBCNew') {
                getNewMoreLinkDomesticProducts();
                getNewMoreLinkWarehouses();
            }
            if (vm.pushOrdersDTO.accountBind.type == 'MoreLinkHWNew') {
                getNewMoreLinkShippingCompany();
            }
            if (vm.pushOrdersDTO.accountBind.type == 'MorelinkOutNew'){
                getNewMoreLinkWarehouses();
            }
            if (vm.pushOrdersDTO.accountBind.type == 'usniuku'){
                getNiukuChannels();
            }
            if (vm.showWarehouses.indexOf(vm.pushOrdersDTO.accountBind.type) != -1) {
                getWarehouses();
            }
            if (vm.showChannel.indexOf(vm.pushOrdersDTO.accountBind.type) != -1 && vm.pushOrdersDTO.accountBind.type != "usniuku") {
                getChannels();
            }
            if (vm.pushOrdersDTO.accountBind.type == 'TPL') {
                getTplPorts("");
            }
            if(vm.pushOrdersDTO.accountBind.payeeCode){
                vm.wmsIds = vm.pushOrdersDTO.accountBind.payeeCode.split(';');
            }
            if(vm.pushOrdersDTO.accountBind.thirdPartCode){
                vm.services = vm.pushOrdersDTO.accountBind.thirdPartCode.split(';');
            }
            if (vm.pushOrdersDTO.accountBind.type != 'GOEOSV2'){
                vm.pushOrdersDTO.accountBind.payeeCode = null;
                vm.pushOrdersDTO.accountBind.thirdPartCode = null;
            }
        })

        vm.fbaType=[{name:"FBA",code:"0"},{name:"私人地址",code:"1"},{name:"海外仓",code:"2"},{name:"留仓",code:"3"},{name:"自提",code:"4"},{name:"沃尔玛",code:"8"},{name:"一件代发",code:"10"}]
        vm.customsType=[{name:"买单",code:"0"},{name:"退税",code:"1"}];
        vm.goeosv2Type=[{name:"Fedex",code:1},{name:"UPS",code:2},{name:"USPS",code:3}];
        vm.goeosv2ChooseType = null;
        function pushOrders (id) {
            layer.load(1, {shade: 0.3});
            if (vm.pushOrdersDTO.accountBind.type == 'usniuku') {
                for (var index in vm.channels) {
                    if (vm.channels[index].code == vm.pushOrdersDTO.accountBind.thirdPartCode) {
                        //纽扣目的仓名字，接口上必填
                        vm.pushOrdersDTO.accountBind.pfVenderName=vm.channels[index].name;
                        break;
                    }
                }

                for (var index in vm.warehouses) {
                    if (vm.warehouses[index].code == vm.pushOrdersDTO.accountBind.payeeCode) {
                        //纽扣送货仓代码，接口上必填
                        vm.pushOrdersDTO.accountBind.warehouseName=vm.warehouses[index].name;
                        break;
                    }
                }
            }
            if (vm.pushOrdersDTO.accountBind.type == 'TPL') {
                vm.pushOrdersDTO.accountBind.appPassword = vm.tplPol + ";" + vm.tplPod;
            }
            if(vm.pushOrdersDTO.getRequestBody){
                ExpressParcel.getPushApiRequestBody(vm.pushOrdersDTO, function(result){
                    layer.closeAll();
                    $rootScope.DOWNLOAD_TXT(result.value, vm.pushOrdersDTO.expressParcelDTO.jobNum + "_request_body.txt");
                    $uibModalInstance.close();
                }, $rootScope.ALERT_ERROR);
                return;
            }
            if(vm.pushOrdersDTO.wmsIn){
                WmsIn.sendWmsInApi(vm.pushOrdersDTO, function(result){
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submitted'));
                    $uibModalInstance.close(result);
                }, $rootScope.ALERT_ERROR);
            }
            else if(vm.pushOrdersDTO.guideId){
                Guide.pushFbaShipment(vm.pushOrdersDTO, function(result){
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submitted'));
                    $uibModalInstance.close(result);
                }, $rootScope.ALERT_ERROR);
            }
            else {
                ExpressParcel.pushOrders(vm.pushOrdersDTO, function(resultList){
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submitted'));
                    $uibModalInstance.close(resultList);
                }, function (){
                    layer.closeAll();
                });
            }
        }

        vm.pedderWarehouses = [
            { value: 'SK', label: '盐田有信达普通仓' },
            { value: 'SZ', label: '华南物流监管仓' },
            { value: 'YP', label: '盐田恒盛辉普通仓' },
            { value: 'YT', label: '盐田监管仓' },
            { value: 'HP', label: '华南普通仓' },
            { value: 'CDXD', label: '成都新都仓' },
            { value: 'CQJL', label: '重庆九龙坡仓' },
            { value: 'DGNC', label: '东莞南城仓' },
            { value: 'FGXA', label: '凤岗到西安集货仓' },
            { value: 'FSNZ', label: '佛山南庄仓' },
            { value: 'FZCS', label: '福州晋安仓' },
            { value: 'GZBY', label: '广州白云仓' },
            { value: 'GZCT', label: '广州白云香港仓' },
            { value: 'GZFB', label: '广州富邦仓' },
            { value: 'GZHP', label: '广州黄浦仓' },
            { value: 'GZWS', label: '广州威时仓' },
            { value: 'HZHC', label: '惠州惠城仓' },
            { value: 'JMPJ', label: '江门蓬江仓' },
            { value: 'JPKY', label: '汕头金平空运仓' },
            { value: 'LHCQ', label: '龙华至成都/重庆集货' },
            { value: 'NSBD', label: '南沙八达仓' },
            { value: 'NSXK', label: '南沙新垦仓' },
            { value: 'QZLC', label: '泉州鲤城仓' },
            { value: 'SDLL', label: '顺德勒流仓' },
            { value: 'STJP', label: '汕头金平仓' },
            { value: 'STLH', label: '汕头龙湖仓' },
            { value: 'SZWY', label: '深圳外运' },
            { value: 'XAWY', label: '西安未央仓' },
            { value: 'XMGK', label: '厦门灌口仓' },
            { value: 'XMXA', label: '厦门到西安集货仓' },
            { value: 'XMXY', label: '厦门象屿仓' },
            { value: 'YJJC', label: '阳江江城仓' },
            { value: 'ZHXZ', label: '珠海香洲仓' },
            { value: 'ZQDZ', label: '肇庆端州仓' },
            { value: 'ZSHJ', label: '中山火炬仓' },
            { value: 'ZSXL', label: '中山小榄仓' }
        ];

        vm.pedderFlowTo = [
            { value: "", label: "" },
            { value: '1', label: '深圳华南监管仓' },
            { value: '2', label: '盐田恒盛辉监管仓' },
            { value: '3', label: '下香港(当地班车)' },
            { value: '4', label: '当地直拼' },
            { value: '5', label: '福建至香港快线' },
            { value: '6', label: '普洛斯一期' },
            { value: '7', label: '深圳盐田恒盛辉普通仓' },
            { value: '8', label: '西安至深圳专线' },
            { value: '9', label: '深圳至西安专线' },
            { value: '10', label: '厦门至西安专线' },
            { value: '11', label: '西安至厦门专线' },
            { value: '12', label: '汕头至广州威时仓专线' },
            { value: '13', label: '汕头至广州艺骏仓专线' },
            { value: '14', label: '汕头至深圳富邦仓专线' },
            { value: '15', label: '汕头至厦门机场专线' },
            { value: '16', label: '成都/重庆至深圳专线' },
            { value: '17', label: '深圳至成都/重庆专线' },
            { value: '18', label: '深圳华南普通仓' },
            { value: '19', label: '盐田平盐1号普通仓' },
            { value: '20', label: '广州白云仓' },
            { value: '21', label: '南沙企盛仓' },
            { value: '22', label: '广州南沙中心仓' }
        ];

        vm.MoreLinkChannels = [];
        function getMoreLinkChannels () {
            if(!vm.pushOrdersDTO.guideId){
                return;
            }
            Guide.getMoreLinkChannels(vm.pushOrdersDTO, function(result){
                vm.MoreLinkChannels = result;
            }, $rootScope.ALERT_ERROR)
        }

        vm.MoreLinkWarehouses = [];
        function getMoreLinkWarehouses () {
            if(!vm.pushOrdersDTO.guideId){
                return;
            }
            Guide.getMoreLinkWarehouses(vm.pushOrdersDTO, function(result){
                vm.MoreLinkWarehouses = result;
            }, $rootScope.ALERT_ERROR)
        }

        vm.newMoreLinkDomesticProducts = [];
        function getNewMoreLinkDomesticProducts () {
            Guide.getNewMoreLinkDomesticProducts(vm.pushOrdersDTO, function(result){
                vm.newMoreLinkDomesticProducts = result;
            }, $rootScope.ALERT_ERROR)
        }

        vm.newMoreLinkWarehouses = [];
        function getNewMoreLinkWarehouses () {
            Guide.getNewMoreLinkWarehouses(vm.pushOrdersDTO, function(result){
                vm.newMoreLinkWarehouses = result;
            }, $rootScope.ALERT_ERROR)
        }

        vm.newMoreLinkPlans = [];
        function productChange (productId) {
            vm.newMoreLinkPlans = [];
            vm.pushOrdersDTO.accountBind.appPassword = null;
            for (var idx in vm.newMoreLinkDomesticProducts) {
                if (vm.newMoreLinkDomesticProducts[idx].id == productId) {
                    vm.newMoreLinkPlans = vm.newMoreLinkDomesticProducts[idx].planInfoList;
                    return;
                }
            }
        }

        vm.newMoreLinkShippingCompanys = [];
        function getNewMoreLinkShippingCompany () {
            Guide.getNewMoreLinkShippingCompany(vm.pushOrdersDTO, function(result){
                vm.newMoreLinkShippingCompanys = result;
            }, $rootScope.ALERT_ERROR)
        }

        vm.warehouses = [];
        function getWarehouses () {
            Guide.getWarehouses(vm.pushOrdersDTO, function(result){
                vm.warehouses = result;
            }, $rootScope.ALERT_ERROR)
        }

        vm.channels = [];
        vm.usniukuText = false;
        function getChannels () {
            if (vm.pushOrdersDTO.accountBind.type === "usniuku" && vm.pushOrdersDTO.accountBind.thirdPartCodeFd === "1"){
                vm.usniukuText = true;
            }
            else {
                vm.usniukuText = false;
            }
            Guide.getChannels(vm.pushOrdersDTO, function(result){
                vm.channels = result;
            }, $rootScope.ALERT_ERROR)
        }

        vm.tplPorts = [];
        vm.getTplPorts = getTplPorts;
        function getTplPorts (keywords) {
            Guide.getTplPorts({keywords: keywords}, vm.pushOrdersDTO, function(result){
                vm.tplPorts = result;
            }, $rootScope.ALERT_ERROR)
        }

        vm.tplPolSelect = tplPolSelect;
        function tplPolSelect (item) {
            vm.tplPol = item.code;
        }

        vm.tplPodSelect = tplPodSelect;
        function tplPodSelect (item) {
            vm.tplPod = item.code;
        }

        vm.logisticsServices =[];

        function getGoeosV2Services () {
            Guide.getGoeosV2Service({serviceType:vm.goeosv2ChooseType},vm.pushOrdersDTO.accountBind,function(result){
                vm.logisticsServices = result;
            }, $rootScope.ALERT_ERROR)
        }


        vm.niukuChannels = [];
        function getNiukuChannels () {
            Guide.getNiukuChannels(vm.pushOrdersDTO, function(result){
                vm.niukuChannels = result;
            }, $rootScope.ALERT_ERROR)
        }

        $scope.updateNiukuChannels = function(item){
            vm.pushOrdersDTO.accountBind.appPassword = item.code;
        }


        $scope.updateNiuku = function(item){
            vm.pushOrdersDTO.accountBind.thirdPartCode = item.code;
        }
    }
})();
