(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('EdiInsuranceDialogController', EdiInsuranceDialogController);

    EdiInsuranceDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$translate', '$stateParams', '$uibModalInstance',
    'entity', 'EdiInsurance', 'DateUtils'];

    function EdiInsuranceDialogController ($timeout, $scope, $rootScope, $translate, $stateParams, $uibModalInstance,
        entity, EdiInsurance, DateUtils) {
        var vm = this;

        vm.guide = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        $scope.ediInsurance = {'insuranceType': entity.insuranceType};
        vm.linghangDataMap = {};
        $timeout(function (){
            if($rootScope.HAVENOAUTH("INSUR") && $rootScope.HAVENOAUTH("WYB")){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
            }
            if(entity && entity.guideId){
                EdiInsurance.getByGuideId({
                    guideId: entity.guideId,
                    insuranceType: entity.insuranceType
                }, onGetSuccess);
            }
            if (entity.insuranceType == "WYB") {
                EdiInsurance.getWybDataMap(function(result){
                    vm.linghangDataMap = result;
                    vm.linghangDataMap.domesticPortsBg = [];
                    angular.forEach(vm.linghangDataMap.domesticPorts, function(data){
                        if(data.bgCode == "0"){
                            vm.linghangDataMap.domesticPortsBg.push(data);
                        }
                    })
                })
            } else if (entity.insuranceType == "PACIFIC") {
                EdiInsurance.getPacificDataMap(function(result){
                    vm.pacificDataMap = result;
                    if (vm.pacificDataMap.category && vm.pacificDataMap.category.length != 0) {
                        vm.pacificDataMap.kindList = vm.pacificDataMap.category[0].kindList;
                        if ($scope.ediInsurance && $scope.ediInsurance.comCode) {
                            angular.forEach(vm.pacificDataMap.category, function(item) {
                                if (item.code == $scope.ediInsurance.comCode) {
                                    vm.pacificDataMap.kindList = item.kindList;
                                }
                            })
                        }
                    }
                })
            } else if (entity.insuranceType == "KUAJINGBAO") {
                EdiInsurance.getKuaJingBaoDataMap(function(result){
                    vm.kuaJingBaoDataMap = result;
                })
            }
            else {
                EdiInsurance.getLinghangDataMap(function(result){
                    vm.linghangDataMap = result;
                    vm.linghangDataMap.domesticPortsBg = [];
                    angular.forEach(vm.linghangDataMap.domesticPorts, function(data){
                        if(data.bgCode == "0"){
                            vm.linghangDataMap.domesticPortsBg.push(data);
                        }
                    })
                    if(entity.insuranceType == "FBA"){
                        vm.linghangDataMap.comCode = [{code: "2", name: "太平洋"}];
                        vm.linghangDataMap.transportId = [
                            {code: "2", name: "海运"},
                            {code: "4", name: "铁路"},
                            {code: "6", name: "空运"},
                            {code: "17", name: "公路"},
                            {code: "26", name: "快递"}
                        ];
                    }
                })
            }
        });
        var onGetSuccess = function(result) {
            $scope.ediInsurance = result;
            if (entity.insuranceType == "PACIFIC") {
                if (vm.pacificDataMap && vm.pacificDataMap.category && vm.pacificDataMap.category.length != 0) {
                    angular.forEach(vm.pacificDataMap.category, function(item) {
                        if (item.code == $scope.ediInsurance.comCode) {
                            vm.pacificDataMap.kindList = item.kindList;
                        }
                    })
                }
                return;
            }
            if(entity.insuranceType == "CARGO"){
                $scope.apiKancha();
                $scope.apiXianzhong();
            }
            $scope.updateAmt();
            $translate.refresh();
            if (entity.insuranceType == 'WYB' && DateUtils.getDaysDiff($scope.ediInsurance.startDate, new Date()) > 7) {
                $rootScope.OPS_ALERT("投保时间晚于起运日期超过7天需要单独到投保网站上传倒签保函投保！");
            }
            if(entity.insuranceType != 'WYB' && DateUtils.getDaysDiff($scope.ediInsurance.startDate, new Date()) > 5){
                $rootScope.OPS_ALERT("投保时间晚于起运日期超过5天需要单独到投保网站上传倒签保函投保！");
            }
            if (entity.insuranceType=="EBAO"){
                $scope.ebaoInit(result);
            }
        }
        $scope.terminiPortSmallChange = function() {
            if(!vm.linghangDataMap || !$scope.ediInsurance){
                return;
            }
            angular.forEach(vm.linghangDataMap.portSmall, function(data){
                if(data.smCode == $scope.ediInsurance.terminiPortSmall){
                    $scope.ediInsurance.payPlace = data.enName + " IN USD";
                }
            })
        }
        $scope.terminiPortBigChange = function() {
            $scope.ediInsurance.terminiPortSmall = null
            if(entity.insuranceType == "CARGO"){
                $scope.apiKancha();
            }

        }
        $scope.zhuxianChange = function(item) {
            angular.forEach($scope.dataXianzhong.zhuxian, function(data){
                data.checked = false;
            })
            item.checked = true;
            updateCreditInfo();
        }
        $scope.fjxChange = function(item) {
            item.checked = !item.checked;
            updateCreditInfo();
        }

        var updateCreditInfo = function() {
            if(!$scope.ediInsurance){
                return;
            }
            $scope.ediInsurance.creditInfo = "";
            angular.forEach($scope.dataXianzhong.zhuxian, function(data){
                if(data.checked){
                    $scope.ediInsurance.creditInfo = $scope.ediInsurance.creditInfo + angular.uppercase(data.description) + "\n";
                }
            })
            angular.forEach($scope.dataXianzhong.fjx, function(data){
                if(data.checked){
                    $scope.ediInsurance.creditInfo = $scope.ediInsurance.creditInfo + angular.uppercase(data.description) + "\n";
                }
            })
        }

        $scope.ttlAmount = "N/A";
        $scope.estInsranceFee = "N/A";
        $scope.updateAmt = function() {
            if($scope.ediInsurance.jcl && $scope.ediInsurance.invoiceAmount){
                $scope.ttlAmount = $scope.ediInsurance.jcl * $scope.ediInsurance.invoiceAmount/100;
                angular.forEach(vm.linghangDataMap.currency, function(data){
                    if(data.code == $scope.ediInsurance.currencyCode){
                        $scope.ttlAmount = $scope.ttlAmount + " " + data.enName;
                    }
                })
            }
            $scope.apiBaofei();
        }
        $scope.apiBaofei = function() {
            if(!$scope.ediInsurance.invoiceAmount || !$scope.ediInsurance.currencyCode  || $scope.ediInsurance.currencyCode == "null"
                || !$scope.ediInsurance.jcl || !$scope.ediInsurance.comCode  || $scope.ediInsurance.comCode == "null"){
                return;
            }
            $scope.estInsranceFee = "N/A";
            EdiInsurance.apiBaofei($scope.ediInsurance, function(result){
                $scope.estInsranceFee = result.baofei;
                angular.forEach(vm.linghangDataMap.currency, function(data){
                    if(data.code == result.currency){
                        $scope.estInsranceFee = $scope.estInsranceFee + " " + data.enName;
                    }
                })
            }, $rootScope.ALERT_ERROR)
        }


        $scope.comCodeChange = function() {
            if(entity.insuranceType == "CARGO"){
                $scope.apiXianzhong();
                $scope.apiKancha();
            }
            $scope.apiBaofei();
        }

        $scope.dataXianzhong = [];
        $scope.apiXianzhong = function() {
            if(!$scope.ediInsurance.transportId || !$scope.ediInsurance.comCode){
                return;
            }
            $scope.dataXianzhong = [];
            EdiInsurance.apiXianzhong($scope.ediInsurance, function(result){
                $scope.dataXianzhong = result;
                if($scope.dataXianzhong && $scope.dataXianzhong.zhuxian && $scope.dataXianzhong.zhuxian.length > 0){
                    $scope.dataXianzhong.zhuxian[0].checked = true;
                }
                updateChecked();
                updateCreditInfo();
            }, $rootScope.ALERT_ERROR)
        }

        $scope.dataKancha = [];
        $scope.apiKancha = function() {
            if(!$scope.ediInsurance.transportId || !$scope.ediInsurance.terminiPortBig || !$scope.ediInsurance.comCode){
                return;
            }
            $scope.dataKancha = [];
            EdiInsurance.apiKancha($scope.ediInsurance, function(result){
                $scope.dataKancha = result;
                if(result.length > 0){
                    $scope.ediInsurance.serveyCode = result[0].value;
                }
            }, $rootScope.ALERT_ERROR)
        }

        $scope.getByJobNum = function() {
            entity.insuranceType = $scope.ediInsurance.insuranceType;
            if (entity.insuranceType == "WYB") {
                EdiInsurance.getWybDataMap(function(result){
                    vm.linghangDataMap = result;
                    vm.linghangDataMap.domesticPortsBg = [];
                    angular.forEach(vm.linghangDataMap.domesticPorts, function(data){
                        if(data.bgCode == "0"){
                            vm.linghangDataMap.domesticPortsBg.push(data);
                        }
                    })
                })
            }

            EdiInsurance.getByJobNum({
                companyId: $rootScope.account.companyId,
                jobNum: $scope.guide.jobNum,
                insuranceType: entity.insuranceType
            }, onGetSuccess, function(error){
                if(error.status == 404){
                    $rootScope.OPS_ALERT($translate.instant("global.numNotExist"));
                }
                else {
                    layer.msg($translate.instant("global.loadFailed"));
                }
            })
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        $scope.clearData = function() {
            $scope.ediInsurance = {};
            $scope.guide = {};
        }
        function save () {
            vm.isSaving = true;
            var planInfoList = [];
            angular.forEach($scope.dataXianzhong.zhuxian, function(data){
                if(data.checked){
                    var planInfo = {
                        name: data.name,
                        planCode: data.id,
                        isMain: 1
                    };
                    planInfoList.push(planInfo);
                }
            })
            angular.forEach($scope.dataXianzhong.fjx, function(data){
                if(data.checked){
                    var planInfo = {
                        name: data.name,
                        planCode: data.id,
                        isMain: 0
                    };
                    planInfoList.push(planInfo);
                }
            })
            $scope.ediInsurance.planInfoList = angular.toJson(planInfoList, true);
            if ($scope.ediInsurance.id !== null) {
                EdiInsurance.update($scope.ediInsurance, onSaveSuccess, onSaveError);
            } else {
                EdiInsurance.save($scope.ediInsurance, onSaveSuccess, onSaveError);
            }
        }
        var updateChecked = function() {
            if(!$scope.ediInsurance || !$scope.ediInsurance.planInfoListObj || !$scope.dataXianzhong){
                return;
            }
            $scope.ediInsurance.creditInfo = "";
            angular.forEach($scope.dataXianzhong.zhuxian, function(data){
                data.checked = false;
                angular.forEach($scope.ediInsurance.planInfoListObj, function(item){
                    item.isMain = item.isMain - 0;
                    if(item.isMain && item.planCode == data.id){
                        data.checked = true;
                    }
                })
            })
            angular.forEach($scope.dataXianzhong.fjx, function(data){
                angular.forEach($scope.ediInsurance.planInfoListObj, function(item){
                    if(!item.isMain && item.planCode == data.id){
                        data.checked = true;
                    }
                })
            })
            $translate.refresh();
        }

        function onSaveSuccess (result) {
            $scope.ediInsurance = result;
            updateChecked();
            layer.msg($translate.instant('global.messages.submitted'));
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createTime = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $scope.apiToubao = function() {
            if (!$scope.ediInsurance.id) {
                $rootScope.OPS_ALERT("请先保存后再提交！");
                return;
            }
            if (entity.insuranceType == 'WYB' && DateUtils.getDaysDiff($scope.ediInsurance.startDate, new Date()) > 7) {
                $rootScope.OPS_ALERT("投保时间晚于起运日期超过7天需要单独到投保网站上传倒签保函投保！");
            }
            if(entity.insuranceType != 'WYB' && DateUtils.getDaysDiff($scope.ediInsurance.startDate, new Date()) > 5){
                $rootScope.OPS_ALERT("投保时间晚于起运日期超过5天需要单独到投保网站上传倒签保函投保！");
            }
            EdiInsurance.apiToubao($scope.ediInsurance, function(result){
                $scope.ediInsurance = result;
                updateChecked();
                layer.msg($translate.instant('global.messages.submitted'));
            }, $rootScope.ALERT_ERROR)
        }

        $scope.apiOrderinfo = function() {
            EdiInsurance.apiOrderinfo($scope.ediInsurance, function(result){
                $scope.ediInsurance = result;
                updateChecked();
                layer.msg($translate.instant('global.update_success'));

            }, $rootScope.ALERT_ERROR)
        }
        $scope.apiPigai = function() {
            EdiInsurance.apiPigai($scope.ediInsurance, function(result){
                $rootScope.OPS_ALERT(result.msg);
            }, $rootScope.ALERT_ERROR)
        }
        $scope.apiChedan = function() {
            EdiInsurance.apiChedan($scope.ediInsurance, function(result){
                layer.msg($translate.instant('global.messages.submitted'));
            }, $rootScope.ALERT_ERROR)
        }

        $scope.getToubaoBody = function() {
            layer.load(1, {shade: 0.3});
            EdiInsurance.getToubaoBody($scope.ediInsurance, function(result){
                $rootScope.DOWNLOAD_TXT(result.value, $scope.ediInsurance.tradeNo + "_EDI_LH.txt");
                layer.closeAll();
            }, function(error){
                layer.closeAll();
            });
        }

        $scope.audit = function (isAudit) {
            vm.isSaving = true;
            EdiInsurance.audit({ediInsuranceId: $scope.ediInsurance.id,isAudit: isAudit}, '', function (result) {
                $scope.ediInsurance = result;
                updateChecked();
                if(isAudit){
                    layer.msg($translate.instant("autopsApp.ediInsurance.auditSuccess"));
                }else{
                    layer.msg($translate.instant("autopsApp.ediInsurance.cancelAudit"));
                }
                vm.isSaving = false;
            }, $rootScope.ALERT_ERROR);
        }

        $scope.categoryChange = function() {
            if(entity.insuranceType == "PACIFIC"){
                angular.forEach(vm.pacificDataMap.category, function(categoryItem) {
                    if (categoryItem.code == $scope.ediInsurance.comCode) {
                        vm.pacificDataMap.kindList = categoryItem.kindList;
                    }
                })
            }
        }

        vm.ebaoPackType=[];
        vm.ebaoGoodValAdditionRate=[];
        vm.ebaoTransportMode=[];
        vm.ebaoDestinationType=[];
        vm.ebaoShelvesMode=[];
        vm.ebaoStoreType=[];
        vm.ebaoInsurantCertificateType=[];

        $scope.ebaoInit = function(result) {
            vm.ebaoGoodValAdditionRate=["1.0","1.1","1.2","1.3"];
            vm.ebaoTransportMode =[{"name":"海运","code":"1"},{"name":"铁路","code":"3"},{"name":"空运","code":"4"},{"name":"全程无忧","code":"5"}];
            vm.ebaoDestinationType =[{"name":"仓库地址","code":"1"},{"name":"非仓库地址","code":"2"}];
            vm.ebaoShelvesMode =[{"name":"上架","code":"1"},{"name":"不上架","code":"2"},{"name":"3-港到港","code":"3"},{"name":"4-仓到仓","code":"4"}];
            vm.ebaoStoreType =[{"name":"FBA仓","code":"1"},{"name":"阿里全球速卖通仓","code":"2"},{"name":"eBay仓","code":"3"},{"name":"菜鸟海外仓","code":"4"},
                {"name":"万邑通仓","code":"5"},{"name":"谷仓海外仓","code":"6"},{"name":"4PX仓","code":"7"},{"name":"京东仓","code":"8"},
                {"name":"易达仓","code":"9"},{"name":"wish仓","code":"10"},{"name":"Lazada仓","code":"11"},{"name":"DHGate仓","code":"12"},
                {"name":"Ueeshop仓","code":"13"},{"name":"Xshoppy仓","code":"14"},{"name":"FunPinPin仓","code":"15"},{"name":"ShopBase仓","code":"16"},
                {"name":"Cdiscount仓","code":"17"},{"name":"其他海外仓","code":"18"},{"name":"沃尔玛仓","code":"19"},{"name":"私人仓库/私人地址","code":"200"}];

            vm.ebaoInsurantCertificateType =[{"name":"营业执照","code":"1"},{"name":"海外公司营业执照","code":"4"}];
            if (result.isShelf){
                vm.ebaoPackType =[{"name":"纸箱","code":"1"},{"name":"木箱","code":"2"},{"name":"托盘","code":"4"}];
            }
            else {
                vm.ebaoPackType =[{"name":"托盘(Pallet)","code":"10"},{"name":"纸箱(Carton)Ctns","code":"11"},{"name":"散装(Bulk)","code":"12"},{"name":"包、捆(Bale)B/S","code":"13"},
                    {"name":"袋(Bag)Bgs","code":"14"},{"name":"麻袋(Gunny Bag)Bgs","code":"15"},{"name":"纸袋(Paper Bag)Bgs","code":"16"},{"name":"布袋(Sack) Sks","code":"17"},
                    {"name":"人造革袋(Leatheroid Bag) Bgs","code":"18"},{"name":"各种木箱(Case)C/S","code":"19"},{"name":"胶板箱(Plywood)C/S,/CS","code":"20"},{"name":"板条、亮格箱(Crate)Crts","code":"21"},
                    {"name":"各种金属桶(Iron Drums) Drms, D/S","code":"22"},{"name":"塑料桶(Plastic Drums)","code":"23"},{"name":"鼓形木桶(Barrel) Brls","code":"24"},{"name":"大木桶(Hogshead) Hghds","code":"25"},
                    {"name":"小木桶(Keg) Kgs","code":"26"},{"name":"纸板桶(Fibre Drum)","code":"27"},{"name":"捆扎(Bundle) Bdle","code":"28"},{"name":"卷筒等(Roll,Reel,Coil)","code":"29"},
                    {"name":"篓筐(Basket) Bkts","code":"30"},{"name":"坛、甏(Jar)","code":"31"},{"name":"瓶(Bottle)","code":"32"},{"name":"钢瓶(Cylinder)","code":"33"},
                    {"name":"罐(Can)","code":"34"},{"name":"锭(Ingot) Igts","code":"35"},{"name":"块(Pig)","code":"36"},{"name":"管(Pipe)","code":"37"},
                    {"name":"条、棒(Bar)","code":"38"},{"name":"张(Sheet) Shts","code":"39"},{"name":"个、件(Piece) Pcs","code":"40"},{"name":"头、匹(Head) Hds","code":"41"},{"name":"裸装(Unpacked)","code":"42"}
                ];
            }
        }
    }
})();
