
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsTrackStatusDetailController', OpsTrackStatusDetailController);

    OpsTrackStatusDetailController.$inject = ['$scope', '$rootScope', '$translate', '$uibModalInstance', '$timeout',
    'entity', 'Guide', 'DateUtils', 'DataUtils', 'CustomNode'];

    function OpsTrackStatusDetailController($scope, $rootScope, $translate, $uibModalInstance, $timeout,
        entity, Guide, DateUtils, DataUtils, CustomNode) {

        $scope.status = entity.status;
        $scope.nowTime = new Date();
        $scope.nodeAction = entity.nodeAction;

        $scope.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $timeout(function() {
            if(entity.guide){
                rqCheck(angular.copy(entity.guide));
            }
            else if(entity.guides && entity.guides.length > 0){
                angular.forEach(entity.guides, function(data){
                    rqCheck(angular.copy(data));
                })
            }
            CustomNode.getAfCustomNodes({
                companyId: $rootScope.account.companyId
            }, function(result){
                angular.forEach(result, function(customNode){
                    $scope.opNodes.push({'key': customNode, 'value': customNode});
                });
            })
        });

        function rqCheck(checkGuide){
            if(!checkGuide.id){
                return;
            }
            var fdName = DataUtils.getGuideNodeName(entity.status);
            Guide.getGuideAndRates({
                id: checkGuide.id
            }, function(result) {
                if(result[fdName]){
                    return;
                }
                result[fdName] = new Date();
                var langName = $rootScope.RQ_CHECK(result);
                if(langName){
                    langName = result.jobNum + " - " + langName;
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.ndRequiredAlert', {param: langName}));
                    $scope.clear();
                }
            })
        }

        $scope.isSaving = false;
        $scope.save = function() {
            $scope.isSaving = true;
            layer.msg("Node(s) Updating...", {
              shade: 0.3,
              time: 200000
            });
            if(entity.guide){
                var orderNodeFilter = {
                    guideId: entity.guide.id,
                    nodeName: $scope.status,
                    nodeTime: $scope.nowTime
                };
                Guide.updateStatusOne({}, orderNodeFilter, function(result) {
                    layer.closeAll();
                    $scope.isSaving = false;
                    $uibModalInstance.close(result);
                }, function(result) {
                    layer.closeAll();
                    $scope.isSaving = false;
                    $rootScope.ALERT_ERROR(result);
                });
            }
            else {
                var guidesCopy = angular.copy(entity.guides);
                var fd = DataUtils.getGuideNodeName($scope.status);
                for(var i = guidesCopy.length - 1; i >= 0; i--){
                    var fdValue = guidesCopy[i][fd];
                    if($scope.status == "SiReceived" && guidesCopy[i].booking){
                        fdValue = guidesCopy[i].booking[fd];
                    }
                    if (!fdValue && !$scope.nowTime){
                        guidesCopy.splice(i, 1);
                    }
                }
                Guide.setStatusAll({
                    field: fd,
                    nodeAction: entity.nodeAction,
                    status: $scope.status,
                    nodeTime: $scope.nowTime
                }, guidesCopy, function(result) {
                    layer.closeAll();
                    $scope.isSaving = false;
                    $uibModalInstance.close(result);
                }, function(result) {
                    layer.closeAll();
                    $scope.isSaving = false;
                    layer.msg($translate.instant('global.save_fail'));
                });
            }
        }

        $scope.opNodes = [
            {'key': $translate.instant("autopsApp.guide.sts.Booked"), 'value': 'Booked'},
            {'key': $translate.instant("autopsApp.guide.sts.SoReleased"), 'value': 'SoReleased'},
            {'key': $translate.instant("autopsApp.guide.ANSent"), 'value': 'anSent'},
            {'key': $translate.instant("autopsApp.guide.DOSent"), 'value': 'DoReleased'},
            {'key': $translate.instant("autopsApp.guide.sts.stowageDone"), 'value': 'stowageDone'},
            {'key': $translate.instant("autopsApp.guide.sts.TruckArranged"), 'value': 'TruckArranged'},
            {'key': $translate.instant("autopsApp.guide.sts.wmsIn"), 'value': 'wmsIn'},
            {'key': $translate.instant("autopsApp.guide.sts.plConfirmed"), 'value': 'plConfirmed'},
            {'key': $translate.instant("autopsApp.guide.sts.wmsOut"), 'value': 'wmsOut'},
            {'key': $translate.instant("autopsApp.guide.sts.CtnStuffed"), 'value': 'CtnStuffed'},
            {'key': $translate.instant("autopsApp.guide.sts.CustomArranged"), 'value': 'CustomArranged'},
            {'key': $translate.instant("autopsApp.guide.sts.customsInspectionExp"), 'value': 'customsInspectionExp'},
            {'key': $translate.instant("autopsApp.guide.sts.CustomReleased"), 'value': 'CustomReleased'},
            {'key': $translate.instant("autopsApp.guide.sts.vgmSent"), 'value': 'vgmSent'},
            {'key': $translate.instant("autopsApp.guide.sts.atd"), 'value': 'atd'},
            {'key': $translate.instant("autopsApp.guide.sts.SiReceived"), 'value': 'SiReceived'},
            {'key': $translate.instant("autopsApp.guide.sts.SiSubmitted"), 'value': 'SiSubmitted'},
            {'key': $translate.instant("autopsApp.guide.sts.HblConfirmed"), 'value': 'HblConfirmed'},
            {'key': $translate.instant("autopsApp.guide.sts.MblConfirmed"), 'value': 'MblConfirmed'},
            {'key': $translate.instant("autopsApp.guide.sts.DnSent"), 'value': 'DnSent'},
            {'key': $translate.instant("autopsApp.guide.sts.SaSent"), 'value': 'SaSent'},
            {'key': $translate.instant("autopsApp.guide.sts.arrivedPod"), 'value': 'arrivedPod'},
            {'key': $translate.instant("autopsApp.guide.sts.customsInspection"), 'value': 'customsInspection'},
            {'key': $translate.instant("autopsApp.guide.sts.customCleared"), 'value': 'customCleared'},
            {'key': $translate.instant("autopsApp.guide.sts.ctnAvailable"), 'value': 'ctnAvailable'},
            {'key': $translate.instant("autopsApp.guide.sts.transferOut"), 'value': 'transferOut'},
            {'key': $translate.instant("autopsApp.guide.sts.deliveryAppointed"), 'value': 'deliveryAppointed'},
            {'key': $translate.instant("autopsApp.guide.sts.emsPickup"), 'value': 'emsPickup'},
            {'key': $translate.instant("autopsApp.guide.sts.Deliveried"), 'value': 'Deliveried'},
            {'key': $translate.instant("autopsApp.guide.sts.ctnReturned"), 'value': 'ctnReturned'},
            {'key': $translate.instant("autopsApp.guide.sts.isClosed"), 'value': 'isClosed'},
            {'key': $translate.instant("autopsApp.guide.sts.Cancelled"), 'value': 'Cancelled'},
            {'key': $translate.instant("autopsApp.guide.sts.voided"), 'value': 'voided'},
            {'key': $translate.instant("autopsApp.guide.sts.paymentReceived"), 'value': 'paymentReceived'},
            {'key': $translate.instant("autopsApp.guide.sts.FreightPaid"), 'value': 'FreightPaid'},
            {'key': $translate.instant("autopsApp.guide.sts.InvoiceReceived"), 'value': 'InvoiceReceived'},
            {'key': $translate.instant("autopsApp.guide.sts.InvoiceIssued"), 'value': 'InvoiceIssued'},
            {'key': $translate.instant("autopsApp.guide.etd"), 'value': 'etd'},
            {'key': $translate.instant("autopsApp.guide.eta"), 'value': 'eta'}
        ]


    }
})();
