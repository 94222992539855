(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('AutoCheck', AutoCheck);

    AutoCheck.$inject = ['$resource', 'DateUtils', '$translate'];

    function AutoCheck ($resource, DateUtils, $translate) {
        var resourceUrl =  'api/auto-checks/:id';
        function initRatesResponse(result){
            if(result){
                result = angular.fromJson(result); 
                angular.forEach(result, function(data){
                    data.etd = DateUtils.convertLocalDateFromServer(data.etd);
                    data.atd = DateUtils.convertLocalDateFromServer(data.atd);
                    data.deadline = DateUtils.convertLocalDateFromServer(data.deadline);
                    data.eta = DateUtils.convertLocalDateFromServer(data.eta);
                    data.deliveryDate = DateUtils.convertLocalDateFromServer(data.deliveryDate);
                    data.createDate = DateUtils.convertLocalDateFromServer(data.createDate);
                    data.billDate = DateUtils.convertLocalDateFromServer(data.billDate);
                    data.bizDate = DateUtils.convertLocalDateFromServer(data.bizDate);
                    data.billMakeDate = DateUtils.convertLocalDateFromServer(data.billMakeDate);
                    data.cargoReadyDate = DateUtils.convertLocalDateFromServer(data.cargoReadyDate);
                    data.wmsInTime = DateUtils.convertDateTimeFromServer(data.wmsInTime);
                    data.wmsOutTime = DateUtils.convertDateTimeFromServer(data.wmsOutTime);
                    data.hblReleasedTime = DateUtils.convertDateTimeFromServer(data.hblReleasedTime);
                    data.mblReleasedTime = DateUtils.convertDateTimeFromServer(data.mblReleasedTime);
                    data.inputTime = DateUtils.convertDateTimeFromServer(data.inputTime);
                    data.paidTime = DateUtils.convertDateTimeFromServer(data.paidTime);       
                    data.invoiceTime = DateUtils.convertDateTimeFromServer(data.invoiceTime);                
                })
            }
            return result;
        }
        function initRequest(data){
            data = angular.copy(data);
            data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
            data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
            return angular.toJson(data);
        }
        function initResponse(result){
            if (result) {
                result = angular.fromJson(result);
                result.dateFrom = DateUtils.convertLocalDateFromServer(result.dateFrom);
                result.dateTo = DateUtils.convertLocalDateFromServer(result.dateTo);
                result.timeFm = DateUtils.convertDateTimeFromServer(result.timeFm); 
                result.timeTo = DateUtils.convertDateTimeFromServer(result.timeTo); 
            }
            return result;
        }
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    return initRequest(data);
                },
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'getGuideRatesForInvoiceBatch': {
                method:'PUT',
                url:'api/auto-checks/getGuideRatesForInvoiceBatch', 
                isArray: true
            },
            'getDnCn': {
                method:'PUT',
                url:'api/auto-checks/getDnCn'
            },
            'getWriteOffRates': {
                method:'PUT',
                url:'api/auto-checks/getWriteOffRates', 
                isArray: true
            },
            'confirmChecking': {
                method:'PUT',
                url:'api/auto-checks/confirmChecking'
            }, 
            'getDnCnByStatementCode': {
                method:'GET',
                url:'api/auto-checks/getDnCnByStatementCode'
            },
            'getAllPayCurrencys': {
                method:'PUT',
                url:'api/auto-checks/getAllPayCurrencys',
                isArray:true,
            },         
            'clientChecking': {
                method:'GET',
                url:'api/auto-checks/clientChecking',
                isArray:true,
            },
            'getDnCnsForCom': {
                method:'PUT',
                url:'api/auto-checks/getDnCnsForCom'
            },
            'updateStatus': {
                method:'POST',
                url:'api/auto-checks/updateStatus'
            },
            'chargeOrRefund': {
                method:'POST',
                url:'api/auto-checks/chargeOrRefund'
            },    
            'amassAutoDiffAdjust': {
                method:'POST',
                url:'api/auto-checks/amassAutoDiffAdjust'
            },                
            'setMcBillNum': {
                method:'POST',
                url:'api/auto-checks/setMcBillNum',
                transformRequest: function (data) {
                    return initRequest(data);
                },
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },                
            'fillGuideRates': {
                method:'POST',
                url:'api/auto-checks/fillGuideRates'
            }, 
            'refreshParcelCheck': {
                method:'POST',
                url:'api/auto-checks/refreshParcelCheck'
            },                     
            'deleteAutoCheckItems': {
                method:'DELETE',
                url:'api/auto-checks/deleteAutoCheckItems'
            },
            'upateMemo': {
                method:'GET',
                url:'api/auto-checks/upateMemo'
            },
            'getGroupCurrentObj': {
                method:'POST',
                url:'api/auto-checks/getGroupCurrentObj'
            },
            'getUnBilledAmt': {
                method:'PUT',
                url:'api/auto-checks/getUnBilledAmt',
                transformRequest: function (data) {
                    return initRequest(data);
                }
            },
            'getUnbilledResult': {
                method:'PUT',
                url:'api/auto-checks/getUnbilledResult',
                transformRequest: function (data) {
                    return initRequest(data);
                }
            },
            'autoCheckGet': {
                method:'PUT',
                url:'api/auto-checks/autoCheckGet',
                isArray:true,
                transformRequest: function (data) {
                    return initRequest(data);
                },
                transformResponse: function (data) {
                    return initRatesResponse(data);
                }
            },
            'autoheckSearch': {
                method:'PUT',
                url:'api/auto-checks/autoheckSearch',
                isArray:true,
                transformRequest: function (data) {
                    return initRequest(data);
                },
                transformResponse: function (data) {
                    if(data){
                        data = angular.fromJson(data); 
                    }                    
                    return data;
                }
            },
            'monthlyCheck': {
                method:'PUT',
                url:'api/auto-checks/monthlyCheck',
                isArray:true,
                transformRequest: function (data) {
                    return initRequest(data);
                },
                transformResponse: function (data) {
                    return initRatesResponse(data);
                }
            },
            'getRatesByRefNum': {
                method:'POST',
                url:'api/auto-checks/getRatesByRefNum',
                isArray:true,
                transformRequest: function (data) {
                    return initRequest(data);
                },
                transformResponse: function (data) {
                    if(data){
                        data = angular.fromJson(data); 
                    }                    
                    return data;
                }
            },
            'importMore': {
                method:'PUT',
                url:'api/auto-checks/importMore',
                isArray:true,
                transformRequest: function (data) {
                    return initRequest(data);
                },
                transformResponse: function (data) {
                    if(data){
                        data = angular.fromJson(data); 
                    }                    
                    return data;
                }
            },            
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    return initRequest(data);
                },
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'advancedSearch': {
                method: 'POST',
                url: 'api/auto-checks/advancedSearch',
                isArray: true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFromFM = DateUtils.convertLocalDateToServer(data.dateFromFM);
                    data.dateFromTO = DateUtils.convertLocalDateToServer(data.dateFromTO);
                    data.dateToFM = DateUtils.convertLocalDateToServer(data.dateToFM);
                    data.dateToTO = DateUtils.convertLocalDateToServer(data.dateToTO);
                    return angular.toJson(data);
                }
            },
            'createOaApplication': {
                method: 'PUT',
                url: 'api/auto-checks/createOaApplication'
            }
        });
    }
})();
