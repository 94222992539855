(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('OpsVender', OpsVender);

    OpsVender.$inject = ['$resource','DateUtils'];

    function OpsVender($resource, DateUtils) {
        return $resource('api/opsVenders/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if(!data){
                        return;
                    }
                    data = angular.fromJson(data);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    data.nextTime = DateUtils.convertDateTimeFromServer(data.nextTime);
                    data.checkDate = DateUtils.convertLocalDateFromServer(data.checkDate);
                    data.membershipValidity = DateUtils.convertLocalDateFromServer(data.membershipValidity);
                    if (data.opsCrms){
                        for(var i=0; i<data.opsCrms.length; i++){
                            data.opsCrms[i].actionTime = DateUtils.convertDateTimeFromServer(data.opsCrms[i].actionTime);
                            data.opsCrms[i].nextTime = DateUtils.convertDateTimeFromServer(data.opsCrms[i].nextTime);
                        }
                    }
                    return data;
                }
            },
            'getSubsysClient': {
                method: 'GET',
                url:'api/opsVenders/getSubsysClient'
            },
            'initBizBySubSysUser': {
                method: 'GET',
                url:'api/opsVenders/initBizBySubSysUser'
            },
            'reDeductCharges': {
                method: 'GET',
                url:'api/opsVenders/reDeductCharges'
            },
            'checkDeductedCharges': {
                method: 'GET',
                url:'api/opsVenders/checkDeductedCharges'
            },
            'batchUpdate': {
                method: 'POST',
                url:'api/opsVenders/batchUpdate',
                isArray: true
            },
            'delHedgingBills': {
                method: 'GET',
                url:'api/opsVenders/delHedgingBills'
            },
            'initClientWmsCharges': {
                method: 'GET',
                url:'api/opsVenders/initClientWmsCharges'
            },
            'getDeposteAmt': {
                method: 'GET',
                url:'api/opsVenders/getDeposteAmt'
            },
            'autoCreditRating': {
                method: 'GET',
                url:'api/opsVenders/autoCreditRating'
            },
            'checkIdCode': {
                method: 'GET',
                url:'api/opsVenders/checkIdCode'
            },
            'getFilterPage': {
                method: 'POST',
                url:'api/opsVenders/getFilterPage',
                isArray: true
            },
            'getUserClients': {
                method: 'GET',
                url:'api/opsVenders/getUserClients',
                isArray: true
            },
            'deleteAll': {
                method: 'POST',
                url:'api/opsVenders/deleteAll'
            },
            'shareAll': {
                method: 'POST',
                url:'api/opsVenders/shareAll'
            },
            'auditAll': {
                method: 'POST',
                url:'api/opsVenders/auditAll'
            },
            'getAllFirstLetter': {
                method: 'GET',
                url:'api/opsVenders/getAllFirstLetter'
            },
            'notifySales': {
                method: 'PUT',
                url:'api/opsVenders/notifySales'
            },
            'setIsSuper': {
                method: 'POST',
                url:'api/opsVenders/setIsSuper'
            },
            'getAllClients': {
                method: 'GET',
                url:'api/opsVenders/clients',
                isArray: true
            },
            'getEmailById': {
                method: 'GET',
                url:'api/opsVenders/getEmailById'
            },
            'subSysClients': {
                method: 'GET',
                url:'api/opsVenders/subSysClients',
                isArray: true
            },
            'getContactsList': {
                method: 'GET',
                url:'api/opsVenders/contactList',
                isArray: true
            },
            'getOneContact': {
                method: 'GET',
                url:'api/contactList/getOneContact'
            },
            'saveRemarks': {
                method: 'GET',
                url:'api/opsVenders/saveRemarks'
            },
            'getOpsFactorys': {
                method: 'GET',
                url:'api/opsVenders/factorys',
                isArray: true
            },
            'getBranchVender': {
                method: 'GET',
                url:'api/opsVenders/getBranchVender'
            },
            'getDTO': {
                method: 'GET',
                url:'api/opsVenders/venderDTO/:id',
                transformResponse: function (data) {
                    if(!data){
                        return;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getOpsVenderDTO': {
                method: 'GET',
                url:'api/opsVenders/opsVenderDTO/:id',
                transformResponse: function (data) {
                    if(!data){
                        return;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getInvoiceVenderDTO': {
                method: 'GET',
                url:'api/opsVenders/invoiceVenderDTO/:id',
                transformResponse: function (data) {
                    if(!data){
                        return;
                    }
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getCrm': {
                method: 'GET',
                url:'api/opsVenders/crm/:id',
                transformResponse: function (data) {
                    if(!data){
                        return;
                    }
                    data = angular.fromJson(data);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    data.nextTime = DateUtils.convertDateTimeFromServer(data.nextTime);
                    data.checkDate = DateUtils.convertLocalDateFromServer(data.checkDate);
                    if (data.opsCrms){
                        for(var i=0; i<data.opsCrms.length; i++){
                            data.opsCrms[i].actionTime = DateUtils.convertDateTimeFromServer(data.opsCrms[i].actionTime);
                            data.opsCrms[i].nextTime = DateUtils.convertDateTimeFromServer(data.opsCrms[i].nextTime);
                        }
                    }
                    return data;
                }
            },
            'getLogin': {
                method:'POST',
                url:'api/opsVenders/getLogin'
            },
            'changeCompany': {
                method: 'GET',
                url:'api/opsVenders/changeCompany'
            },
            'shiftSales': {
                method:'PUT',
                url:'api/opsVenders/shift'
            },
            'shiftAll': {
                method:'GET',
                url:'api/opsVenders/shiftAll'
            },
            'combineObj': {
                method: 'GET',
                url:'api/opsVenders/combineObj'
            },
            'setAudited': {
                method:'PUT',
                url:'api/opsVenders/setAudited'
            },
            'setlocked': {
                method:'PUT',
                url:'api/opsVenders/setlocked'
            },
            'loginDisabled': {
                method:'PUT',
                url:'api/opsVenders/loginDisabled'
            },
            'getSearchResult': {
                method:'PUT',
                url:'api/opsVenders/getSearchResult',
                isArray: true
            },
            'getAllLocation': {
                method:'GET',
                url:'api/opsVenders/getAllLocation',
                isArray: true
            },
            'checkRepeat': {
                method: 'GET',
                url:'api/opsVenders/checkRepeat',
                isArray: true
            },
            'update': {
                method:'PUT',
                transformRequest: function (data) {
                    data.checkDate = DateUtils.convertLocalDateToServer(data.checkDate);
                    data.membershipValidity = DateUtils.convertLocalDateToServer(data.membershipValidity);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if(!data){
                        return;
                    }
                    data = angular.fromJson(data);
                    data.membershipValidity = DateUtils.convertLocalDateFromServer(data.membershipValidity);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    data.nextTime = DateUtils.convertDateTimeFromServer(data.nextTime);
                    data.checkDate = DateUtils.convertLocalDateFromServer(data.checkDate);
                    if (data.opsCrms){
                        for(var i=0; i<data.opsCrms.length; i++){
                            data.opsCrms[i].actionTime = DateUtils.convertDateTimeFromServer(data.opsCrms[i].actionTime);
                            data.opsCrms[i].nextTime = DateUtils.convertDateTimeFromServer(data.opsCrms[i].nextTime);
                        }
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.membershipValidity = DateUtils.convertLocalDateToServer(data.membershipValidity);
                    data.checkDate = DateUtils.convertLocalDateToServer(data.checkDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (!data){
                        return;
                    }
                    data = angular.fromJson(data);
                    data.membershipValidity = DateUtils.convertLocalDateFromServer(data.membershipValidity);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    data.nextTime = DateUtils.convertDateTimeFromServer(data.nextTime);
                    data.checkDate = DateUtils.convertLocalDateFromServer(data.checkDate);
                    if (data.opsCrms){
                        for(var i=0; i<data.opsCrms.length; i++){
                            data.opsCrms[i].actionTime = DateUtils.convertDateTimeFromServer(data.opsCrms[i].actionTime);
                            data.opsCrms[i].nextTime = DateUtils.convertDateTimeFromServer(data.opsCrms[i].nextTime);
                        }
                    }
                    return data;
                }
            },
            'getVenderBizClient': {
                method: 'GET',
                url:'api/venderBizClient',
                isArray: true
            },
            'deleteVenderBizClient': {
                method: 'DELETE',
                url:'api/venderBizClient/:id'
            }
        });
    }
})();
