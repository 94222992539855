(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountBindDetailController', AccountBindDetailController);

    AccountBindDetailController.$inject = ['$scope', '$rootScope', '$timeout', '$translate', '$uibModal', '$uibModalInstance',
    'entity', 'AccountBind', 'ExpressParcel','selectData'];

    function AccountBindDetailController($scope, $rootScope, $timeout, $translate, $uibModal, $uibModalInstance,
        entity, AccountBind, ExpressParcel,selectData) {
        var vm = this;
        vm.clear = clear;
        vm.createOne = createOne;
        vm.editOne = editOne;
        vm.delOne = delOne;
        vm.getOrders = getOrders;
        vm.synInventory = synInventory;
        vm.pushInventory = pushInventory;

        vm.pushOrders = pushOrders;
        vm.showSynInventory = showSynInventory;
        vm.showPushInventory = showPushInventory;

        var bindType = entity;
        var selectData = selectData;

        $timeout(function (){
            if($rootScope.HAVENOAUTH("WMS_OVERSEAS")){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                clear();
            } else {
                getAll();
            }
        });
        vm.accountBinds = [];
        function getAll () {
            AccountBind.getAll({type: bindType}, function(resultList){
                vm.accountBinds = resultList;
                if(!vm.accountBinds || vm.accountBinds.length == 0){
                    createOne ();
                }
            });
        }
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        var synInventoryTypes = ["mbWms", "chukou1", "ecang", "anmei", "logiwa", "aidelivery", "auwms", "westernpost", "MfyCang", "JDFOP", "ShipOut"];
        var pushInventoryTypes = ["mbWms"];

        function showSynInventory (type) {
            return synInventoryTypes.indexOf(type) !== -1;
        }
        function showPushInventory (type) {
            return pushInventoryTypes.indexOf(type) !== -1;
        }

        function getOrders (accountBind) {
            $uibModal.open({
                templateUrl: 'app/entities/express-cargo/express-cargos.html',
                controller: 'ExpressCargoController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: function () {
                        return accountBind;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('stats');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
            }, function(result) {
            });
        }

        function pushOrders (accountBind) {
            $uibModal.open({
                templateUrl: 'app/entities/express-cargo/push-orders.html',
                controller: 'PushOrdersController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: function () {
                        return accountBind;
                    },
                    extraData: function () {
                        return selectData;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('stats');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
            }, function(result) {
            });
        }


        function createOne () {
            $uibModal.open({
                templateUrl: 'app/entities/account-bind/account-bind-dialog.html',
                controller: 'AccountBindDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: function () {
                        return {
                            type: bindType
                        };
                    }
                }
            }).result.then(function() {
                getAll();
            }, function() {
            });
        }

        function editOne (accountBind) {
            $uibModal.open({
                templateUrl: 'app/entities/account-bind/account-bind-dialog.html',
                controller: 'AccountBindDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return AccountBind.get({id : accountBind.id}).$promise;
                    }]
                }
            }).result.then(function() {
                getAll();
            }, function() {
            });
        }

        function delOne (accountBind) {
            $uibModal.open({
                templateUrl: 'app/entities/account-bind/account-bind-delete-dialog.html',
                controller: 'AccountBindDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return AccountBind.get({id : accountBind.id}).$promise;
                    }]
                }
            }).result.then(function() {
                getAll();
            }, function() {
                $state.go('^');
            });
        }
        var updateUnsubscribe = $rootScope.$on('autopsApp:accountBindUpdate', function(event, result) {
            if(result && result.id){
                var isNew = true;
                angular.forEach(vm.accountBinds, function(data){
                    if(data.id == result.id){
                        isNew = false;
                        angular.copy(result, data);
                    }
                })
                if(isNew){
                    vm.accountBinds.push(result);
                }
            }
        });
        $scope.$on('$destroy', updateUnsubscribe);

        function synInventory (accountBind) {
            $uibModal.open({
                templateUrl: 'app/entities/share-client/share-client-detail.html',
                controller: 'ShareClientDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return accountBind;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('skuBind');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {
            }, function() {
            });
        }

        function pushInventory (accountBind) {
            layer.load(1, {shade: 0.3});
            ExpressParcel.pushInventory(accountBind, function(result){
                layer.closeAll();
                layer.msg($translate.instant('global.messages.submitted'));
            }, $rootScope.ALERT_ERROR);
        }

    }
})();
