(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('wms-virtual', {
            parent: 'wms',
            url: '/wms-virtual?page&sort&search',
            data: {
                authorities: ['WMS_VIRTUAL'],
                pageTitle: 'autopsApp.wmsIn.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-in/wms-virtual.html',
                    controller: 'WmsVirtualController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('guide');
                    $translatePartialLoader.addPart('wmsWorkLog');
                    return $translate.refresh();
                }]
            }
        })
        .state('wms-in', {
            parent: 'wms',
            url: '/wms-app-in',
            data: {
                authorities: ['ROLE_USER', 'ROLE_CLIENT'],
                pageTitle: 'autopsApp.wmsIn.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-in/wms-ins.html',
                    controller: 'WmsInController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('guide');
                    $translatePartialLoader.addPart('wmsWorkLog');
                    return $translate.refresh();
                }]
            }
        })
        .state('wms-in-charges', {
            parent: 'wms',
            url: '/wms-storage-charges',
            data: {
                authorities: ['ROLE_USER', 'ROLE_CLIENT'],
                pageTitle: 'autopsApp.guide.wmsCharges'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-in/wms-in-charges-new.html',
                    controller: 'WmsInChargesNewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('guide');
                    $translatePartialLoader.addPart('wmsWorkLog');
                    $translatePartialLoader.addPart('stats');                    
                    return $translate.refresh();
                }]
            }
        })
        .state('wms-distribution', {
            parent: 'wms',
            url: '/wms-distribution',
            data: {
                authorities: ['ROLE_USER', 'ROLE_CLIENT'],
                pageTitle: 'global.menu.entities.distribution'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-in/wms-distribution.html',
                    controller: 'WmsDistributionController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('wmsOut');
                    $translatePartialLoader.addPart('guide');
                    $translatePartialLoader.addPart('wmsWorkLog');
                    return $translate.refresh();
                }]
            }
        })
        .state('wms-distribution.edit', {
            parent: 'wms-distribution',
            url: '/dialog/{id}',
            data: {
                authorities: ['ROLE_USER', 'ROLE_CLIENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/wms-in/wms-distribution-dialog.html',
                    controller: 'WmsDistributionDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['WmsIn', 'WinAES', function(WmsIn, WinAES) {
                            return WmsIn.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('wmsIn');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })        
        .state('wms-distribution.new', {
            parent: 'wms-distribution',
            url: '/new',
            data: {
                authorities: ['ROLE_USER', 'ROLE_CLIENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/wms-in/wms-distribution-dialog.html',
                    controller: 'WmsDistributionDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['$rootScope', function($rootScope) {
                            return {
                                id: null,
                                handleType: "TD",
                                cargoList: [{
                                    allOut:false, 
                                    action:"appIn"}],
                                type: "FCL",
                                isOut: false,
                                freeDays: $rootScope.account.freeDays,
                                status: "new",
                                allOut: false
                            };
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('wmsIn');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('wms-return-in', {
            parent: 'wms',
            url: '/wms-return-in',
            data: {
                authorities: ['WMS_RETURN'],
                pageTitle: 'autopsApp.wmsIn.home.returnTitle'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-in/wms-ins.html',
                    controller: 'WmsInController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('wmsWorkLog');
                    return $translate.refresh();
                }]
            }
        })
        .state('wms-in-out', {
            parent: 'wms',
            url: '/wms-app-out',
            data: {
                authorities: ['ROLE_USER', 'ROLE_CLIENT'],
                pageTitle: 'autopsApp.wmsIn.home.outTitle'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-in/wms-ins.html',
                    controller: 'WmsInController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('wmsWorkLog');
                    return $translate.refresh();
                }]
            }
        })
        .state('wms-ins-register', {
            parent: 'wms',
            url: '/wms-ins-register',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'autopsApp.wmsIn.registIn'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-in/wms-ins.html',
                    controller: 'WmsInController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('wmsWorkLog');
                    return $translate.refresh();
                }]
            }
        })
        .state('wms-outs-register', {
            parent: 'wms',
            url: '/wms-outs-register',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'autopsApp.wmsIn.registOut'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-in/wms-ins.html',
                    controller: 'WmsInController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('wmsWorkLog');
                    return $translate.refresh();
                }]
            }
        }) 
        .state('wms-ins-register.dialog', {
            parent: 'wms-ins-register',
            url: '/dialog/{id}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/wms-in/wms-in-register.html',
                    controller: 'WmsInRegisterController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['WmsIn', 'WinAES', function(WmsIn, WinAES) {
                            return WmsIn.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('wmsIn');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('wms-outs-register.dialog', {
            parent: 'wms-outs-register',
            url: '/dialog/{id}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/wms-in/wms-out-register.html',
                    controller: 'WmsOutRegisterController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['WmsIn', 'WinAES', function(WmsIn, WinAES) {
                            return WmsIn.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('wms-in.edit', {
            parent: 'wms-in',
            url: '/detail/{id}',
            data: {
                authorities: ['ROLE_USER', 'ROLE_CLIENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/wms-in/wms-in-detail.html',
                    controller: 'WmsInDetailController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['WmsIn', 'WinAES', function(WmsIn, WinAES) {
                            return WmsIn.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('wmsIn');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('wms-in.copy', {
            parent: 'wms-in',
            url: '/copy/{id}',
            data: {
                authorities: ['ROLE_USER', 'ROLE_CLIENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/wms-in/wms-in-detail.html',
                    controller: 'WmsInDetailController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['WmsIn', 'WinAES', function(WmsIn, WinAES) {
                            return WmsIn.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('wmsIn');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('wms-in.new', {
            parent: 'wms-in',
            url: '/new',
            data: {
                authorities: ['ROLE_USER', 'ROLE_CLIENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/wms-in/wms-in-detail.html',
                    controller: 'WmsInDetailController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: [function() {
                            return {id: null};
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('wmsIn');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('wms-return-in.rma-new', {
            parent: 'wms-return-in',
            url: '/rma-new',
            data: {
                authorities: ['ROLE_USER', 'ROLE_CLIENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/wms-in/wms-in-detail.html',
                    controller: 'WmsInDetailController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: [function() {
                            return {};
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('wmsIn');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('wms-return-in.rma-edit', {
            parent: 'wms-return-in',
            url: '/rma-edit/{id}',
            data: {
                authorities: ['ROLE_USER', 'ROLE_CLIENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/wms-in/wms-in-detail.html',
                    controller: 'WmsInDetailController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['WmsIn', 'WinAES', function(WmsIn, WinAES) {
                            return WmsIn.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('wmsIn');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('wms-return-in.rma-copy', {
            parent: 'wms-return-in',
            url: '/rma-copy/{id}',
            data: {
                authorities: ['ROLE_USER', 'ROLE_CLIENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/wms-in/wms-in-detail.html',
                    controller: 'WmsInDetailController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['WmsIn', 'WinAES', function(WmsIn, WinAES) {
                            return WmsIn.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('wmsIn');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('wms-in-out.edit', {
            parent: 'wms-in-out',
            url: '/detail/{id}',
            data: {
                authorities: ['ROLE_USER', 'ROLE_CLIENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/wms-in/wms-in-out-detail.html',
                    controller: 'WmsInOutDetailController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxl',
                    resolve: {
                        entity: ['WmsIn', 'WinAES', function(WmsIn, WinAES) {
                            return WmsIn.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('wmsIn');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('wms-in-out.new', {
            parent: 'wms-in-out',
            url: '/new',
            data: {
                authorities: ['ROLE_USER', 'ROLE_CLIENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/wms-in/wms-in-out-detail.html',
                    controller: 'WmsInOutDetailController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxl',
                    resolve: {
                        entity: [function() {
                            return {};
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('wmsIn');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('fms-inbound', {
            parent: 'wms',
            url: '/fms-inbound?page&sort&search',
            data: {
                authorities: ['ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_SALES', 'ROLE_DOCUMENT', 'ROLE_CS', 'ROLE_MARKET'],
                pageTitle: 'global.menu.entities.registIn'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-in/fms-wms.html',
                    controller: 'FmsWmsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('guide');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('expressParcel');
                    $translatePartialLoader.addPart('wmsWorkLog');
                    return $translate.refresh();
                }]
            }
        })
        .state('fms-outbound', {
            parent: 'wms',
            url: '/fms-outbound?page&sort&search',
            data: {
                authorities: ['ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_SALES', 'ROLE_DOCUMENT', 'ROLE_CS', 'ROLE_MARKET'],
                pageTitle: 'global.menu.entities.registOut'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-in/fms-wms.html',
                    controller: 'FmsWmsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('guide');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('expressParcel');
                    $translatePartialLoader.addPart('wmsWorkLog');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
