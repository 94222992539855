(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('HGuidesDialogController', HGuidesDialogController);

    HGuidesDialogController.$inject = ['$rootScope', '$scope', '$uibModalInstance', '$translatePartialLoader', '$translate', '$uibModal', '$timeout',
    'entity', 'Guide', 'OpsFiles', 'OpsReport', 'ExpressParcel', 'GridState', 'uiGridConstants', 'ApiErp', 'DataUtils', 'EdiInsurance'];

    function HGuidesDialogController ($rootScope, $scope, $uibModalInstance, $translatePartialLoader, $translate, $uibModal, $timeout,
        entity, Guide, OpsFiles, OpsReport, ExpressParcel, GridState, uiGridConstants, ApiErp, DataUtils, EdiInsurance) {

        $translatePartialLoader.addPart('expressTracking');
        $translate.refresh();

        var vm = this;
        $scope.consolParam = entity;

        vm.addToConsol = addToConsol;
        vm.clear = clear;
        var isVirtual = false;

        var gridStateObj = {};
        var gridStateCopy = null;

        $timeout(function (){
            if($rootScope.HAVENOAUTH("AU_VIEW_MHLIST")){
                $rootScope.FORBIDDEN_BY_ALERT('AU_VIEW_MHLIST');
                clear();
                return;
            }
            getConsoleShipments ();
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 98
            }, function(result){
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
            })
        });

        function getConsoleShipments () {
            layer.load(1, {shade: 0.3});
            ExpressParcel.getConsoleShipments({
                companyId: $rootScope.account.companyId,
                isFms: $scope.consolParam.isFms,
                masterId: $scope.consolParam.masterId
            }, function(result){
                layer.closeAll();
                $scope.gridOptions.data = result;
                if(!$scope.gridOptions.data || $scope.gridOptions.data.length == 0){
                    layer.msg($translate.instant('global.noRecord'));
                    clear();
                }
                else {
                    initData ();
                    resize();
                }
            }, function(result){
                layer.closeAll();
            });
        }
        $scope.mGuide = {};
        function initData () {
            angular.forEach($scope.gridOptions.data, function(data){
                if(data.isVirtual){
                    isVirtual = true;
                }
                if (data.id == $scope.consolParam.masterId) {
                    $scope.mGuide = data;
                }
                DataUtils.fillFdLabel(data);
            })
            if($rootScope.isYuyue($rootScope.account.companyId)){
                $rootScope.AMASS_CHECK_OVERSIZE($scope.gridOptions.data, false);
            }
            // $scope.gridOptions.isRowSelectable = function(row) {
            //     if (row.entity.id == $scope.consolParam.masterId) {
            //         return false;
            //     } else {
            //         return true;
            //     }
            // };
            $scope.gridApi.selection.clearSelectedRows();
            $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.OPTIONS);
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }


        $scope.removeFromConsol = function(consolShipment) {
            if(consolShipment.isClosed){
                return;
            }
            if (consolShipment.id == $scope.consolParam.masterId && $scope.gridOptions.data.length > 1){
                layer.msg($translate.instant('autopsApp.guide.home.deleteHAlert'));
                return;
            }
            var title = $translate.instant('autopsApp.guide.removeFromConsolAlert');
            //有没有分摊费用
            ExpressParcel.checkBeforeRemove(consolShipment, function(result){
                if(result.csmNum){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.removeFromConsolAlert3', {param: result.csmNum}));
                    return;
                }
                if(result.csIncluded){
                    title = $translate.instant('autopsApp.guide.removeFromConsolAlert2');
                }
                layer.confirm(title, {
                    icon: 3,
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                    }, function(index) {
                        if (consolShipment.id == $scope.consolParam.masterId){
                            removeMasterDo(consolShipment);
                        }
                        else {
                            removeFromConsolDo(consolShipment);
                        }
                        layer.close(index);
                    }, function() {
                });
            })
        }
        function removeMasterDo(consolShipment) {
            ExpressParcel.removeMaster({}, consolShipment, function(result){
                getConsoleShipments();
                layer.msg($translate.instant('global.delete_success'));
            }, $rootScope.ALERT_ERROR)
        }

        function removeFromConsolDo(guide) {
            ExpressParcel.removeFromConsol({
                houseId:guide.id,
                isFms: guide.guideId != null,
                mIsFms: $scope.consolParam.isFms
            }, function(result){
                getConsoleShipments();
                layer.msg($translate.instant('global.delete_success'));
            }, $rootScope.ALERT_ERROR)
        }

        $scope.removeSelectedFromConsol = function(guide) {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if(selectedRows.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            for(var i = selectedRows.length -1; i >= 0; i--){
                if (selectedRows[i].id == $scope.consolParam.masterId) {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.removeMasterAlert', {param: selectedRows[i].jobNum}));
                    return;
                }
            }
            layer.confirm($translate.instant('autopsApp.guide.removeFmConsolAlert', {param: selectedRows.length}), {
                icon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    ExpressParcel.removeSelectedFromConsol({
                        mIsFms: $scope.consolParam.isFms
                    }, selectedRows, function(result){
                        getConsoleShipments();
                        layer.msg($translate.instant('global.delete_success'));
                    }, function(result){
                        layer.msg($translate.instant('global.delete_fail'));
                    })
                    layer.close(index);
                }, function() {
            });
        }
        window.onresize = function(){
            resize();
        }

        function resize() {
            if(!$("#gridId") || !$("#gridId").offset()){
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 200;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            tabHeight -= 10;
            if(tabHeight < 300){
                tabHeight = 300;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        var unsubscribe = $rootScope.$on('autopsApp:refreshHblsEtd', function(event, result) {
            getConsoleShipments();
        });

        $scope.$on('$destroy', unsubscribe);

        function addToConsol (isFms) {
            layer.prompt({
                formType: 0,
                value: '',
                title: $translate.instant('autopsApp.guide.inputJobPoNum')
            }, function(value, index, elem) {
                layer.close(index);
                if($scope.consolParam.isFms){
                    ExpressParcel.addToGuideConsol({
                        companyId: $rootScope.account.companyId,
                        masterId: $scope.consolParam.masterId,
                        isVirtual: isVirtual,
                        jobNum: value,
                        isFms: isFms
                    }, onAddSuccess, $rootScope.ALERT_ERROR)
                }
                else {
                    ExpressParcel.addToParcelConsol({
                        companyId: $rootScope.account.companyId,
                        masterId: $scope.consolParam.masterId,
                        jobNum: value,
                        isVirtual: isVirtual,
                        isFms: isFms
                    }, onAddSuccess, $rootScope.ALERT_ERROR)
                }
            });
        }
        function onAddSuccess (result) {
            getConsoleShipments();
            layer.msg($translate.instant('global.importSuccess'));
        }


        function customSumExcludeMorder(aggregation, row, col){
            var sumValue = 0;
            var grid = this.grid;
            var rowField = row.field;
            if (grid.renderContainers && grid.renderContainers.body && grid.renderContainers.body.visibleRowCache) {
                angular.forEach(grid.renderContainers.body.visibleRowCache,function(row){
                    var isMaster = row.entity.isMaster;
                    var colValue = row.entity[rowField];
                    if (!isMaster) {
                        sumValue = sumValue + colValue;
                    }
                })
            }
            var formattedValue = parseFloat(sumValue.toFixed(4));
            return "sum: " + formattedValue.toString().replace(/(\.\d*?[1-9])0+$/, '$1').replace(/\.$/, '');
        }

        $rootScope.LOAD_REPORT_TEMPLATES();
        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: false,

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤

            enableHorizontalScrollbar : 1,//表格的水平滚动条
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            enableRowHeaderSelection : true,  //行选中
            enableRowSelection: true, //行选中
            enableSelectAll: true, //全部选中
            enableFullRowSelection : false, //行任意位置选中
            multiSelect: true,//多行选中

            exporterMenuCsv : false,
            exporterMenuPdf : false,
            exporterMenuLabel : "Export",
            exporterExcelFilename: 'consolidatedList.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],
            minimumColumnSize: 80,
            columnDefs: [
                { field: 'id',
                    width: 50,
                    displayName: "",
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    cellTemplate: '<button type="button" ng-click="grid.appScope.removeFromConsol(row.entity)" ng-disabled="grid.appScope.consoleLocked(row.entity)" class="btn btn-danger btn-grid ml-5"><span class="glyphicon glyphicon-remove" ng-hide="grid.appScope.consoleLocked(row.entity)"></span><span class="glyphicon glyphicon-lock" ng-if="grid.appScope.consoleLocked(row.entity)"></span></button>'},
                { field: 'jobNum',
                    displayName: 'Job#',
                    width: 150,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<span class="grid-text" ng-class="row.entity.oversize ? \'alert alert-warning\' : \'\'"><span ng-if="row.entity.id == grid.appScope.consolParam.masterId" class="text-danger">(M)</span><a href="" ng-click="grid.appScope.BIZPOP(row.entity.guideId)" ng-if="row.entity.guideId">{{row.entity.jobNum}}</a><a href="" ng-click="grid.appScope.VIEWEXPRESS(row.entity.parcelId)" ng-if="row.entity.parcelId">{{row.entity.jobNum}}</a><span class="glyphicon glyphicon-lock text-danger ml-4" ng-if="grid.appScope.consoleLocked(row.entity)" data-toggle="tooltip" data-placement="top" title="{{\'autopsApp.guide.consolidatedOrLocked\' | translate}}"></span></span>'},
                { field: 'checkStatus',
                    displayName: $translate.instant('autopsApp.guide.checkStatus'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CHECK_STATUS
                    },
                    cellTemplate: '<span class="grid-text" ng-if="row.entity.checkStatus"><span class="label label-info" ng-if="row.entity.checkStatus == \'notChecked\'" translate="autopsApp.guide.sts.notChecked">status</span><span class="label label-success" ng-if="row.entity.checkStatus == \'approved\'" translate="autopsApp.guide.sts.approved">status</span><span class="label label-danger" ng-if="row.entity.checkStatus == \'unapproved\'" translate="autopsApp.guide.sts.unapproved">status</span></span>'},
                { field: 'onHold',
                    displayName: $translate.instant('autopsApp.guide.onHold'),
                    width: 50,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="grid-text" ng-click="grid.appScope.consoleOnHoldShipmentPop(row.entity)"><span ng-if="row.entity.onHold"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.onHold"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'soNum',
                    displayName: 'SO#',
                    visible: false},
                { field: 'poNum',
                    displayName: 'PO#',
                    visible: false},
                { field: 'mblNum',
                    visible: false,
                    cellTemplate: '<span class="grid-text">{{row.entity.mblNum}}<span class="glyphicon glyphicon-ok-circle ml-5 text-primary"data-toggle="tooltip"data-placement="top"title="MBL released: {{row.entity.mblReleasedTime | date:\'medium\'}}"ng-if="row.entity.mblReleasedTime"></span></span>',
                    displayName: 'MBL/Mawb#'},
                { field: 'hblNum',
                    visible: false,
                    cellTemplate: '<span class="grid-text">{{row.entity.hblNum}}<span class="glyphicon glyphicon-ok-circle ml-5 text-primary"data-toggle="tooltip"data-placement="top"title="HBL released: {{row.entity.hblReleasedTime | date:\'medium\'}}"ng-if="row.entity.hblReleasedTime"></span></span>',
                    displayName: 'HBL/Hawb#'},
                { field: 'refNum',
                    displayName: 'EDI Ref#',
                    visible: false},
                { field: 'thirdpartyPlatformRef',
                    displayName: $translate.instant('autopsApp.guide.thirdpartyPlatformRef'),
                    visible: false},
                { field: 'clientSoNum',
                    displayName: $translate.instant('autopsApp.guide.clientWmsInNum'),
                    visible: false},
                { field: 'shipmentId',
                    displayName: "Shipment Id",
                    minWidth:60},
                { field: 'fbaRef',
                    displayName: "Reference Id",
                    minWidth:60},
                { field: 'gw',
                    width: 100,
                    type: 'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'GW',
                    cellTemplate: '<span class="grid-text">{{row.entity.gw}}<span class="ml-5" ng-if="row.entity.gwForecast">({{row.entity.gwForecast}})</span></span>',
                    aggregationType: customSumExcludeMorder},
                { field: 'vol',
                    width: 100,
                    type: 'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'VOL',
                    cellTemplate: '<span class="grid-text">{{row.entity.vol}}<span class="ml-5" ng-if="row.entity.volForecast">({{row.entity.volForecast}})</span></span>',
                    aggregationType: customSumExcludeMorder},
                { field: 'pkgNum',
                    width: 100,
                    type: 'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'PKGS',
                    cellTemplate: '<span class="grid-text">{{row.entity.pkgNum}}<span class="ml-5" ng-if="row.entity.pkgNumForecast">({{row.entity.pkgNumForecast}})</span></span>',
                    aggregationType: customSumExcludeMorder},
                { field: 'cw',
                    width: 100,
                    type: 'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'CW',
                    aggregationType: customSumExcludeMorder},
                { field: 'pallets',
                    width: 100,
                    type: 'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'Pallets',
                    aggregationType: customSumExcludeMorder},
                { field: 'shipmentType',
                    displayName: 'TYPE',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.FMS_PARCEL_TYPES
                    },
                    cellTemplate: '<span class="grid-text" ng-if="row.entity.shipmentType"><span class="label label-info" translate="global.{{row.entity.shipmentType}}"></span></span>'},
                { field: 'deliveryTo',
                    displayName: $translate.instant('autopsApp.guide.deliveryTo')},
                { field: 'shiptoCode',
                    displayName: $translate.instant('autopsApp.guide.shipToParty')},
                { field: 'destName',
                    displayName: $translate.instant('autopsApp.guide.destName')},
                { field: 'shiptoCountryCode',
                    displayName: $translate.instant('autopsApp.guide.destCountryCode')},
                { field: 'shiptoZipCode',
                    displayName: $translate.instant('autopsApp.guide.destPostCode')},
                { field: 'isResidential',
                    displayName: $translate.instant('autopsApp.expressParcel.isResidential'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.isResidential"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.isResidential"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'isRemote',
                    displayName: $translate.instant('autopsApp.guide.isRemote'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.isRemote"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.isRemote"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'insuranceRequired',
                    displayName: $translate.instant('autopsApp.guide.insuranceRequired'),
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.insuranceRequired"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.insuranceRequired"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'polName',
                    displayName: $translate.instant('autopsApp.guide.polName')},
                { field: 'warehouseName',
                    displayName: $translate.instant('autopsApp.guide.warehouse')},
                { field: 'cargoType',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CARGOTYPES
                    },
                    displayName: $translate.instant('autopsApp.guide.cargoType'),
                    cellTemplate: '<span class="grid-text" ng-if="row.entity.cargoTypeLabel"><span class="label label-info">{{row.entity.cargoTypeLabel}}</span></span>'},
                { field: 'createdTime',
                    displayName: $translate.instant('autopsApp.guide.home.createdTime'),
                    cellTemplate: '<span data-toggle="tooltip" data-placement="top" title="{{row.entity.createdTime | date:\'medium\'}}" class="grid-text">{{row.entity.createdTime | date:\'mediumDate\'}}</span>'},
                { field: 'cargoReadyDate',
                    displayName: $translate.instant('autopsApp.guide.cargoReadyDate')},
                { field: 'bookingEtd',
                    displayName: $translate.instant('autopsApp.guide.bookingEtd')},
                { field: 'wmsInTime',
                    displayName: $translate.instant('autopsApp.guide.wmsInTime'),
                    cellTemplate: '<span data-toggle="tooltip" data-placement="top" title="{{row.entity.wmsInTime | date:\'medium\'}}" class="grid-text">{{row.entity.wmsInTime | date:\'mediumDate\'}}</span>'},
                { field: 'etd',
                    displayName: 'ETD'},
                { field: 'customerName',
                    displayName: $translate.instant('autopsApp.guide.bookingPartyName')},
                { field: 'shipperName',
                    displayName: $translate.instant('autopsApp.guide.shipperName')},
                { field: 'productName',
                    displayName: $translate.instant('autopsApp.guide.productName')},
                { field: 'deliveryChannel',
                    displayName: $translate.instant('autopsApp.guide.deliveryChannel')},
                { field: 'salesName',
                    displayName: $translate.instant('autopsApp.guide.salesName')},
                { field: 'opName',
                    displayName: $translate.instant('autopsApp.guide.opName')},
                { field: 'docName',
                    displayName: $translate.instant('autopsApp.guide.docName')},
                { field: 'cusName',
                    displayName: $translate.instant('autopsApp.guide.cusName')},
                { field: 'overseasCs',
                    displayName: $translate.instant('autopsApp.guide.overseasCs')},
                { field: 'vsl',
                    displayName: 'Vessel'},
                { field: 'voy',
                    displayName: 'Voy/Flight'},
                { field: 'porName',
                    displayName: $translate.instant('autopsApp.guide.porName')},
                { field: 'memo',
                    displayName: $translate.instant('autopsApp.guide.table.memo')},
                { field: 'cargoName',
                    displayName: $translate.instant('autopsApp.guide.cargoName')},
                { field: 'innerRemark',
                    displayName: $translate.instant('autopsApp.guide.remarks')},
                { field: 'bookingRemark',
                    displayName: $translate.instant('autopsApp.booking.remarks')},
                { field: 'ownImporter',
                    displayName: $translate.instant('autopsApp.guide.ownImporter'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="grid-text"><span ng-if="row.entity.ownImporter"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.ownImporter"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'plConfirmed',
                    displayName: $translate.instant('autopsApp.booking.plConfirmed'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="grid-text"><span ng-if="row.entity.plConfirmed"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.plConfirmed"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'customsType',
                    displayName: $translate.instant('autopsApp.guide.customsType')},
                { field: 'bizType',
                    displayName: $translate.instant('autopsApp.guide.bizType'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.BIZTYPES
                    },
                    cellTemplate: '<span class="grid-text">{{row.entity.bizTypeLabel}}</span>'},
                { field: 'internalType',
                    displayName: $translate.instant('autopsApp.guide.bizInternalType'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.INTERNALTYPES
                    },
                    cellTemplate: '<span class="grid-text">{{row.entity.internalTypeLabel}}</span>'},
                { field: 'deliveryWay',
                    displayName: $translate.instant('autopsApp.booking.deliveryWay'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.DELIVERYTYPES
                    },
                    minWidth:100,
                    cellTemplate: '<span class="grid-text" translate="autopsApp.booking.deliveryType.{{row.entity.deliveryWay}}" ng-if="row.entity.deliveryWay" class="label-info label"></span>'}

            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 98;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "consolidatedList";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "consolidatedList";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                // gridApi.selection.on.rowSelectionChanged($scope,function(row){
                //     $scope.selectedRows = $scope.gridApi.selection.getSelectedRows();
                // });
                // gridApi.selection.on.rowSelectionChangedBatch($scope,function(rows){
                //     $scope.selectedRows = $scope.gridApi.selection.getSelectedRows();
                // });
            }
        }

        $scope.packingParcel = function () {
            if(!$scope.gridOptions.data){
                return;
            }
            layer.load(1, {shade: 0.3});
            OpsFiles.packingParcel({
                enCn: $translate.instant('global.enCn')
            }, $scope.gridOptions.data, function(result) {
                layer.closeAll();
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('autopsApp.guide.packingParcel') + ".xlsx");
            }, function(){
                layer.msg($translate.instant('global.loadFileFailed'));
                layer.closeAll();
            })
        }
        $scope.packingParcelT86 = function () {
            if(!$scope.gridOptions.data){
                return;
            }
            layer.load(1, {shade: 0.3});
            OpsFiles.packingParcelT86({
                enCn: $translate.instant('global.enCn')
            }, $scope.gridOptions.data, function(result) {
                layer.closeAll();
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('autopsApp.guide.packingParcel') + "T86.xlsx");
            }, function(){
                layer.msg($translate.instant('global.loadFileFailed'));
                layer.closeAll();
            })
        }
        $scope.OTELSZExportItems = function (id, isFms) {
            if (!id) {
                return;
            }
            layer.load(1, { shade: 0.3 });
            Guide.OTELSZExportItems({
                id: id,
                isFms: isFms
            }, function (result) {
                layer.closeAll();
                var masterJobNum = '';
                angular.forEach($scope.gridOptions.data, function (item) {
                    if (item.isMaster) {
                        masterJobNum = item.jobNum;
                    }
                })
                $rootScope.DOWNLOADEXCEL(result, masterJobNum + "_Items.xlsx");
            }, function () {
                layer.msg($translate.instant('global.loadFileFailed'));
                layer.closeAll();
            })
        }
        $scope.OTELSZExportVendorInvoiceRegistry = function (id, isFms, isPay) {
            if (!id) {
                return;
            }
            layer.load(1, { shade: 0.3 });
            Guide.OTELSZExportVendorInvoiceRegistry({
                id: id,
                isFms: isFms,
                isPay: isPay
            }, function (result) {
                layer.closeAll();
                var masterJobNum = '';
                angular.forEach($scope.gridOptions.data, function (item) {
                    if (item.isMaster) {
                        masterJobNum = item.jobNum;
                    }
                })
                var filename;
                if(isPay) {
                    filename = masterJobNum + "_AP.xlsx";
                }else {
                    filename = masterJobNum + "_AR.xlsx";
                }
                $rootScope.DOWNLOADEXCEL(result, filename);
            }, function () {
                layer.msg($translate.instant('global.loadFileFailed'));
                layer.closeAll();
            })
        }

        $scope.uploadFile = function(file, pathSuffix) {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if(selectedRows.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            angular.forEach(selectedRows, function(data) {
                var filePath;
                if(data.guideId){
                    filePath = 'REPORTS/GUIDE/' + data.guideId + pathSuffix;
                }
                if(data.parcelId){
                    filePath = '/expressParcel/' + data.parcelId + pathSuffix;
                }
                if(filePath){
                    $rootScope.UPLOAD_FILE_WITH_NAME(file, filePath, null, data.companyId, null);
                }
            })
        }
        $scope.consoleLocked = function(entity) {
            if(entity.isClosed){
                return true;
            }
            if(!entity.consolidated){
                return false;
            }
            if($scope.consolParam.isFms){
                return true;
            }
            if(entity.id == $scope.consolParam.masterId){
                return true;
            }
            return false;
        }
        $scope.uploadClientFile = function(file, pathSuffix) {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if(selectedRows.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            for(var i = selectedRows.length -1; i >= 0; i--){
                if (!selectedRows[i].bookingId) {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.noClientFileAlert', {param: selectedRows[i].jobNum}));
                    return;
                }
            }
            angular.forEach(selectedRows, function(data) {
                var filePath;
                if(data.bookingId){
                    filePath = 'REPORTS/BOOKING/' + data.bookingId + pathSuffix;
                }
                if(filePath){
                    $rootScope.UPLOAD_FILE_WITH_NAME(file, filePath, null, data.companyId, null);
                }
            })
        }
        $scope.DLXExportUnpackingList = function (id) {
            if (!id) {
                return;
            }
            layer.load(1, { shade: 0.3 });
            Guide.DLXExportUnpackingList({
                masterId: id
            }, function (result) {
                layer.closeAll();
                var masterJobNum = '';
                angular.forEach($scope.gridOptions.data, function (item) {
                    if (item.isMaster) {
                        masterJobNum = item.jobNum;
                    }
                })
                var filename = masterJobNum + "-Order List.xlsx";
                $rootScope.DOWNLOADEXCEL(result, filename);
            }, function () {
                layer.msg($translate.instant('global.loadFileFailed'));
                layer.closeAll();
            })
        }

        $scope.DLXExportInvoicePackingList = function (id) {
            if (!id) {
                return;
            }
            layer.load(1, { shade: 0.3 });
            Guide.DLXExportInvoicePackingList({
                guideId: id
            }, function (result) {
                layer.closeAll();
                var masterJobNum = '';
                angular.forEach($scope.gridOptions.data, function (item) {
                    if (item.isMaster) {
                        masterJobNum = item.jobNum;
                    }
                })
                var filename = masterJobNum + "柜号&packingList.xlsx";
                $rootScope.DOWNLOADEXCEL(result, filename);
            }, function () {
                layer.msg($translate.instant('global.loadFileFailed'));
                layer.closeAll();
            })
        }

        $scope.TPDExportInvoicePackingList = function (id) {
            if (!id) {
                return;
            }
            layer.load(1, { shade: 0.3 });
            Guide.TPDExportInvoicePackingList({
                guideId: id
            }, function (result) {
                layer.closeAll();
                var masterJobNum = '';
                angular.forEach($scope.gridOptions.data, function (item) {
                    if (item.isMaster) {
                        masterJobNum = item.jobNum;
                    }
                })
                var filename = masterJobNum + ".xlsx";
                $rootScope.DOWNLOADEXCEL(result, filename);
            }, function () {
                layer.msg($translate.instant('global.loadFileFailed'));
                layer.closeAll();
            })
        }
        $scope.DLXExportISFAndAMSInfo = function (id) {
            if (!id) {
                return;
            }
            layer.load(1, { shade: 0.3 });
            Guide.DLXExportISFAndAMSInfo({
                guideId: id
            }, function (result) {
                layer.closeAll();
                var masterJobNum = '';
                angular.forEach($scope.gridOptions.data, function (item) {
                    if (item.isMaster) {
                        masterJobNum = item.jobNum;
                    }
                })
                var filename = masterJobNum + "isf_ams.xlsx";
                $rootScope.DOWNLOADEXCEL(result, filename);
            }, function () {
                layer.msg($translate.instant('global.loadFileFailed'));
                layer.closeAll();
            })
        }

        $scope.customizeExcelExport = function(exportCustomize, isTmsParcel) {
            if(!$scope.gridOptions.data || $scope.gridOptions.data.length == 0){
                return;
            }
            var dataList = [];
            var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
            angular.forEach(allvisiblerows, function(row){
                dataList.push(row.entity);
            })
            var orderIds = [];
            angular.forEach(dataList, function(item) {
                if(isTmsParcel){
                    if(item.parcelId){
                        orderIds.push(item.parcelId);
                    }
                }
                else if(item.guideId){
                    orderIds.push(item.guideId);
                }
            })
            layer.load(1, { shade: 0.3 });
            GridState.customizeExportOrder({templateId: exportCustomize.id}, orderIds, function(result) {
                layer.closeAll();
                $rootScope.DOWNLOADEXCEL(result, exportCustomize.stateName + "_" + new Date().toLocaleString() + ".xlsx");
            }, $rootScope.ALERT_ERROR)
        }

        $scope.batchInsurancePacific = function() {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if(selectedRows.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            EdiInsurance.batchCreateInsurance (selectedRows, function(result) {
                layer.msg($translate.instant('global.messages.submitted'));
            }, $rootScope.ALERT_ERROR)
        }

    }
})();
