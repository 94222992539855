(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('GuideRates', GuideRates);

    GuideRates.$inject = ['$resource','DateUtils'];

    function GuideRates($resource, DateUtils) {
        return $resource('api/guideRatess/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.inputTime = DateUtils.convertDateTimeFromServer(data.inputTime);
                    data.lastModifiedTime = DateUtils.convertDateTimeFromServer(data.lastModifiedTime);
                    return data;
                }
            },
            'setAudit': {
                method:'GET',
                url:'api/guideRates/setAudit'
            },
            'setAssistAudit': {
                method:'GET',
                url:'api/assistRates/setAudit'
            } ,
            'setChecked': {
                method:'GET',
                url:'api/guideRates/setChecked'
            },
            'addByTemplate': {
                method:'PUT',
                url:'api/guideRates/addByTemplate'
            },
            'updatePltCharges': {
                method:'PUT',
                url:'api/guideRates/updatePltCharges'
            },
            'getDnCnMap': {
                method:'PUT',
                url:'api/guideRates/getDnCnMap'
            },
            'checkTimeStamp': {
                method:'GET',
                url:'api/guideRates/checkTimeStamp'
            },
            'refreshEconomicDate': {
                method:'GET',
                url:'api/guideRates/refreshEconomicDate'
            },
            'getDefaultPayer': {
                method:'GET',
                url:'api/guideRates/getDefaultPayer',
                isArray: true
            },
            'delList': {
                method:'PUT',
                url:'api/guideRates/delList'
            },
            'getGuideRatesDtoList': {
                method:'POST',
                url:'api/guideRates/getGuideRatesDtoList',
                isArray: true
            },
            'getWriteOffRatesByRatesId': {
                method:'GET',
                url:'api/guideRates/getWriteOffRatesByRatesId',
                isArray: true
            },
            'refreshDnGuideRates': {
                method:'POST',
                url:'api/guideRates/refreshDnGuideRates'
            },
            'getShipperRates': {
                method:'GET',
                url:'api/guideRates/getShipperRates'
            },
            'getSubtrackingCharges': {
                method:'GET',
                url:'api/guideRates/getSubtrackingCharges'
            },
            'delAllShareCost': {
                method:'GET',
                url:'api/guideRates/delAllShareCost'
            },
            'getHouseGuidesRates': {
                method:'GET',
                url:'api/guideRates/getHouseGuidesRates',
                isArray:true
            },
            'getUnpaidList': {
                method:'GET',
                url:'api/guideRates/getUnpaidList'
            },
            'refreshGuideRatesByDnNum': {
                method:'GET',
                url:'api/guideRates/refreshGuideRatesByDnNum'
            },
            'reShareCost': {
                method:'PUT',
                url:'api/guideRates/reShareCost'
            },
            'chargeOrRefundInMaster': {
                method: 'POST',
                url:'api/guideRates/chargeOrRefundInMaster'
            },
            'writeBackWmsChargesToGuide': {
                method: 'POST',
                url:'api/guideRates/writeBackWmsChargesToGuide'
            },
            'writeBackPodChargesToPol': {
                method: 'POST',
                url:'api/guideRates/writeBackPodChargesToPol'
            },
            'getRatesByRefNum': {
                method: 'POST',
                url:'api/guideRates/getRatesByRefNum',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.etdFM = DateUtils.convertLocalDateToServer(data.etdFM);
                    data.etdTO = DateUtils.convertLocalDateToServer(data.etdTO);
                    return angular.toJson(data);
                }
            },
            'getCargoShareRates': {
                method:'GET',
                url:'api/guideRates/getCargoShareRates',
                isArray:true
            },
            'getUnpaidSubshipmentCharges': {
                method:'GET',
                url:'api/consol/getUnpaidSubshipmentCharges',
                isArray:true
            },
            'hedgingUnpaidCharges': {
                method:'POST',
                url:'api/consol/hedgingUnpaidCharges'
            },
            'hedgingSharedCharges': {
                method:'GET',
                url:'api/consol/hedgingSharedCharges'
            },
            'shareToCargo': {
                method:'POST',
                url:'api/guideRates/shareToCargo'
            },
            'synVirtualRates': {
                method: 'GET',
                url:'api/guideRates/synVirtualRates',
                isArray:true
            },
            'getShareCargos': {
                method: 'POST',
                url:'api/guideRates/getShareCargos',
                isArray:true
            },
            'chargeOrRefund': {
                method: 'POST',
                url:'api/guideRates/chargeOrRefund'
            },
            'fastWriteOff': {
                method: 'POST',
                url:'api/guideRates/fastWriteOff',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.accountCashier.transactionDate = DateUtils.convertLocalDateToServer(data.accountCashier.transactionDate);
                    angular.forEach(data.writeOffRates, function(rate){
                        rate.etd = DateUtils.convertLocalDateToServer(rate.etd);
                    })
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (!data){return;}
                    data = angular.fromJson(data);
                    data.makeDate = DateUtils.convertLocalDateFromServer(data.makeDate);
                    return data;
                }
            },
            'ratesSubmit': {
                method:'PUT',
                url:'api/comGenerateDnCn'
            },
            'getByVid': {
                method:'GET',
                url:'api/guideRates/:id',
                params:{id:'@id'},
                isArray:true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getByJobNum': {
                method:'GET',
                url:'api/getRatesByJobNum',
                isArray:true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getForInvoiceBatch': {
                method: 'GET',
                url:'api/guideRates/getForInvoiceBatch',
                isArray: true
            },
            'newInvoice': {
                method:'PUT',
                url:'api/guideRatesInvoice',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.accountInvoice.deadLine = DateUtils.convertLocalDateToServer(data.accountInvoice.deadLine);
                    data.accountingDate = DateUtils.convertLocalDateToServer(data.accountingDate);
                    data.accountInvoice.economicDate = DateUtils.convertLocalDateToServer(data.accountInvoice.economicDate);
                    return angular.toJson(data);
                }
            },
            'newPayment': {
                method:'PUT',
                url:'api/guideRatesPayment',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.accountPayment.deadLine = DateUtils.convertLocalDateToServer(data.accountPayment.deadLine);
                    data.accountingDate = DateUtils.convertLocalDateToServer(data.accountingDate);
                    data.accountPayment.economicDate = DateUtils.convertLocalDateToServer(data.accountPayment.economicDate);
                    return angular.toJson(data);
                }
            },
            'getUnlockedRatesByAutoCheck': {
                method:'PUT',
                url:'api/guideRates/getUnlockedRatesByAutoCheck',
                isArray:true
            },
            'getUnlockedRatesByOpsBill': {
                method:'PUT',
                url:'api/guideRates/getUnlockedRatesByOpsBill',
                isArray:true
            },
            'chargesReport':{
                method:'PUT',
                url:'api/guideRates/chargesReport',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.etdFM = DateUtils.convertLocalDateToServer(data.etdFM);
                    data.etdTO = DateUtils.convertLocalDateToServer(data.etdTO);
                    data.accountDateFM = DateUtils.convertLocalDateToServer(data.accountDateFM);
                    data.accountDateTO = DateUtils.convertLocalDateToServer(data.accountDateTO);
                    return angular.toJson(data);
                }
            },
            'chargesCompare':{
                method:'PUT',
                url:'api/guideRates/chargesCompare',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    return angular.toJson(data);
                }
            },
            'chargesAging':{
                method:'PUT',
                url:'api/guideRates/chargesAging',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.cutDate = DateUtils.convertLocalDateToServer(data.cutDate);
                    data.etdFM = DateUtils.convertLocalDateToServer(data.etdFM);
                    data.etdTO = DateUtils.convertLocalDateToServer(data.etdTO);
                    data.etaFrom = DateUtils.convertLocalDateToServer(data.etaFrom);
                    data.etaTo = DateUtils.convertLocalDateToServer(data.etaTo);
                    data.atdFrom = DateUtils.convertLocalDateToServer(data.atdFrom);
                    data.atdTo = DateUtils.convertLocalDateToServer(data.atdTo);
                    return angular.toJson(data);
                }
            },
            'cancelVoucher': {
                method:'GET',
                url:'api/guideRatesCancelVoucher'
            },
            'getByVoucherId': {
                method:'GET',
                url:'api/guideRatesByVoucherId',
                isArray:true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getByDnNum': {
                method:'GET',
                url:'api/guideRatesByDnNum',
                isArray:true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getAllByInvoice': {
                method:'GET',
                url:'api/guideRates/getAllByInvoice',
                isArray:true
            },
            'getWrieteOffRatesByDnNum': {
                method:'GET',
                url:'api/getWrieteOffRatesByDnNum',
                isArray:true,
                transformResponse: function (data) {
                    if (!data){return;}
                    data = angular.fromJson(data);
                    if (data && data.length >0){
                        for(var i=0; i<data.length; i++){
                            data[i].etd = DateUtils.convertLocalDateFromServer(data[i].etd);
                        }
                    }
                    return data;
                }
            },
            'getRatesByMblNum': {
                method:'PUT',
                url:'api/guideRatess/getRatesByMblNum',
                isArray:false,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.etdFrom = DateUtils.convertLocalDateToServer(data.etdFrom);
                    data.etdEnd = DateUtils.convertLocalDateToServer(data.etdEnd);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'batchInvoice': {
                method:'PUT',
                url:'api/guideRates/batchInvoice',
                isArray:false
            },
            'getMonthlyPayment': {
                method:'PUT',
                url:'api/guideRates/getMonthlyPayment',
                isArray:false,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.etdFrom = DateUtils.convertLocalDateToServer(data.etdFrom);
                    data.etdEnd = DateUtils.convertLocalDateToServer(data.etdEnd);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'clientCheckingInvoice': {
                method:'PUT',
                url:'api/guideRates/clientCheckingInvoice',
                isArray:false,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.etdFrom = DateUtils.convertLocalDateToServer(data.etdFrom);
                    data.etdEnd = DateUtils.convertLocalDateToServer(data.etdEnd);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'clientCheckingByAutoCheck': {
                method:'PUT',
                url:'api/guideRates/clientCheckingByAutoCheck',
                isArray:false
            },
            'getChargesSyn': {
                method:'PUT',
                url:'api/chargessyn',
                isArray:false,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    // for(var i=0; i<data.length; i++){
                    //     data[i].etd = DateUtils.convertLocalDateFromServer(data[i].etd);
                    // }
                    return data;
                }
            },
            'checkWritOffNum': {
                method:'GET',
                url:'api/guideRates/checkWritOffNum'
            },
            'checkDeductedCharge': {
                method:'GET',
                url:'api/guideRates/checkDeductedCharge'
            },
            'udpateWithInvoiceNum': {
                method:'PUT',
                url:'api/guideRatess/udpateWithInvoiceNum',
                isArray: true
            },
            'getGuideRatesByIds': {
                method:'PUT',
                url:'api/guideRates/getGuideRatesByIds',
                isArray: true
            },
            'update': { method:'PUT', isArray: true},
            'save': { method:'POST', isArray: true},
            'getWriteOffRates': {
                method:'POST',
                url:'api/guideRates/getWriteOffRates',
                isArray: true
            },
            'getWriteOffRatesByDnNum': {
                method:'GET',
                url:'api/guideRates/getWriteOffRatesByDnNum',
                isArray: true
            },
            'HHExportChargesCompareExcel': {
                method: 'POST',
                url:'api/excel/hhExportChargesCompareExcel',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            }
        });
    }
})();
