(function() {
    'use strict';

    angular
        .module('autopsApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'ui.grid',
            'ui.grid.saveState',//EDI打单地址簿 172 工单处理中心 173  费用分摊到业务单 174 仓租 175
            'ui.grid.selection',
            'ui.grid.moveColumns',
            'ui.grid.resizeColumns',
            'ui.grid.exporter',
            'ui.grid.pinning',
            'ui.grid.grouping',
            'ui.grid.cellNav',
            'ui.grid.autoResize',
            'ui.grid.edit',
            'ui.grid.pagination',
            'angular-loading-bar',
            'ngDraggable'
        ])
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler'];

    function run(stateHandler, translationHandler) {
        stateHandler.initialize();
        translationHandler.initialize();
    }
})();
