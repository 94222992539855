(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsInChargesNewController', WmsInChargesNewController);

    WmsInChargesNewController.$inject = ['$scope', '$timeout', '$state', '$rootScope', '$translate', 
    'WmsIn', 'WmsWarehouse', 'OpsVender', 'DataUtils', 'uiGridConstants', 'uiGridGroupingConstants', 'GridState', 'OpsFiles'];

    function WmsInChargesNewController($scope, $timeout, $state, $rootScope, $translate, 
        WmsIn, WmsWarehouse, OpsVender, DataUtils, uiGridConstants, uiGridGroupingConstants, GridState, OpsFiles) {
        var vm = this;
        
        vm.wmsFilterDTO = {opType: "loadCharges"};
        
        vm.getAllWarehouses = getAllWarehouses;
        vm.warehouseSelected = warehouseSelected;
        vm.excelStorageCharge = excelStorageCharge;

        vm.getWmsCharges = getWmsCharges;
        function getAllWarehouses () {
            if (!vm.warehouses || vm.warehouses.length == 0){
                WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function (data) {
                    vm.warehouses = data;
                });
            }
        }

        function warehouseSelected (warehouse) {
            vm.wmsFilterDTO.warehouseId = warehouse.id;
        }

        $scope._onClientSelected = function(data) {
            vm.wmsFilterDTO.clientId = data.id;
        }

        function getWmsCharges () {
            if(!vm.wmsFilterDTO.shipperName){
                vm.wmsFilterDTO.clientId = null;
            }
            if(!vm.wmsFilterDTO.warehouseName){
                vm.wmsFilterDTO.warehouseId = null;
            }            
            if(vm.wmsFilterDTO.opType == "chargesGenerate"){
                layer.confirm($translate.instant('autopsApp.wmsCargo.wmsChargeTypes.chargesGenerateAlert'), {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                }, function(index) {
                    layer.close(index);
                    layer.load(1, { shade: 0.3 });
                    WmsIn.getWmsChargesNew(vm.wmsFilterDTO, onLoadDataSuccess, $rootScope.ALERT_ERROR);
                }, function() {
                });
            }
            else {
                layer.load(1, { shade: 0.3 });
                WmsIn.getWmsChargesNew(vm.wmsFilterDTO, onLoadDataSuccess, $rootScope.ALERT_ERROR);
            }
        }
        var onLoadDataSuccess = function(result){
            layer.closeAll();
            if(vm.wmsFilterDTO.opType == "chargesGenerate"){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsCargo.wmsChargeTypes.chargesGeneratedAlert'));
            }
            else if (!result || result.length == 0) {
                $scope.gridOptions.data = [];
                layer.msg($translate.instant('stats.stat.noData'));
                return;
            }
            $scope.gridOptions.data = result;   
            resize();   
        }
        window.onresize = function(){
            resize();
        }
        function resize() {
            var tabHeight = $scope.gridOptions.data.length * 30 + 190;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 680){
                tabHeight = 680;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }


        var filterTimer, gridCopy;
        $scope.gridOptions = {
            enableSorting: true,//排序
            enableRowHeaderSelection : true,  //行选中
            enableGridMenu : true,//表格 菜单  
            showGridFooter: true,//表格的footer  
            enableFiltering: true,//高级过滤
            showColumnFooter: true,//每列footer

            minRowsToShow: 20,
            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            enableRowSelection: true, //行选中
            enableSelectAll: true, //全部选中
            enableFullRowSelection : false, //行任意位置选中

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: $translate.instant('autopsApp.guide.wmsCharges') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],     
            columnDefs: [ 
                { field: 'sku', 
                    displayName: "SKU", 
                    minWidth:150},
                { field: 'skuCount',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100, 
                    displayName: $translate.instant('autopsApp.wmsCargo.skuCount')},
                { field: 'packageType', 
                    displayName: $translate.instant('autopsApp.wmsCargo.pkgsType'), 
                    minWidth:100},
                { field: 'gateInTime', 
                    displayName: $translate.instant('autopsApp.wmsIn.gateInTime'), 
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.gateInTime | date:\'medium\'}}">{{row.entity.gateInTime | date:\'mediumDate\'}}</span>',
                    minWidth:130},   
                { field: 'inventoryDays',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100, 
                    displayName: $translate.instant('autopsApp.wmsIn.inventoryDays')},
                { field: 'freeDays',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100, 
                    displayName: $translate.instant('autopsApp.wmsIn.freeDays')},
                { field: 'billDays',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100, 
                    displayName: $translate.instant('autopsApp.wmsIn.chargeDays')},
                { field: 'billingPeriod',
                    minWidth:200,
                    displayName: $translate.instant('autopsApp.wmsIn.billingPeriod')},
                { field: 'item',
                    minWidth:100,
                    displayName: $translate.instant('stats.stat.item')},
                { field: 'currency',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.currency')},
                { field: 'total',
                    minWidth:160, 
                    type:'number',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<span class="grid-text pull-right"><strong class="text-primary">{{grid.appScope.FORMAT_NUM(row.entity.total)}}</strong><span class="glyphicon glyphicon-ok-circle text-muted ml-4" ng-show="row.entity.paidOrDeducted"></span></span>',
                    displayName: $translate.instant('stats.stat.total')},
                { field: 'warehouseCode',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.warehouse')},                    
                { field: 'payer',
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.settlementObj')},
                { field: 'refNum',
                    minWidth:150, 
                    displayName: $translate.instant('autopsApp.guide.refNum')},
                { field: 'billRemarks', 
                    displayName: $translate.instant('autopsApp.wmsCargo.billRemarks'), 
                    minWidth:200},
                { field: 'length', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.length'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'width', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.width'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'height', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.height'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'vol', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.vol'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'gw', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.gw'), 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'cargoName', 
                    displayName: $translate.instant('autopsApp.wmsIn.cargoName'), 
                    minWidth:100},
                { field: 'cargoNameEn', 
                    displayName: $translate.instant('autopsApp.wmsIn.cargoNameEn'), 
                    minWidth:100},
                { field: 'code', 
                    displayName: $translate.instant('autopsApp.wmsIn.code'), 
                    minWidth:100},
                { field: 'status', 
                    displayName: $translate.instant('autopsApp.wmsIn.status'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [ 
                        { value: 'OK', label: 'OK'}, 
                        { value: 'NO', label: 'NO'}, 
                        { value: 'HD', label: 'HD'}]
                    },
                    minWidth:100},  
                { field: 'location', 
                    displayName: $translate.instant('autopsApp.wmsIn.location'), 
                    minWidth:100},   
                { field: 'locationType', 
                    displayName: $translate.instant('autopsApp.wmsIn.locationType'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.LOCATION_TYPES
                    },
                    cellTemplate: '<span translate="global.locationTypes.{{row.entity.locationType}}" ng-if="row.entity.locationType"></span>',
                    minWidth:100}, 
                { field: 'wmsInNum', 
                    displayName: $translate.instant('autopsApp.wmsIn.warehousingNumber'), 
                    minWidth:130},    
                { field: 'ctnNum', 
                    displayName: $translate.instant('autopsApp.wmsIn.ctnNum'), 
                    minWidth:100},
                { field: 'poNum', 
                    displayName: $translate.instant('autopsApp.wmsIn.poNum'), 
                    minWidth:100},
                { field: 'etd',
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.etd | date:\'mediumDate\'}}</span>',
                    displayName: $translate.instant('stats.stat.bizDate')},
                { field: 'inputBy',
                    minWidth:100,
                    displayName: $translate.instant('stats.stat.inputBy')},
                { field: 'inputTime',
                    minWidth:120,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.inputTime | date:\'medium\'}}">{{row.entity.inputTime | date:\'mediumDate\'}}</span>',
                    displayName: $translate.instant('autopsApp.wmsCargo.billDate')}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 175;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }     
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);                   
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "storageCharges";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);                   
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "storageCharges";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                $scope.gridApi.grid.registerColumnsProcessor(setGroupValues, 410);
                gridApi.selection.on.rowSelectionChanged($scope,function(row){

                });
                gridApi.selection.on.rowSelectionChangedBatch($scope,function(row){

                });
                gridApi.core.on.filterChanged($scope, function() {  
                    gridCopy = this.grid;
                    clearTimeout(filterTimer);
                    filterTimer = setTimeout(function(){                        
                        // getVisibelTtl();                            
                    }, 1000);//查询延时时间，单位ms 
                });
            }
        }
        var setGroupValues = function(columns, rows) {
            columns.forEach(function(column) {
                if (column.grouping && column.grouping.groupPriority > -1) {
                    // Put the balance next to all group labels.
                    column.treeAggregationFn = function(aggregation, fieldValue, numValue, row) {
                        if (typeof(aggregation.value) === 'undefined') {
                            aggregation.value = 0;
                            aggregation.count = 0;
                        }
                        aggregation.count++;
                        aggregation.value += row.entity.total;
                    };
                    column.customTreeAggregationFinalizerFn = function(aggregation) {
                        if (typeof(aggregation.groupVal) !== 'undefined') {
                            aggregation.value = $rootScope.FORMAT_NUM(DataUtils.round(aggregation.value, 2));
                            aggregation.rendered = aggregation.groupVal + ' (' + aggregation.count + "_" + (!$rootScope.account.baseCurrency ? "RMB" : $rootScope.account.baseCurrency) + aggregation.value + ")";                           
                        } else {
                            aggregation.rendered = null;
                        }
                    };
                }
            });
            return columns;
        };
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 175
            }, function(result){
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }   
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
            })
        });
        function excelStorageCharge() {
            layer.load(1, { shade: 0.3 });
            OpsFiles.excelStorageCharge($scope.gridOptions.data, function(result) {
                layer.closeAll();
                $rootScope.DOWNLOADEXCEL(result, "storage_charges.xlsx");
            }, $rootScope.ALERT_ERROR)
        }

        
    }
})();
