(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsVenderController', OpsVenderController);

    OpsVenderController.$inject = ['$scope', '$state', '$rootScope', '$translate', '$uibModal', '$location', '$http', '$timeout',
    'OpsVender', 'OpsVenderSearch', 'OpsFiles', 'Principal', 'uiGridConstants', 'GridState', 'Clerks', 'DataUtils'];

    function OpsVenderController($scope, $state, $rootScope, $translate, $uibModal, $location, $http, $timeout,
        OpsVender, OpsVenderSearch, OpsFiles, Principal, uiGridConstants, GridState, Clerks, DataUtils) {
        var vm = this;

        if(!$rootScope.filterType) {
            $rootScope.filterType = "self";
        }

        var path = $location.path();
        if (path.indexOf("client") >= 0){
            $rootScope.venderType = "client";
        }
        else if (path.indexOf("bookingAgent") >= 0){
            $rootScope.venderType = "bookingAgent";
        }
        else if (path.indexOf("overseasAgent") >= 0){
            $rootScope.venderType = "overseasAgent";
        }
        else if (path.indexOf("vender") >= 0){
            $rootScope.venderType = "vender";
        }
        else if (path.indexOf("all") >= 0){
            $rootScope.venderType = "all";
        }

        $scope.setFilter = function(type) {
            if (type == "all" && $rootScope.account.authorities.indexOf("FUN_VIEW_ALL_CLIENTS") == -1 && $rootScope.account.authorities.indexOf("ROLE_SALES") == -1){
                $rootScope.FORBIDDEN_BY_ALERT('FUN_VIEW_ALL_CLIENTS');
                return;
            }
            $rootScope.filterType = type;
            clear();
        };

        vm.predicate = 'id';
        vm.direction = 'desc';
        vm.page = 1;
        vm.itemsPerPage = 20;

        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = null;
        vm.currentSearch = null;

        vm.isAdvanceSearch = false;
        var gridCopy;
        var pageLoaded = false;
        var filterTimer;
        function loadAll() {
            if (vm.searchQuery) {
                OpsVenderSearch.query({
                    venderType: $rootScope.venderType,
                    query: vm.searchQuery,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                var filter = {};
                filter.venderType = $rootScope.venderType;
                filter.filterType = $rootScope.filterType;
                if(gridCopy){
                    angular.forEach(gridCopy.columns, function(column){
                        angular.forEach(column.filters, function(cf){
                            if(cf.term != null && angular.isDefined(cf.term)){
                                if(cf.condition){
                                    var fd = column.field;
                                    if(fd.endWith("Str")){
                                        fd = fd.replace("Str", "");
                                    }
                                    if(cf.condition == 128 || cf.condition == 256){ //" < ",
                                        if(column.field == "checkDate"){
                                            filter[fd + "TO"] = DateUtils.convertLocalDateToServer(cf.term);
                                        }
                                        else {
                                            filter[fd + "TO"] = cf.term;
                                        }
                                    }
                                    else if(cf.condition == 32 || cf.condition == 64){ //" > ",
                                        if(column.field == "checkDate"){
                                            filter[fd + "FM"] = DateUtils.convertLocalDateToServer(cf.term);
                                        }
                                        else{
                                            filter[fd + "FM"] = cf.term;
                                        }
                                    }
                                }
                                else {
                                    filter[column.field] = cf.term;
                                }
                            }
                        })
                    })
                }
                OpsVender.getFilterPage({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, filter, onSuccess, onError);
            }

            function sort() {
                var result = [(vm.predicate.endWith('TAG')?vm.predicate.replace('TAG', ''):vm.predicate) + ',' + vm.direction];
                return result;
            }

            function onSuccess(data, headers) {
                if(headers('X-Total-Count')){
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                }
                else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                vm.isAdvanceSearch = false;
                resize();
                initData();
            }

            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }
        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.page = 1;
            vm.predicate = '_score';
            vm.direction = 'desc';
            vm.currentSearch = searchQuery;
            loadAll();
        }
        function clear() {
            vm.page = 1;
            vm.predicate = 'id';
            vm.direction =  'desc';
            vm.searchQuery = vm.currentSearch = null;
            vm.isAdvanceSearch = false;
            loadAll();
        }

        window.onresize = function(){
            resize();
        }

        function resize() {
            if(!$("#gridId") || !$("#gridId").offset()){
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 180;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 600){
                tabHeight = 600;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        function initData() {
            DataUtils.addVenderTag($scope.gridOptions.data);
            $scope.gridOptions.useExternalSorting = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.useExternalFiltering = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.showColumnFooter = vm.isAdvanceSearch;

            $scope.gridOptions.showGridFooter = vm.isAdvanceSearch;
            // $scope.gridOptions.enableRowSelection = vm.isAdvanceSearch;
            $scope.gridOptions.multiSelect = vm.isAdvanceSearch;
            $scope.gridOptions.enableSelectAll = vm.isAdvanceSearch;
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN);
        }

        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: true,

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: false,//每列footer

            enableFiltering: true,//高级过滤
            useExternalFiltering: true,

            enableHorizontalScrollbar : 1,//表格的水平滚动条
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            enableRowHeaderSelection : true,  //行选中
            enableRowSelection: true, //行选中
            enableSelectAll: false, //全部选中
            enableFullRowSelection : false, //行任意位置选中
            multiSelect: false,//多行选中
            enableFooterTotalSelected: true,

            exporterMenuCsv : false,
            exporterMenuPdf : false,
            exporterMenuExcel : false,

            paginationPageSizes: [20, 35, 50],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 70,
            exporterExcelFilename: "settlement_object",
            columnDefs: [
                { field: 'id',
                    width: 150,
                    displayName: "",
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<div class="btn-group flex-btn-group-container ml-5"><button type="button" ng-click="grid.appScope.edit(row.entity)" class="btn btn-primary btn-grid"><span class="glyphicon glyphicon-modal-window"></span><span class="hidden-xs hidden-sm" translate="entity.action.edit"></span></button><button type="button" ng-click="grid.appScope.delete(row.entity)" class="btn btn-danger btn-grid" ng-disabled="(grid.appScope.account.id != row.entity.createdBy && grid.appScope.HAVENOAUTH(\'ROLE_BRANCH\')) || row.entity.companyId != grid.appScope.account.companyId"><span class="glyphicon glyphicon-remove-circle"></span><span class="hidden-xs hidden-sm" translate="entity.action.delete"></span></button></div>'},
                { field: 'companyName',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.opsClient.companyName')},
                { field: 'companyNameEn',
                    minWidth:180,
                    visible:false,
                    displayName: $translate.instant('autopsApp.opsClient.companyNameEn')},
                { field: 'blacklist',
                    minWidth:80,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.blacklist'),
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.blacklist"><span translate="global.yes_answer"class="label label-success">yes</span></span><span ng-hide="row.entity.blacklist"><span translate="global.no_answer"class="label label-primary">no</span></span></span>'},
                { field: 'isGroup',
                    minWidth:60,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.isGroup'),
                    cellTemplate: '<span ng-class="row.entity.isGroup?\'label-primary\':\' label-danger\'"translate="{{row.entity.isGroup?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'autoRelease',
                    minWidth:60,
                    visible:false,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.autoRelease'),
                    cellTemplate: '<span ng-class="row.entity.autoRelease?\'label-primary\':\' label-danger\'"translate="{{row.entity.autoRelease?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'userIdTAG',
                    minWidth:60,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.openAccount'),
                    cellTemplate: '<span ng-class="row.entity.userIdTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.userIdTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'autoTrackingEmail',
                    minWidth:60,
                    visible:false,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.autoTrackingEmail'),
                    cellTemplate: '<span ng-class="row.entity.autoTrackingEmail?\'label-primary\':\' label-danger\'"translate="{{row.entity.autoTrackingEmail?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'creater',
                    displayName: $translate.instant('autopsApp.opsClient.creater'),
                    minWidth:150},
                { field: 'cusName',
                    displayName: $translate.instant('autopsApp.opsClient.cusName'),
                    minWidth:150},
                { field: 'code',
                    displayName: $translate.instant('autopsApp.opsClient.code'),
                    minWidth:150,
                    cellTemplate: '<a class="grid-text" href="" ng-click="grid.appScope.edit(row.entity)">{{row.entity.code}}</a>'},
                { field: 'idCode',
                    displayName: $translate.instant('autopsApp.opsClient.idCode'),
                    minWidth:150},
                { field: 'role',
                    displayName: $translate.instant('autopsApp.opsClient.role'),
                    minWidth:150,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CLIENT_TYPES
                    },
                    cellTemplate: '<span class="grid-text"><span class="label label-success"translate="autopsApp.opsClient.directAccount"ng-if="row.entity.role == \'directAccount\'">directAccount</span><span class="label label-primary"translate="autopsApp.opsClient.shipper"ng-if="row.entity.role == \'shipper\'">shipper</span><span class="label label-primary"translate="autopsApp.opsClient.cnee"ng-if="row.entity.role == \'cnee\'">cnee</span><span class="label label-info"translate="autopsApp.opsClient.trader"ng-if="row.entity.role == \'trader\'">trader</span><span class="label label-danger"translate="autopsApp.opsClient.co-loader"ng-if="row.entity.role == \'co-loader\'">co-loader</span><span class="label label-danger"translate="autopsApp.opsClient.branch"ng-if="row.entity.role == \'branch\'">branch</span></span>'},
                { field: 'clientSource',
                    displayName: $translate.instant('autopsApp.opsClient.clientSource'),
                    minWidth:150,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CLIENT_SOURCES
                    },
                    cellTemplate: '<span class="grid-text"><span class="label label-success" translate="autopsApp.opsClient.{{row.entity.clientSource}}" ng-if="row.entity.clientSource">directAccount</span></span>'},
                { field: 'checkByName',
                    displayName: $translate.instant('autopsApp.opsClient.checkByName'),
                    minWidth:150},
                { field: 'discount',
                    minWidth:100,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [
                        { value: -1, label: "N/A"},
                        { value: 1, label: "VIP1"},
                        { value: 2, label: "VIP2"},
                        { value: 3, label: "VIP3"}]
                    },
                    displayName: $translate.instant('autopsApp.opsClient.discount'),
                    cellTemplate: '<span class="ml-5 label label-primary"ng-if="row.entity.discount">VIP{{row.entity.discount}}</span>'},
                { field: 'audited',
                    minWidth:130,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [
                        { value: true, label: $translate.instant('autopsApp.opsClient.audited')},
                        { value: false, label: $translate.instant('autopsApp.opsClient.notAudited')}]
                    },
                    displayName: $translate.instant('autopsApp.opsClient.checkStatus'),
                    cellTemplate: '<span class="ml-5"><span class="label label-primary"translate="autopsApp.opsClient.audited"ng-if="row.entity.audited"ng-click="grid.appScope.setAudited(row.entity)">audited</span><span class="label label-info"translate="autopsApp.opsClient.notAudited"ng-hide="row.entity.audited"ng-click="grid.appScope.setAudited(row.entity)">notAudited</span></span>'},
                { field: 'auditor',
                    displayName: $translate.instant('autopsApp.opsClient.auditor'),
                    minWidth:150},
                { field: 'isLocked',
                    minWidth:130,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [
                        { value: true, label: $translate.instant('autopsApp.opsClient.isLocked')},
                        { value: false, label: $translate.instant('autopsApp.opsClient.notLocked')}]
                    },
                    displayName: $translate.instant('autopsApp.opsClient.lockStatus'),
                    cellTemplate: '<span class="grid-text"><span class="label label-primary"translate="autopsApp.opsClient.isLocked"ng-if="row.entity.isLocked"ng-click="grid.appScope.setlocked(row.entity)">isLocked</span><span class="label label-info"translate="autopsApp.opsClient.notLocked"ng-hide="row.entity.isLocked"ng-click="grid.appScope.setlocked(row.entity)">notLocked</span></span>'},
                { field: 'address',
                    minWidth:180,
                    displayName: $translate.instant('autopsApp.opsClient.address')},
                { field: 'createdTime',
                    minWidth:200,
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsClient.createdTime'),
                    cellTemplate: '<span class="grid-text">{{row.entity.createdTime | date:\'medium\'}}</span>'},
                { field: 'bindedCompany',
                    minWidth:180,
                    displayName: $translate.instant('autopsApp.opsClient.bindedCompany')},
                { field: 'isClient',
                    minWidth:60,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.isClient'),
                    cellTemplate: '<span ng-class="row.entity.isClient?\'label-primary\':\' label-danger\'"translate="{{row.entity.isClient?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'isColoader',
                    minWidth:60,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.isColoader'),
                    cellTemplate: '<span ng-class="row.entity.isColoader?\'label-primary\':\' label-danger\'"translate="{{row.entity.isColoader?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'isAgent',
                    minWidth:60,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.isAgent'),
                    cellTemplate: '<span ng-class="row.entity.isAgent?\'label-primary\':\' label-danger\'"translate="{{row.entity.isAgent?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'isTrucker',
                    minWidth:60,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.isTrucker'),
                    cellTemplate: '<span ng-class="row.entity.isTrucker?\'label-primary\':\' label-danger\'"translate="{{row.entity.isTrucker?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'isCustom',
                    minWidth:60,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.isCustom'),
                    cellTemplate: '<span ng-class="row.entity.isCustom?\'label-primary\':\' label-danger\'"translate="{{row.entity.isCustom?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'isTrader',
                    minWidth:60,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.isTrader'),
                    cellTemplate: '<span ng-class="row.entity.isTrader?\'label-primary\':\' label-danger\'"translate="{{row.entity.isTrader?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'isWarehouse',
                    minWidth:60,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.isWarehouse'),
                    cellTemplate: '<span ng-class="row.entity.isWarehouse?\'label-primary\':\' label-danger\'"translate="{{row.entity.isWarehouse?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'isFumigation',
                    minWidth:60,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.isFumigation'),
                    cellTemplate: '<span ng-class="row.entity.isFumigation?\'label-primary\':\' label-danger\'"translate="{{row.entity.isFumigation?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'isInsurance',
                    minWidth:60,
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.opsClient.isInsurance'),
                    cellTemplate: '<span ng-class="row.entity.isInsurance?\'label-primary\':\' label-danger\'"translate="{{row.entity.isInsurance?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'lastModifiedTime',
                    minWidth:200,
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsClient.lastModifiedTime'),
                    cellTemplate: '<span class="grid-text">{{row.entity.lastModifiedTime | date:\'medium\'}}</span>'},
                { field: 'lastModifiedBy',
                    displayName: $translate.instant('autopsApp.opsClient.lastModifiedBy'),
                    minWidth:150},
                { field: 'alertDays',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:120,
                    displayName: $translate.instant('autopsApp.opsClient.alertDays')},
                { field: 'freeDays',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:120,
                    displayName: $translate.instant('autopsApp.opsClient.freeDays')},
                { field: 'deposite',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:120,
                    displayName: $translate.instant('autopsApp.opsClient.deposite') + "(" + $rootScope.account.baseCurrency + ")"},
                { field: 'creditLimit',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:120,
                    displayName: $translate.instant('autopsApp.opsClient.creditLimit') + "(" + $rootScope.account.baseCurrency + ")"},
                { field: 'creditLimitOwms',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:120,
                    displayName: $translate.instant('autopsApp.opsClient.creditLimitOwms') + "(" + $rootScope.account.baseCurrency + ")"},
                { field: 'paymentTerm',
                    displayName: $translate.instant('autopsApp.opsClient.paymentTerm'),
                    minWidth:150,
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.PAYMENT_TYPES
                    },
                    cellTemplate: '<span class="grid-text" translate="autopsApp.opsClient.{{row.entity.paymentTerm}}" ng-if="row.entity.paymentTerm">paymentTerm</span>'},
                { field: 'creditDateType',
                    displayName: $translate.instant('autopsApp.opsClient.creditDateType'),
                    minWidth:150,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CREDIT_DATE_TYPES
                    },
                    cellTemplate: '<span class="grid-text" translate="autopsApp.opsClient.{{row.entity.creditDateType}}" ng-if="row.entity.creditDateType">creditDateType</span>'},
                { field: 'creditDays',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:180,
                    displayName: $translate.instant('autopsApp.opsClient.creditDays')},
                { field: 'creditLimit',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:120,
                    displayName: $translate.instant('autopsApp.opsClient.creditLimit')},
                { field: 'creditRemarks',
                    displayName: $translate.instant('autopsApp.opsClient.creditRemarks'),
                    minWidth:150},
                { field: 'creditRating',
                    displayName: $translate.instant('autopsApp.opsClient.creditRating'),
                    minWidth:150,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CREDIT_RATINGS
                    },
                    cellTemplate: '<span class="grid-text" translate="autopsApp.opsClient.creditRate.{{row.entity.creditRating}}" ng-if="row.entity.creditRating">creditRating</span>'},
                { field: 'branch',
                    displayName: $translate.instant('autopsApp.opsClient.branch'),
                    minWidth:150},
                { field: 'team',
                    displayName: $translate.instant('autopsApp.opsClient.team'),
                    minWidth:150},
                { field: 'location',
                    displayName: $translate.instant('autopsApp.opsClient.location'),
                    minWidth:150},
                { field: 'kc',
                    displayName: "KC#",
                    minWidth:150},
                { field: 'iata',
                    displayName: "IATA#",
                    minWidth:150},
                { field: 'taxId',
                    displayName: $translate.instant('autopsApp.opsClient.taxId'),
                    minWidth:150},
                { field: 'agentName',
                    displayName: $translate.instant('autopsApp.opsClient.agentName'),
                    minWidth:150},
                { field: 'membershipValidity',
                    minWidth:200,
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsClient.membershipValidity'),
                    cellTemplate: '<span class="grid-text">{{row.entity.membershipValidity | date:\'mediumDate\'}}</span>'},


                { field: 'remarks',
                    displayName: $translate.instant('autopsApp.opsClient.remarks'),
                    minWidth:150}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = getGridId();
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "vender";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "vender";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if(!$scope.gridOptions.useExternalSorting){
                        if(sortColumns[sortColumns.length - 1]){
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    vm.predicate = sortColumns[sortColumns.length - 1].field;
                    vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    loadAll();
                });
                gridApi.core.on.filterChanged($scope, function() {
                    gridCopy = this.grid;
                    if(pageLoaded && $scope.gridOptions.useExternalFiltering){
                        clearTimeout(filterTimer);
                        filterTimer = setTimeout(function(){
                            loadAll();
                        }, 1000);//查询延时时间，单位ms
                    }
                });
                gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    vm.page = newPage;
                    vm.itemsPerPage = pageSize;
                    if(pageLoaded){
                        loadAll();
                    }
                });
            }
        }
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: getGridId()
            }, function(result){
                gridStateObj = result;
                $rootScope.CHECK_GRID_AUTH($scope.gridOptions);
                if(!gridStateObj || !gridStateObj.id){
                    pageLoaded = true;
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    loadAll();
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                pageLoaded = true;
                if(gridCopy){
                    var filterRequired = false;
                    angular.forEach(gridCopy.columns, function(column){
                        angular.forEach(column.filters, function(cf){
                            if(cf.term || cf.term == false){
                                filterRequired = true;
                            }
                        })
                    })
                    if(filterRequired){
                        layer.msg($translate.instant('global.filterDetectedAlert'));
                    }
                }
                loadAll();
            })
        });

        function getGridId () {
            if($rootScope.venderType === "client"){
                return 70;
            }
            else if($rootScope.venderType === "bookingAgent"){
                return 71;
            }
            else if($rootScope.venderType === "overseasAgent"){
                return 72;
            }
            else if($rootScope.venderType === "vender"){
                return 73;
            }
            else if($rootScope.venderType === "all"){
                return 103;
            }
        }


        if (!$rootScope.account || angular.equals($rootScope.account, {})){
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };


        $scope.edit = function (client) {
            if (!client){
                var opsVender = {};
                opsVender.cooperated = true;
                opsVender.companyName = $scope.newName;
                opsVender.opsFactorys = [];
                opsVender.opsShippers = [];
                opsVender.contactList = [];
                opsVender.invoiceInfos = [];
                if($rootScope.venderType == "client"){
                    opsVender.isClient = true;
                }
                else if($rootScope.venderType == "bookingAgent"){
                    opsVender.isColoader = true;
                }
                else if($rootScope.venderType == "overseasAgent"){
                    opsVender.isAgent = true;
                }
                else if($rootScope.venderType == "vender"){
                    opsVender.isTrucker = true;
                }
                openClientEditPop(opsVender);
            }
            else {
                OpsVender.get({id: client.id}, function(result) {
                    openClientEditPop(result);
                });
            }
        }

        var openClientEditPop = function(opsVenderToEdit) {
            $rootScope.activeTab = 0;
            $uibModal.open({
                templateUrl: 'app/entities/opsVender/opsVender-detail.html',
                controller: 'OpsVenderDetailController',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return opsVenderToEdit;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsClient');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                var isNew = true;
                angular.forEach($scope.gridOptions.data, function(data){
                    if (data.id == result.id){
                        angular.copy(result, data);
                        isNew = false;
                    }
                })
                if (isNew){
                    $scope.gridOptions.data.splice(0, 0, result);
                }
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        var onDeleteSuccess = function (result) {
            layer.closeAll();
            layer.msg($translate.instant('global.delete_success'));
            loadAll();
        };

        var onDeleteError = function (result) {
            layer.closeAll();
            if (result.status === 403){
                layer.msg($translate.instant('global.delete_forbidden'));
            }
            else if (result.status === 409){
                layer.msg($translate.instant('autopsApp.opsClient.lockedAlert'));
            }
            else if (result.status === 406){
                layer.msg($translate.instant('autopsApp.opsClient.delContactForbidden'));
            }
            else{
                layer.msg($translate.instant('global.delete_fail'));
            }
        };

        $scope.delete = function (obj) {
            if($rootScope.ISDEMO && $rootScope.HAVENOAUTH('ROLE_BOSS')){
                layer.msg($translate.instant('global.demoAlert'));
                return;
            }
            if ($rootScope.HAVENOAUTH('DELETE_STMT_OBJ')){
                $rootScope.FORBIDDEN_BY_ALERT('DELETE_STMT_OBJ');
                return;
            }
            layer.confirm(obj.companyName + $translate.instant('autopsApp.opsClient.delete.question'), {
                title: $translate.instant('autopsApp.opsClient.delete.title'),
                btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')]
            }, function(index) {
                layer.close(index);
                OpsVender.delete({id: obj.id}, onDeleteSuccess, onDeleteError);
            }, function(index) {
                layer.close(index);
            });
        }


        $scope.refresh = function () {
            loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            opsVenderToEdit = {
                companyId: null,
                createdBy: null,
                venderId: null,
                code: null,
                companyName: null,
                address: null,
                attn: null,
                mob: null,
                tel: null,
                fax: null,
                email: null,
                role: null,
                authority: null,
                remarks: null,
                createdTime: null,
                id: null
            };
        };

        $scope.repeatNames = {};
        $scope.newName = "";
        var inputPopIndex = 0;
        $scope.createNew = function () {
            if ($rootScope.HAVENOAUTH('CREATE_STMT_OBJ')){
                $rootScope.FORBIDDEN_BY_ALERT('CREATE_STMT_OBJ');
                return;
            }
            $scope.newName = "";
            inputPopIndex = layer.open({
                type: 1,
                area: ['380px', '170px'],
                title: $translate.instant("autopsApp.opsClient.checkRepeat"),
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#checkRepeatPop")
            });
            document.getElementById("checkRepeat_field").focus();
        }

        $scope.checkRepeat = function () {
            $scope.isSaving = true;
            OpsVender.checkRepeat({
                companyId: $rootScope.account.companyId,
                newName: $scope.newName
            }, function(result) {
                layer.closeAll();
                $scope.isSaving = false;
                if (!result || result.length == 0){
                    layer.closeAll();
                    $scope.edit();
                    layer.msg($translate.instant('autopsApp.opsClient.checkPassed'));
                }
                else {
                    $scope.repeatNames = result;
                    layer.open({
                        type: 1,
                        area: ['780px', '380px'],
                        moveOut: false,
                        title: $scope.newName + ": " + $translate.instant("autopsApp.opsClient.checkResultTips"),
                        shade: 0.3,
                        moveType: 0,
                        shift: 0,
                        content: $("#checkResultPop"),
                        btn: [$translate.instant('entity.action.ignore'), $translate.instant('entity.action.back')],
                        yes: function(index, layero) {
                            layer.close(index);
                            $scope.edit();
                        },
                        cancel: function(index) {
                            layer.close(index);
                        }
                    });
                }
            }, function(response) {
                $scope.isSaving = false;
                if (response.status === 403){
                    layer.msg($translate.instant('autopsApp.opsClient.invalidInput'));
                }
            });
        };

        var isReverse = true;
        $scope.sortClients = function(title) {
            isReverse = !isReverse;
            $scope.gridOptions.data.sort(function(a,b){
                if (isReverse){
                    return a[title]<b[title]?1:-1;
                }
                else {
                    return a[title]>b[title]?1:-1;
                }
            });
        }

        $scope.advanceSearch = function() {
            var searchParams = {
                isFromCrm: false,
                venderType: $rootScope.venderType
            };
            $uibModal.open({
                templateUrl: 'app/entities/ops-crm/ops-crm-dialog.html',
                controller: 'OpsCrmDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(results) {
                $scope.gridOptions.data = results;
                vm.isAdvanceSearch = vm.currentSearch = true;
                $scope.gridOptions.totalItems = results.length;
                resize();
                initData();
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        $scope.setlocked= function(opsClient) {
            if ($rootScope.account.companyId !=  opsClient.companyId && opsClient.companyId){
                layer.msg($translate.instant('autopsApp.opsClient.groupClientAlert'));
                return;
            }
            if ($rootScope.HAVENOAUTH('AU_OBJ_LOCK')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_OBJ_LOCK');
                return;
            }
            var lockedCopy = opsClient.isLocked;
            if(!opsClient.isLocked){
                opsClient.isLocked = true;
            }
            else {
                opsClient.isLocked = false;
            }
            OpsVender.setlocked(opsClient, function(result) {
                layer.msg($translate.instant('global.messages.submitted'));
            },function(result) {
                opsClient.isLocked = lockedCopy;
                layer.msg($translate.instant('global.messages.submittedfailed'));
            });
        };

        $scope.setAudited= function(opsClient) {
            if ($rootScope.account.companyId !=  opsClient.companyId && opsClient.companyId){
                layer.msg($translate.instant('autopsApp.opsClient.groupClientAlert'));
                return;
            }
            if ($rootScope.HAVENOAUTH('AU_OBJ_AUDIT')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_OBJ_AUDIT');
                return;
            }
            var lockedCopy = opsClient.audited;
            if(!opsClient.audited){
                opsClient.audited = true;
                opsClient.auditor = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            }
            else {
                opsClient.audited = false;
                opsClient.auditor = null;
            }
            OpsVender.setAudited(opsClient, function(result) {
                layer.msg($translate.instant('global.messages.submitted'));
            },function(result) {
                opsClient.audited = lockedCopy;
                layer.msg($translate.instant('global.messages.submittedfailed'));
            });
        };

        $scope.excelImport = function(file, amendCredit) {
            if ($rootScope.HAVENOAUTH('CREATE_STMT_OBJ')){
                $rootScope.FORBIDDEN_BY_ALERT('CREATE_STMT_OBJ');
                return;
            }
            if (!file) {return;}

            var venderRole = $rootScope.venderType;
            if(venderRole == "overseasAgent"){
                venderRole = "agent";
            }
            else if( venderRole== "bookingAgent"){
                venderRole = "coloader";
            }

            var fd = new FormData();
            fd.append('file', file);
            fd.append('cooperated', true);
            fd.append('venderRole', venderRole);
            if(amendCredit){
                fd.append('amendCredit', true);
            }
            $http.post(
                'api/opsVenders/importByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    data = angular.fromJson(data);
                    if(data.newCount>0){
                        loadAll();
                    }
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.opsClient.uploadResult', {
                        repeatCount: data.repeatCount,
                        newCount: data.newCount}));
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                });
        };

        $scope.importInvoiceInfo = function(file) {
            if (!file) {return;}
            var fd = new FormData();
            fd.append('file', file);
            $http.post(
                'api/opsVenders/importInvoiceInfo', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    data = angular.fromJson(data);
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.opsClient.importInvoiceInfoResult', {
                        invalid: data.invalid,
                        updated: data.updated,
                        imported: data.imported}));
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                });
        };
        $scope.importBlInfo = function(file) {
            if (!file) {return;}
            var fd = new FormData();
            fd.append('file', file);
            $http.post(
                'api/opsVenders/importBlInfo', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    data = angular.fromJson(data);
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.opsClient.importInvoiceInfoResult', {
                        invalid: data.invalid,
                        updated: data.updated,
                        imported: data.imported}));
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                });
        };
        $scope.excelExport = function() {
            if(!$scope.gridOptions.data || $scope.gridOptions.data.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var fileName = "Object.xlsx";
            OpsFiles.excelOpsVender({
                title: "Settlements Object",
                enCn: $translate.instant('global.enCn')
            }, $scope.gridOptions.data, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }
        $scope.shiftAll = function() {
            if ($rootScope.HAVENOAUTH('ROLE_BOSS')){
                layer.msg($translate.instant('global.forbiddenBoss'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/share-client/share-client-delete-dialog.html',
                controller: 'ShareClientDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return "";
                    }]
                }
            }).result.then(function() {
                loadAll();
            }, function() {

            });
        }
        $scope.batchChange = function(type) {
            if ($rootScope.HAVENOAUTH('BATCH_UPDATE_STMT_OBJ')){
                $rootScope.FORBIDDEN_BY_ALERT('BATCH_UPDATE_STMT_OBJ');
                return;
            }
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if(!selectedRows || selectedRows.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            var entity = {};
            entity.opsVenders = selectedRows;
            entity.type = type;
            $uibModal.open({
                templateUrl: 'app/entities/ops-crm/ops-crm-delete-dialog.html',
                controller: 'OpsCrmDeleteController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(results) {
                angular.forEach(selectedRows, function(data){
                    angular.forEach(results, function(result){
                        if(data.id == result.id){
                            data.cusId = result.cusId;
                            data.cusName = result.cusName;
                            data.createdBy = result.createdBy;
                            data.creater = result.creater;
                            data.cusName = result.cusName;
                        }

                    })

                })
            }, function() {

            });
        }
        $scope.shareAll= function() {
            if ($rootScope.HAVENOAUTH('ROLE_BOSS')){
                layer.msg($translate.instant('global.forbiddenBoss'));
                return;
            }
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if(!selectedRows || selectedRows.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            layer.confirm($translate.instant('autopsApp.opsClient.shareAllAlert', {param: selectedRows.length}), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.cancel')]
            }, function(index) {
                layer.close(index);
                layer.msg("Updating...", {
                    shade: 0.3,
                    time: 2000000
                });
                OpsVender.shareAll(selectedRows, function(result) {
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submitted'));
                },function(result) {
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                });
            }, function() {
                return;
            });
        }
        $scope.auditAll= function(auditValue) {
            if ($rootScope.HAVENOAUTH('AU_OBJ_AUDIT')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_OBJ_AUDIT');
                return;
            }
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if(!selectedRows || selectedRows.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            layer.confirm(auditValue?$translate.instant('autopsApp.opsClient.auditAllAlert', {param: selectedRows.length}):$translate.instant('autopsApp.opsClient.antiAuditAllAlert', {param: selectedRows.length}), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.cancel')]
            }, function(index) {
                layer.close(index);
                layer.msg("Updating...", {
                    shade: 0.3,
                    time: 2000000
                });
                OpsVender.auditAll({auditValue: auditValue}, selectedRows, function(result) {
                    layer.closeAll();
                    angular.forEach(selectedRows, function(data){
                        data.audited = auditValue;
                    })
                    layer.msg($translate.instant('global.messages.submitted'));

                },function(result) {
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submittedfailed'));

                });
            }, function() {
                return;
            });
        }
        $scope.deleteAll = function (obj) {
            if ($rootScope.HAVENOAUTH('DELETE_STMT_OBJ')){
                $rootScope.FORBIDDEN_BY_ALERT('DELETE_STMT_OBJ');
                return;
            }
            layer.confirm($translate.instant('autopsApp.opsClient.delete.deleteAllQuestion'), {
                title: $translate.instant('autopsApp.opsClient.delete.title'),
                btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')]
            }, function(index) {
                layer.close(index);
                layer.load(1, {shade: 0.3});
                OpsVender.deleteAll({}, $scope.gridOptions.data, onDeleteSuccess, onDeleteError);
            }, function(index) {
                layer.close(index);
            });
        }
        var unsubscribe = $rootScope.$on('autopsApp:opsVenderUpdate', function(event, result) {
            if(result.id){
                angular.forEach($scope.gridOptions.data, function(guide){
                    if(guide.id == result.id){
                        angular.copy(result, guide);
                    }
                })
            }
            else {
                loadAll();
            }
        });
        $scope.$on('$destroy', unsubscribe);

        $scope.clientCreditRating = function() {
            var loadIndex = layer.load(1, {shade: 0.3});
            OpsFiles.clientCreditRating({companyId: $rootScope.account.companyId}, null, function(result) {
                layer.close(loadIndex);
                $rootScope.DOWNLOADEXCEL(result, "credit_rating.xlsx");
            })
        }

    }
})();

