(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ShareClientDialogController', ShareClientDialogController);

    ShareClientDialogController.$inject = ['$timeout', '$rootScope', '$translate', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ShareClient'];

    function ShareClientDialogController ($timeout, $rootScope, $translate, $scope, $stateParams, $uibModalInstance, entity, ShareClient) {
        var vm = this;

        vm.client = entity;
        vm.clear = clear;
        vm.getAllSales = getAllSales;
        vm.delSales = delSales;

        $scope.sharedSales = [];

        $timeout(function (){
            if ($rootScope.account.companyId !=  vm.client.companyId && vm.client.companyId){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.opsClient.groupClientAlert'));
                 $uibModalInstance.dismiss('cancel');
                 return;
            }   
            else if(vm.client.createdBy 
                && $rootScope.account.id != vm.client.createdBy
                && $rootScope.HAVENOAUTH('FUN_VIEW_ALL_CLIENTS')
                && ($rootScope.HAVENOAUTH('ROLE_BRANCH') || $rootScope.account.branch != vm.client.branch)
                && ($rootScope.HAVENOAUTH('ROLE_DEPARTMENT') || $rootScope.account.branch != vm.client.branch
                    || $rootScope.account.team != vm.client.team)){
                $rootScope.OPS_ALERT($translate.instant('global.forbidden'));
                $uibModalInstance.dismiss('cancel');
                return;
            }
            else {
                angular.element('.form-group:eq(1)>input').focus();
                getAllSales();
            }
        });

        function getAllSales () {
            ShareClient.getAllSales({
                clientId: vm.client.id
            }, function(result){
                $scope.sharedSales = result;
            })
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        $scope.addSales = function(sales) {
            vm.isSaving = true;
            var shareClient = {};
            shareClient.clientId = vm.client.id;
            shareClient.salesId = sales.id;
            shareClient.relationType = "shareClient";
            shareClient.creater = sales.name;
            ShareClient.save(shareClient, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            getAllSales();
        }

        function onSaveError (result) {
            if (result.status == 409){
                layer.msg($translate.instant('autopsApp.shareClient.doubleSalesAlert'));
            }
            vm.isSaving = false;
            getAllSales();
        }
        
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function delSales (id) {
            ShareClient.delete({id: id},
                function () {
                    getAllSales();
                });
        }


        $scope.salesFilter = function(item) {
            return (item.authorities.indexOf("ROLE_CS") !== -1 || item.authorities.indexOf("ROLE_SALES") !== -1) && vm.client.createdBy != item.id;
        };
    }
})();
